import React from 'react';
import {Nav} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faPuzzlePiece, faUsers, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

export type ActiveDemographicsTab = 'general' | 'significantAdults' | 'otherAdults' | 'criticalInfo';

type Props = {
    activeDemographicsTab : ActiveDemographicsTab;
    handleSetActiveDemographicsTab : (activeTab : ActiveDemographicsTab) => void;
    hasCriticalInfo: boolean;
}

const DemographicsSubNav = ({activeDemographicsTab, handleSetActiveDemographicsTab, hasCriticalInfo} : Props) => {
    let history = useHistory();

    return (
        <Nav className="flex-column pb-xs-0 pb-lg-5 demo-nav" onSelect={(eventKey: any) => {
            history.push(`/record-locator?sub=${eventKey}`);
            if (history.location.search === `?sub=${eventKey}`) {
                handleSetActiveDemographicsTab(eventKey);
            }
        }} activeKey={activeDemographicsTab}>
            <Nav.Item>
                <Nav.Link className="p-3" eventKey="general">
                    <FontAwesomeIcon icon={faPencilAlt} size={"lg"}/> &nbsp; General
                </Nav.Link>
                <Nav.Link className="p-3" eventKey="significantAdults">
                    <FontAwesomeIcon icon={faPuzzlePiece} size={"lg"}/> &nbsp; Significant Adults
                </Nav.Link>
                <Nav.Link className="p-3" eventKey="otherAdults">
                    <FontAwesomeIcon icon={faUsers} size={"lg"}/> &nbsp; Other Adults
                </Nav.Link>
                <Nav.Link className={`p-3 ${hasCriticalInfo ? 'critical-info' : ''}`} eventKey="criticalInfo">
                    <FontAwesomeIcon icon={faExclamationCircle} size={"lg"}/> &nbsp; Critical Info
                </Nav.Link>
            </Nav.Item>
        </Nav>
    );
};

export default DemographicsSubNav;