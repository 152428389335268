import {FormikValues} from 'formik';
import queryString from 'query-string';
import React, {useCallback, useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {useHistory, useLocation} from 'react-router-dom';
import * as yup from 'yup';
import CircularLoadingIndicator from '../components/CircularLoadingIndicator';
import FormBuilder, {SubmitType} from '../components/Forms/FormBuilder';
import GeneralErrorModal from '../components/Modal/GeneralErrorModal';
import NavHeader from '../components/Navigation/NavHeader';
import {ChrisUserProviderContext} from '../components/Providers/ChrisUserProvider';
import {useApiFetch} from "../components/Providers/OidcProvider";
import {FormField, mapRawField} from "../components/SiteForms/Forms";
import {apiEndpoint, apiFetch as standardFetch} from "../utils/api";
import {mapFormikValuesForFM, yupDateRegex} from '../utils/formHelpers';
import moment from "moment";
import ActiveUserNav from "../components/Navigation/ActiveUserNav";
import ActiveChildNav from "../components/Navigation/ActiveChildNav";
import ChildSearchHelperModal from "../components/Modal/ChildSearchHelperModal";
import {RecordLocatorProviderContext} from "../components/Providers/RecordLocatorProvider";
import CosTooOldModal from "../components/Modal/CosTooOldModal";
import {saveAs} from "file-saver";

interface YupSchema {
    [key: string]: any
}

const activeForm = {
    "id": process.env.REACT_APP_COSFORM_ID ?? '',
    "title": "Child Outcomes Summary Form",
    "short": "Child Outcomes Summary Form",
    "parentId": "",
    "isParent": false,
    "description": null,
}


const CosForm = () => {
    const apiFetch = useApiFetch();
    const {search} = useLocation();
    const queryStringParameters = queryString.parse(search);
    const [{chrisUser}] = React.useContext(ChrisUserProviderContext);
    const {
        childSummary,
        handleShowChildSearchHelperModal,
        siteLists,
        handleSetActiveChild,
        activeChild,
        showCosTooOldModal,
        setShowCosTooOldModal,
        cosChildUUID: searchCosChildUUID,
        loading: activeChildLoading,
    } = React.useContext(RecordLocatorProviderContext);
    const childUUID = queryStringParameters?.id as string;

    const [activeFormFields, setActiveFormFields] = useState<FormField[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [showGeneralErrorModal, setShowGeneralErrorModal] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        if (siteLists?.length && activeChild?.childUUID === childUUID && activeFormFields === null) {
            setActiveFormFields(null);
            setLoading(true);

            const url = new URL(`/v1/forms/form/${activeForm.id}/${childUUID}`, apiEndpoint);
            apiFetch(url.toString()).then(async (response) => {

                if (!response.ok) {
                    setShowGeneralErrorModal(true);
                    return;
                }

                const data = await response.json();

                const fieldData: FormField[] = data.map((data: any) => mapRawField(data, siteLists));
                const COSFormDistrictAtEntryIndex = fieldData.findIndex((d) => d.name === 'COSFormDistrictAtEntry');
                fieldData[COSFormDistrictAtEntryIndex].options = fieldData[COSFormDistrictAtEntryIndex].options
                    .filter((county) => chrisUser?.districts?.includes(county.label)
                        || county.label === fieldData[COSFormDistrictAtEntryIndex].value
                        || county.label === 'FSDB');

                const COSFormDistrictAtExitIndex = fieldData.findIndex((d) => d.name === 'COSFormDistrictAtExit');
                const RecordTransferIndex = fieldData.findIndex((d) => d.name === 'RecordTransfer');
                if (fieldData[RecordTransferIndex].value === 'Y' && chrisUser?.districts?.includes(fieldData[COSFormDistrictAtExitIndex].value)) {
                    fieldData[RecordTransferIndex].value = '';
                    fieldData[RecordTransferIndex].options[0].checked = false;
                }

                setActiveFormFields(fieldData);
                setLoading(false);
            })
        }
    }, [siteLists, apiFetch, chrisUser?.districts, activeChild?.childUUID, childUUID, activeFormFields]);

    const handleFormBuilderSubmit = useCallback(async (values: FormikValues, submitType: SubmitType) => {
        const mappedValues = mapFormikValuesForFM(values);
        const url = new URL(`/v1/forms/${activeForm?.id}/${activeChild?.childUUID}`, apiEndpoint);
        const response = await apiFetch(url.toString(), {
            method: 'PUT',
            body: JSON.stringify({
                withPdf: submitType === 'print',
                withEmail: submitType === 'email',
                values: mappedValues
            })
        });

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        if (submitType === 'print' && activeChild) {
            const data = await response.json();

            const pdfResponse = await standardFetch(data.pdf);
            const blob = await pdfResponse.blob();
            saveAs(
                blob,
                activeForm?.title.replace(/\s/g, '')
                + '-'
                + activeChild.generalDemographics.FirstName
                + activeChild.generalDemographics.LastName
                + '.pdf'
            );
        }
    }, [activeChild, apiFetch]);

    React.useEffect(() => {
        if (!activeChildLoading && childUUID && (activeChild === null || activeChild.childUUID !== childUUID)) {
            setLoading(true);
            setActiveFormFields(null);
            void handleSetActiveChild(childUUID, null)
        }
    }, [activeChild, activeChildLoading, childUUID, handleSetActiveChild, loading]);

    if (loading) {
        return <CircularLoadingIndicator />;
    }

    const entryEvaluationRequired = (formValues: FormikValues) => {
        const COSFormEntryCOSDate = formValues.COSFormEntryCOSDate
            ? moment(formValues.COSFormEntryCOSDate, "MM/DD/YYYY") > moment('2024-06-30') : false;
        let entryEvaluation = true;
        if (COSFormEntryCOSDate) {
            entryEvaluation = !!formValues.COSFormPrimaryExceptionality && !!formValues.COSFormEvaluationDate
        }
        return entryEvaluation;
    }

    const allEntryFieldEntered = (formValues: FormikValues, path: string) => {
        return (
            !!formValues.COSFormDistrictAtEntry &&
            !!formValues.COSFormEntryOutcome1Rating &&
            !!formValues.COSFormEntryCOSDate &&
            !!formValues.COSFormEntryOutcome2Rating &&
            !!formValues.COSFormEntryIEPDate &&
            !!formValues.COSFormEntryOutcome3Rating &&
            entryEvaluationRequired(formValues)
        )
    }

    const allExitFieldEntered = (formValues: FormikValues, path: string) => {
        const fields = [
            !!formValues.COSFormExitOutcome1Rating,
            !!formValues.COSFormExitOutcome2Rating,
            !!formValues.COSFormExitOutcome3Rating,
            !!formValues.COSFormExitOutcome1Progress,
            !!formValues.COSFormExitOutcome2Progress,
            !!formValues.COSFormExitOutcome3Progress,
            !!formValues.COSFormExitCOSDate,
            !!formValues.COSFormTeacherServiceProvider
        ];

        const trueCnt = fields.filter(f => f).length;
        const falseCnt = fields.filter(f => !f).length;

        if (trueCnt === 0 && formValues.RecordTransfer) {
            //if it's record transfer and the true count is 0
            if (path === 'COSFormDistrictAtExit') {
                //the field we are testing is exit then return true if exit has a value or false if it does not
                return !!formValues.COSFormDistrictAtExit;
            } else {
                //for all the other fields they should be empty so return true
                return true;
            }
        }

        if (trueCnt === 0  && !formValues.COSFormDistrictAtExit) {
            return true;
        }

        return falseCnt === 0
    }

    let addSchema: YupSchema = {
        COSFormStudentID: yup.string().required().matches(/^FL00[0-9]{10}$/, 'Must begin with FL00 and then have 10 numeric characters.'),
        COSFormDistrictAtEntry: yup.string().test(
            'oneOfRequired',
            'All Entry fields must be entered to save.',
            function () {return allEntryFieldEntered(this.parent, this.path)}
        ),
        COSFormEvaluationDate: yupDateRegex.test(
            'oneOfRequired',
            'All Entry fields must be entered to save.',
            function () {return allEntryFieldEntered(this.parent, this.path)}
        ),
        COSFormPrimaryExceptionality: yup.string().test(
            'oneOfRequired',
            'All Entry fields must be entered to save.',
            function () {return allEntryFieldEntered(this.parent, this.path)}
        ),
        COSFormEntryOutcome1Rating: yup.string().test(
            'oneOfRequired',
            'All Entry fields must be entered to save.',
            function () {return allEntryFieldEntered(this.parent, this.path)}
        ),
        COSFormEntryCOSDate: yupDateRegex.test(
            'oneOfRequired',
            'All Entry fields must be entered to save.',
            function () {return allEntryFieldEntered(this.parent, this.path)}
        ),
        COSFormEntryOutcome2Rating: yup.string().test(
            'oneOfRequired',
            'All Entry fields must be entered to save.',
            function () {return allEntryFieldEntered(this.parent, this.path)}
        ),
        COSFormEntryIEPDate: yup.string().test(
            'oneOfRequired',
            'All Entry fields must be entered to save.',
            function () {return allEntryFieldEntered(this.parent, this.path)}
        ).test(
            'afterEntry',
            'The COS date must not be earlier than the IEP date',
            function () {
                const entry = this.parent.COSFormEntryCOSDate
                const iep = this.parent.COSFormEntryIEPDate

                if (!entry || !iep) {
                    return true;
                }

                const entryDate = moment(entry, "MM/DD/YYYY");
                const iepDate = moment(iep, "MM/DD/YYYY");
                return iepDate <= entryDate;
            }
        ),
        COSFormEntryOutcome3Rating: yup.string().test(
            'oneOfRequired',
            'All Entry fields must be entered to save.',
            function () {return allEntryFieldEntered(this.parent, this.path)}
        ),
        COSFormExitOutcome1Progress: yup.string().test(
            'requiredWhen',
            'All Exit fields must be entered to save.',
            function () {return allExitFieldEntered(this.parent, this.path)}
        ),
        COSFormExitOutcome2Progress: yup.string().test(
            'requiredWhen',
            'All Exit fields must be entered to save.',
            function () {return allExitFieldEntered(this.parent, this.path)}
        ),
        COSFormExitOutcome3Progress: yup.string().test(
            'requiredWhen',
            'All Exit fields must be entered to save.',
            function () {return allExitFieldEntered(this.parent, this.path)}
        ),
        COSFormExitOutcome1Rating: yup.string().test(
            'requiredWhen',
            'All Exit fields must be entered to save.',
            function () {return allExitFieldEntered(this.parent, this.path)}
        ),
        COSFormExitOutcome2Rating: yup.string().test(
            'requiredWhen',
            'All Exit fields must be entered to save.',
            function () {return allExitFieldEntered(this.parent, this.path)}
        ),
        COSFormExitOutcome3Rating: yup.string().test(
            'requiredWhen',
            'All Exit fields must be entered to save.',
            function () {return allExitFieldEntered(this.parent, this.path)}
        ),
        COSFormDistrictAtExit: yup.string().test(
            'requiredWhen',
            'All Exit fields must be entered to save.',
            function () {return allExitFieldEntered(this.parent, this.path)}
        ),
        COSFormTeacherServiceProvider: yup.string().test(
            'requiredWhen',
            'All Exit fields must be entered to save.',
            function () {return allExitFieldEntered(this.parent, this.path)}
        ),
        COSFormExitCOSDate: yupDateRegex.test(
            'entryBeforeExit',
            'Entry date must be filled out before exit date.',
            function () {
                if (this.parent.COSFormExitCOSDate)
                    return this.parent.COSFormEntryCOSDate;
                return true;
            }
        ).test(
            'entryBeforeExit',
            'Exit date must be after entry date.',
            function () {
                return !this.parent.COSFormExitCOSDate ||
                    moment(this.parent.COSFormEntryCOSDate, "MM/DD/YYYY")
                    < moment(this.parent.COSFormExitCOSDate, "MM/DD/YYYY");
            }
        )
        .test(
            'requiredWhen',
            'All Exit fields must be entered to save',
            function () {
                return allExitFieldEntered(this.parent, this.path)
            }
        ),
    }


    activeFormFields?.forEach((activeFormField) => {
        if (
            activeFormField.type === 'date'
            && !['COSFormEntryCOSDate', 'COSFormExitCOSDate', 'COSFormEntryIEPDate', 'COSFormEvaluationDate'].includes(activeFormField.name))
        {
            addSchema[activeFormField.name] = yupDateRegex;
        }
    })

    const entryFields = [
        "COSFormDistrictAtEntry",
        "COSFormEntryOutcome1Rating",
        "COSFormEntryCOSDate",
        "COSFormEntryOutcome2Rating",
        "COSFormEntryIEPDate",
        "COSFormEntryOutcome3Rating",
    ]

    const disabledConditions = {
        COSFormDistrictAtExit: entryFields,
        COSFormExitOutcome1Rating: entryFields,
        COSFormExitCOSDate: entryFields,
        COSFormExitOutcome2Rating: entryFields,
        COSFormExitOutcome3Rating: entryFields,
    }

    return <React.Fragment>
        <NavHeader />
        <ActiveUserNav chrisUser={chrisUser}>
            {activeChild && childSummary ? (
                <ActiveChildNav
                    activeChild={activeChild}
                    childSummary={childSummary}
                    handleShowChildSearchHelperModal={handleShowChildSearchHelperModal}
                />
            ) : (<></>)}
        </ActiveUserNav>
        <Container>
            <Row>
                {(loading || !activeChild || !activeForm || !activeFormFields) ? (
                    <Col sm={12} className="bg-white py-3 mx-0 px-0">
                        <CircularLoadingIndicator />
                    </Col>
                ) : (
                    <Col sm={12} className="bg-white py-3 mx-0 px-0">
                        {activeChild && activeForm && activeFormFields && <FormBuilder
                            activeForm={activeForm}
                            activeFormFields={activeFormFields}
                            handleFormBuilderSubmit={handleFormBuilderSubmit}
                            canSave={true}
                            addSchema={addSchema}
                            disabledConditions={disabledConditions}
                        />}
                    </Col>
                )}
            </Row>
            <GeneralErrorModal
                show={showGeneralErrorModal}
                handleShowGeneralErrorModal={setShowGeneralErrorModal}
            />
            <ChildSearchHelperModal/>
            <CosTooOldModal
                show={showCosTooOldModal}
                handleShowModal={setShowCosTooOldModal}
                handleContinue={() => {
                    setShowCosTooOldModal(false);
                    history.push(`/cos-form?id=${searchCosChildUUID}`);
                }}
            />
        </Container>
    </React.Fragment>
};

export default CosForm;
