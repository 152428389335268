import React, {useCallback, useState} from 'react';
import {Form, Formik, FormikProps, FormikValues} from "formik";
import {NameToValueMap} from "../../FormBuilder";
import {Col, Row} from "react-bootstrap";
import * as yup from 'yup';
import {Child, List} from "../../../../pages/RecordLocator";
import ModifiedPrompt from "../../ModifiedPrompt";
import {SCDisplayEvent, SCEvent, SCEventName, SCEventValues} from "../../../Tracking/SCEvents";
import EventControls from "./EventControls";
import EventSummary from "./EventSummary";
import EventFooter from "./EventFooter";
import ReferralFirstContactForm, {
    REFERRAL_FIRST_CONTACT_STRING,
    ReferralFirstContactSchema
} from "./ReferralFirstContactForm";
import ContactLogForm, {CONTACT_LOG_STRING, ContactLogSchema} from "./ContactLogForm";
import ReferralClosedForm, {REFERRAL_CLOSED_STRING, ReferralClosedSchema} from "./ReferralClosedForm";
import AppointmentsForm, {APPOINTMENTS_STRING, AppointmentsSchema} from "./AppointmentsForm";
import ReferralOutForm, {REFERRAL_OUT_STRING, ReferralOutSchema} from "./ReferralOutForm";
import ReferralInForm, {REFERRAL_IN_STRING, ReferralInSchema} from "./ReferralInForm";
import ReferralInactiveForm, {REFERRAL_INACTIVE_STRING, ReferralInactiveSchema} from "./ReferralInactiveForm";
import FolderToESEForm, {FOLDER_TO_ESE_DISPLAY, FOLDER_TO_ESE_STRING, FolderToESESchema} from "./FolderToESEForm";
import OnHoldForm, {ON_HOLD_STRING, OnHoldSchema} from "./OnHoldForm";
import PacketReturnedForm, {PACKET_RETURNED_STRING, PacketReturnedSchema} from "./PacketReturnedForm";
import PacketSentForm, {PACKET_SENT_STRING, PacketSentSchema} from "./PacketSentForm";
import PeriodicFollowUpForm, {PERIODIC_FOLLOW_UP_STRING, PeriodicFollowUpSchema} from "./PeriodicFollowUpForm";
import {apiEndpoint} from "../../../../utils/api";
import {useApiFetch} from "../../../Providers/OidcProvider";
import {yupDateRegex} from "../../../../utils/formHelpers";

export const mapRawSCEventValues = (values : any) : SCEventValues => ({
    ...values,
});

export const mapScEventNameToDisplay = (SCEvent : SCEvent) => {
    return SCEvent.name === FOLDER_TO_ESE_STRING ? FOLDER_TO_ESE_DISPLAY : SCEvent.name
}

type Props = {
    canSave : boolean;
    canDelete : boolean;
    siteLists : Array<List>;
    activeChild : Child;
    SCEvent : SCEvent;
    handleClearActiveSCEvent : () => void;
    eventValues : SCEventValues;
    sortedEvents : Array<SCDisplayEvent>;
    serviceCoordinationEvents : Array<SCEvent>,
    handleSetActiveSCEvent : (scEvent : SCEvent) => void;
    handleSCEventSubmit : (values : FormikValues, scEventUUID : string, eventDateField : string) => Promise<void>;
    eventName: SCEventName;
    handleRemoveSCE: (eventUUID: string) => Promise<void>;
};

export type BaseFormValues = {
    EventNotes : string;
    'DemoEntry to ServiceCoordination by ANUnique::ActionNeeded' : string;
    'DemoEntry to ServiceCoordination by ANUnique::ANCompletedDate' : string;
    'DemoEntry to ServiceCoordination by ANUnique::ANFollowUpDate' : string;
    UUID : string;
    RecordCreator : string;
    UpdateDate : string;
    EventModifier : string;
};

export type contactListTypes = 'ListAPRFCRI' | 'ProviderList'
type contactDetails = {
    phone: string;
    fax: string;
    email: string;
}

const SCEventForm = ({
    canSave,
    canDelete,
    siteLists,
    activeChild,
    SCEvent,
    handleClearActiveSCEvent,
    eventValues,
    sortedEvents,
    serviceCoordinationEvents,
    handleSetActiveSCEvent,
    handleSCEventSubmit,
    eventName,
    handleRemoveSCE
} : Props) => {
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const apiFetch = useApiFetch();

    const handleSetFormIsModified = useCallback(async (formIsModified : boolean) => {
        await setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const initialValues : NameToValueMap = eventValues;

    const getContactDetails = useCallback(async (
        listName: contactListTypes,
        sceUUID: string,
        listValue: string
    ) : Promise<contactDetails | undefined> => {
        const url = new URL(
            `/v1/child/contact/`
            + `${listName}/${sceUUID}/${listValue}`,
            apiEndpoint
        );

        const response = await apiFetch(url.toString(), {
            method: 'GET',
        });

        if (response.status === 403) {
            return;
        }

        const data = await response.json();
        return data as contactDetails;
    }, [apiFetch]);

    const updateContact = useCallback(async (
        listName: contactListTypes,
        formProps: FormikProps<any>,
        selectValue: string,
        faxField: string,
        phoneField: string,
        emailFiled: string
    ) => {
        formProps.setFieldValue(faxField, 'Loading ...');
        formProps.setFieldValue(phoneField, 'Loading ...');
        formProps.setFieldValue(emailFiled, 'Loading ...');
        await formProps.submitForm();
        const contactInfo = await getContactDetails(listName, SCEvent.UUID, selectValue);
        formProps.setFieldValue(faxField, contactInfo?.fax);
        formProps.setFieldValue(phoneField, contactInfo?.phone);
        formProps.setFieldValue(emailFiled, contactInfo?.email);
    }, [SCEvent.UUID, getContactDetails]);

    const getSCEventForm = (eventName : SCEventName, formProps: FormikProps<any>) : JSX.Element | null => {
        switch (eventName) {
            case CONTACT_LOG_STRING:
                return <ContactLogForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                />;
            case REFERRAL_CLOSED_STRING:
                return <ReferralClosedForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                />;
            case REFERRAL_FIRST_CONTACT_STRING:
                return <ReferralFirstContactForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                    formProps={formProps}
                    updateListAPRFCRI={updateContact}
                />;
            case APPOINTMENTS_STRING:
                return <AppointmentsForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                />;
            case REFERRAL_OUT_STRING:
                return <ReferralOutForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                />;
            case REFERRAL_IN_STRING:
                return <ReferralInForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                    formProps={formProps}
                    updateListAPRFCRI={updateContact}
                />;
            case REFERRAL_INACTIVE_STRING:
                return <ReferralInactiveForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                />;
            case FOLDER_TO_ESE_STRING:
                return <FolderToESEForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                />;
            case ON_HOLD_STRING:
                return <OnHoldForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                />;
            case PACKET_RETURNED_STRING:
                return <PacketReturnedForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                />;
            case PACKET_SENT_STRING:
                return <PacketSentForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                />;
            case PERIODIC_FOLLOW_UP_STRING:
                return <PeriodicFollowUpForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                />;
        }
        return null;
    };

    const getSCEventSchema : any = {
        'Contact Log' : ContactLogSchema,
        'Referral Closed' : ReferralClosedSchema,
        'Referral First Contact' : ReferralFirstContactSchema,
        'Appointments' : AppointmentsSchema,
        'Referral Out' : ReferralOutSchema,
        'Referral In' : ReferralInSchema,
        'Referral Inactive' : ReferralInactiveSchema,
        'Folder To ESE' : FolderToESESchema,
        'On Hold' : OnHoldSchema,
        'Packet Returned' : PacketReturnedSchema,
        'Packet Sent' :PacketSentSchema,
        'Periodic Follow-Up' : PeriodicFollowUpSchema
    };

    const getSCEventDateField : any = {
        'Contact Log' : 'ConLogDateOfContact',
        'Referral Closed' : 'ReferralClosedDate',
        'Referral First Contact' : 'ReferralFirstContactDate',
        'Appointments' : 'ApptDate',
        'Referral Out' : 'ReferralOutDate',
        'Referral In' : 'ReferralInDate',
        'Referral Inactive' : 'ReferralInactiveDate',
        'Folder To ESE' : 'FolderToESEDate',
        'On Hold' : 'OnHoldDate',
        'Packet Returned' : 'PacketReturnedDate',
        'Packet Sent' : 'PacketSentDate',
        'Periodic Follow-Up' : 'PeriodicFollowUpDate',
    };

    const schema = yup.object({
        EventNotes: yup.string(),
        'DemoEntry to ServiceCoordination by ANUnique::ActionNeeded': yup.string(),
        'DemoEntry to ServiceCoordination by ANUnique::ANCompletedDate': yupDateRegex,
        'DemoEntry to ServiceCoordination by ANUnique::ANFollowUpDate': yupDateRegex,
        ...getSCEventSchema[eventName]
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values, formikHelpers) => {
                setLoading(true);
                await handleSCEventSubmit(values, SCEvent.UUID, getSCEventDateField[eventName]);
                await handleSetFormIsModified(false);
                setLoading(false);
            }}
            enableReinitialize={true}
        >
        {(props) => {
            return <Form className="chris-form-bg pt-3">
                <EventControls
                    handleClearActiveSCEvent={handleClearActiveSCEvent}
                    loading={loading}
                    formIsModified={formIsModified}
                    submitForm={props.submitForm}
                    canSave={canSave}
                    canDelete={canDelete && SCEvent.name !== REFERRAL_FIRST_CONTACT_STRING}
                    isValid={props.isValid}
                    sortedEvents={sortedEvents}
                    activeSCDisplayEvent={sortedEvents.find(
                        (scDisplayEvent) => scDisplayEvent.UUID === SCEvent.UUID)
                    }
                    handleSetActiveSCEvent={handleSetActiveSCEvent}
                    serviceCoordinationEvents={serviceCoordinationEvents}
                    handleRemoveSCE={handleRemoveSCE}
                />
                <Row className="mx-0 px-2">
                    <Col xs={12} className="d-flex flex-column align-items-center">
                        <h3 className="event-title">{mapScEventNameToDisplay(SCEvent)}</h3>
                    </Col>
                </Row>
                <EventSummary activeChild={activeChild} eventValues={eventValues}/>
                {getSCEventForm(eventName, props)}
                <EventFooter handleSetFormIsModified={handleSetFormIsModified} canSave={canSave}/>
                <ModifiedPrompt formIsModified={formIsModified} loading={loading}/>
            </Form>
        }}
        </Formik>
    );
};

export default SCEventForm;
