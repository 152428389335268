import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../../Fields/StandardField";
import * as yup from 'yup';
import {List} from "../../../../pages/RecordLocator";
import {getOptionsForField, yupDateRegex} from "../../../../utils/formHelpers";
import {BaseFormValues} from "./SCEventForm";

export const REFERRAL_CLOSED_STRING = 'Referral Closed';
export type REFERRAL_CLOSED = typeof REFERRAL_CLOSED_STRING;

export type ReferralClosedValues = BaseFormValues & {
    ReferralClosedDate : string;
    ReferralClosedReason : string;
};

export const ReferralClosedSchema = {
    ReferralClosedReason: yup.string(),
    ReferralClosedDate: yupDateRegex.required('You must enter a date')
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
};

const ReferralClosedForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified
} : Props) => {

    return (
        <>
            <Row className="mx-0 p-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralClosedDate"
                        type="date"
                        label="Referral Closed Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 p-2">
                <Col xs={12}>
                    <StandardField
                        name="ReferralClosedReason"
                        type="select"
                        label="Referral Closed Reason"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'InactiveClosed',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
        </>
    );
};

export default ReferralClosedForm;