import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Form, Formik, FormikValues} from 'formik';
import React, {useCallback, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useHistory} from "react-router-dom";
import * as yup from 'yup';
import {FormField, SiteForm} from '../SiteForms/Forms';
import SystemAlert from "../SystemAlert";
import FormControls from './Fields/FormControls';
import StandardField from './Fields/StandardField';
import ModifiedPrompt from "./ModifiedPrompt";
import {ChrisUserProviderContext} from "../Providers/ChrisUserProvider";

export interface NameToValueMap {
    [key: string]: any;
}

type Props = {
    activeForm: SiteForm;
    activeFormFields: FormField[];
    setActiveForm?: (activeForm: SiteForm | null) => void;
    handleFormBuilderSubmit: (values: FormikValues, submitType: SubmitType) => void;
    canSave: boolean;
    addSchema: NameToValueMap;
    disabledConditions: any;
}

const anyFieldEmpty = (disabledConditions: any, values: FormikValues, name: string): boolean => {
    let valueFound = false;

    if (disabledConditions[name]) {
        const fields = disabledConditions[name];
        fields.forEach((field: string) => {
            if (values[field] === '') {
                valueFound = true;
            }
        })
    }

    return valueFound;
}

export type SubmitType = 'save' | 'email' | 'print';

const FormBuilder = ({
    activeForm,
    activeFormFields,
    setActiveForm,
    handleFormBuilderSubmit,
    canSave,
    addSchema,
    disabledConditions,
}: Props) => {
    const [{chrisUser}] = React.useContext(ChrisUserProviderContext);
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showSystemAlert, setShowSystemAlert] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<string>('');
    const [submitType, setSubmitType] = useState<SubmitType>('save');

    const handleSetFormIsModified = useCallback(async (formIsModified: boolean) => {
        setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const systemAlert = useCallback(async (alertText: string, alertTimeout: number) => {
        setAlertText(alertText);
        setShowSystemAlert(true);
        setTimeout(() => {
            setShowSystemAlert(false);
        }, alertTimeout);
    }, [setShowSystemAlert, setAlertText]);

    let initialValues: NameToValueMap = {};

    activeFormFields.map((formField: FormField) => {
        if (formField.type === 'checkbox' || formField.type === 'radio') {
            let selectedOptions: Array<string> = [];
            formField.options.map((option) => {
                if (option.checked) {
                    selectedOptions.push(option.value)
                }
                return null;
            });
            initialValues[formField.name] = selectedOptions;
        } else {
            initialValues[formField.name] = formField.value;
        }
        return null;
    });

    let validationSchema: any = addSchema ?? {};

    activeFormFields.forEach((formField: FormField) => {
        let definition = yup.string();

        if (addSchema[formField.name]) {
            // add custom validation
            definition = addSchema[formField.name];

        } else if (formField.required) {
            definition = definition.required();
        }

        validationSchema[formField.name] = definition;
    });

    const enabledFields = activeFormFields.filter(formField => !formField.disabled && !formField.labelOnly);
    const schema = yup.object(validationSchema);
    let history = useHistory();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values, formikHelpers) => {
                setLoading(true);
                handleFormBuilderSubmit(values, submitType);
                await handleSetFormIsModified(false);
                setSubmitType('save');
                setLoading(false);
            }}
        >
            {(props) => {
                const emailHasValue = props.getFieldMeta('EmailSendTo').value !== '';

                return <Form>
                    <Row className="mx-0">
                        <Col lg={7} className="d-flex flex-row pt-3 pl-2">
                            {setActiveForm && <FontAwesomeIcon
                                icon={faExchangeAlt}
                                className="btn-link cursor-pointer mx-2"
                                onClick={() => {
                                    history.push(`/record-locator?sub=forms`);
                                    if (history.location.search === `?sub=forms`) {
                                        setActiveForm(null);
                                    }
                                }}
                            />}<h3>{activeForm.title}</h3>
                        </Col>
                        <Col lg={5} xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                            <FormControls
                                formIsModified={formIsModified}
                                loading={loading}
                                submitForm={props.submitForm}
                                showSave={enabledFields.length > 0 && canSave}
                                systemAlert={systemAlert}
                                setSubmitType={setSubmitType}
                                submitType={submitType}
                                showEmail={true}
                                showPrint={true}
                                isValid={props.isValid}
                                emailHasValue={emailHasValue}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <p className="my-4">{activeForm.description}</p>
                        </Col>
                    </Row>
                    <Row className="chris-form-bg mx-0 py-3 px-2">
                        {activeFormFields.map((formField, iteration) => {
                            const colWidth = ((formField.type === 'checkbox'
                                || formField.type === 'radio') && formField.options.length > 3)
                                || formField.type === 'textarea' ? 12 : 4;

                            let formOptions = formField.options;

                            if (formField.name === 'COSFormDistrictAtExit') {
                                formOptions = [...formOptions];
                                if (!props.values['RecordTransfer']?.includes('Y') && chrisUser.districts) {
                                    formOptions = formOptions
                                        .filter((county) => chrisUser.districts.includes(county.label)
                                            || formField.value === county.label
                                            || county.label === 'FSDB')
                                } else {
                                    formOptions = formOptions
                                        .filter((county) => !chrisUser.districts.includes(county.label))
                                }
                            }
                            return (
                                <React.Fragment key={iteration}>
                                    {formField.labelOnly ? (
                                        <Col xs={12} lg={12} className="pt-2 pb-2 label-only">{formField.label}</Col>
                                    ) : (
                                        <React.Fragment>
                                            <Col xs={12} lg={colWidth}>
                                                <StandardField
                                                    key={iteration}
                                                    name={formField.name}
                                                    type={formField.type}
                                                    label={formField.label}
                                                    options={formOptions}
                                                    disabled={formField.disabled || !canSave || anyFieldEmpty(disabledConditions, props.values, formField.name)}
                                                    handleSetFormIsModified={handleSetFormIsModified}
                                                    className={formField.type === 'textarea' ? 'textarea-lg' : ''}
                                                />
                                            </Col>
                                            {formField.breakAfter && (
                                                <Col xs={12} lg={12} />
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end mt-3">
                            {canSave && (
                                <FormControls
                                    formIsModified={formIsModified}
                                    loading={loading}
                                    submitForm={props.submitForm}
                                    showSave={enabledFields.length > 0 && canSave}
                                    systemAlert={systemAlert}
                                    setSubmitType={setSubmitType}
                                    submitType={submitType}
                                    showEmail={true}
                                    showPrint={true}
                                    isValid={props.isValid}
                                    emailHasValue={emailHasValue}
                                />
                            )}
                        </Col>
                    </Row>
                    <ModifiedPrompt formIsModified={formIsModified} loading={loading} />
                    <SystemAlert show={showSystemAlert} alertText={alertText} setShowSystemAlert={setShowSystemAlert} />
                </Form>
            }}
        </Formik>
    );
};

export default FormBuilder;