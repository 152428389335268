import React from 'react';
import {Child} from "../../pages/RecordLocator";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";
import {ChildSummary} from "../Providers/RecordLocatorProvider";
import {ChrisUserProviderContext} from "../Providers/ChrisUserProvider";

type Props = {
    activeChild : Child;
    handleShowChildSearchHelperModal? : (show : boolean) => void;
    childSummary? : ChildSummary;
}

const ActiveChildNav = ({activeChild, handleShowChildSearchHelperModal, childSummary} : Props) => {
    const [{chrisUser}] = React.useContext(ChrisUserProviderContext);
    const renderTooltip = (props : any) => (
        <Tooltip {...props}>
            Available Records
        </Tooltip>
    );
    let history = useHistory();

    return (
        <div className="d-flex flex-column flex-lg-row py-1 text-primary my-0 pl-1 active-child-text">
            <div className="d-flex flex-row">
                {handleShowChildSearchHelperModal && (
                <div className="pt-2" onClick={() => handleShowChildSearchHelperModal(true)}>
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 500, hide: 100 }}
                        overlay={renderTooltip}
                        trigger={['focus', 'hover']}
                    >
                    <FontAwesomeIcon icon={faSearch} size="lg" className="cursor-pointer btn-link-search" />
                    </OverlayTrigger>
                </div>
                )}
                <div className="active-child-gt px-3 mt-0">&gt;</div>
            </div>
            {handleShowChildSearchHelperModal && (
            <div
                className="px-0 pr-md-2 btn-link cursor-pointer pt-2"
                onClick={() => handleShowChildSearchHelperModal(true)}
            >{childSummary ? childSummary.name : activeChild.name}</div>
            )}
            {!handleShowChildSearchHelperModal && !chrisUser.isCosUser && (
                <div
                    className="px-0 pr-md-2 btn-link cursor-pointer pt-2"
                    onClick={() => history.push('/record-locator?sub=demographics')}
                >{childSummary ? childSummary.name : activeChild.name}</div>
            )}
            {activeChild.databaseNumber && <div className="px-0 px-md-2 pt-2"><strong>DBNUM:</strong>&nbsp;{activeChild.databaseNumber}</div>}
            {activeChild.childId && <div className="px-0 px-md-2 pt-2"><strong>Child ID:</strong>&nbsp;{activeChild.childId}</div>}
            {(activeChild.age ?? childSummary?.age) && <div className="px-0 px-md-2 pt-2"><strong>Age:</strong>&nbsp;{childSummary ? childSummary.age : activeChild.age}</div>}
            {activeChild.recordCreator && <div className="px-0 px-md-2 pt-2"><strong>Record Creator:</strong>&nbsp;{activeChild.recordCreator}</div>}
            {activeChild.displayDOB && <div className="px-0 px-md-2 pt-2"><strong>DOB:</strong>&nbsp;{activeChild.displayDOB}</div>}
        </div>
    );
};

export default ActiveChildNav;
