import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import NavHeader from "../components/Navigation/NavHeader";

const Help = () => {

    return (
        <>
            <NavHeader />
            <Container>
                <Row>
                    <Col xs={12} className="d-flex flex-column align-items-center mt-4">
                        <h4>Coming Soon ...</h4>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Help;