import React from 'react';
import {Col, Container, Navbar, Row} from "react-bootstrap";
import {ChrisUser} from "../Providers/ChrisUserProvider";

type Props = {
    children? : any;
    chrisUser : ChrisUser;
};

const ActiveUserNav = ({children, chrisUser} : Props) => {
    return (
        <Navbar expand={true} className="active-child-bg py-0">
            <Container>
                <Row className="w-100">
                    <Col xs={12} lg={11}>
                        {children}
                    </Col>
                    <Col
                        xs={12}
                        lg={1}
                        className="d-flex justify-content-end flex-row pr-0 mr-0 active-child-text text-primary"
                    >
                        <div className="py-2 chris-user-name"><strong>{chrisUser.name}</strong></div>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    );
};

export default ActiveUserNav;
