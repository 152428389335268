import React, {useCallback, useState} from 'react';
import {Child, List} from "../../pages/RecordLocator";
import {useApiFetch} from "../Providers/OidcProvider";
import {Col, Row} from "react-bootstrap";
import TrackingSubNav, {ActiveTrackingTab} from "../Navigation/TrackingSubNav";
import {FormikValues} from "formik";
import {mapFormikValuesForFM} from "../../utils/formHelpers";
import {apiEndpoint} from "../../utils/api";
import TrackingGeneralForm, {TrackingGeneral} from "../Forms/Tracking/TrackingGeneralForm";
import SCEvents, {SCEvent} from "./SCEvents";
import TimelineEvents, {Timeline} from "./TimelineEvents";
import GeneralErrorModal from "../Modal/GeneralErrorModal";
import {ChildSummary} from "../Providers/RecordLocatorProvider";

export type ChildTracking = {
    trackingGeneral : TrackingGeneral;
    serviceCoordinationEvents : Array<SCEvent>;
    timelines : Array<Timeline>
};

type Props = {
    activeChild : Child;
    siteLists : Array<List>;
    systemAlert : (alertText : string, alertTimeout : number) => Promise<void>;
    canSave : boolean;
    canDelete: boolean;
    tracking : ChildTracking;
    childSummary : ChildSummary;
};

const mapTrackingGeneral = (values : any) : TrackingGeneral => ({
    ...values
});

const Tracking = ({
    activeChild,
    siteLists,
    systemAlert,
    canSave,
    canDelete,
    tracking,
    childSummary
} : Props) => {
    const [activeTrackingTab, setActiveTrackingTab] = useState<ActiveTrackingTab>('trackingGeneral');
    const [showContactLog, setShowContactLog] = useState<boolean>(true);
    const [showGeneralErrorModal, setShowGeneralErrorModal] = useState<boolean>(false);

    const apiFetch = useApiFetch();

    const handleSetActiveTrackingTab = useCallback( (activeTab : ActiveTrackingTab) => {
        setActiveTrackingTab(activeTab);
    }, [setActiveTrackingTab]);

    const handleSetShowContactLog = useCallback( (showContactLog : boolean) => {
        setShowContactLog(showContactLog);
    }, [setShowContactLog]);

    const handleTrackingSubmit = useCallback(async (values : FormikValues) => {
        const mappedValues = mapFormikValuesForFM(values);
        const url = new URL(`/v1/child/tracking/${activeChild.childUUID}`, apiEndpoint);

        const response = await apiFetch(url.toString(), {
            method: 'PUT',
            body: JSON.stringify({
                values: mappedValues
            })
        });

        if (response.status === 403) {
            await systemAlert('Invalid access for child', 5000);
            return;
        }

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        if (activeTrackingTab === 'trackingGeneral') {
            tracking.trackingGeneral = mapTrackingGeneral(values);
        }

    }, [apiFetch, activeChild, systemAlert, activeTrackingTab, tracking]);

    return (
        <>
            <Row className="mt-4">
                <Col xs={12} lg={3}>
                    <TrackingSubNav
                        activeTrackingTab={activeTrackingTab}
                        handleSetActiveTrackingTab={handleSetActiveTrackingTab}
                    />
                </Col>
                <Col xs={12} lg={9}>
                    {activeTrackingTab === 'trackingGeneral' && childSummary && (
                        <TrackingGeneralForm
                            canSave={canSave}
                            siteLists={siteLists}
                            handleTrackingSubmit={handleTrackingSubmit}
                            activeChild={activeChild}
                            trackingGeneral={tracking.trackingGeneral}
                            childSummary={childSummary}
                        />
                    )}
                    {activeTrackingTab === 'trackingSCEvents' && (
                        <SCEvents
                            tracking={tracking}
                            handleSetShowContactLog={handleSetShowContactLog}
                            showContactLog={showContactLog}
                            canSave={canSave}
                            canDelete={canDelete}
                            siteLists={siteLists}
                            activeChild={activeChild}
                            systemAlert={systemAlert}
                        />
                    )}
                    {activeTrackingTab === 'trackingTimeline' && (
                        <TimelineEvents
                            tracking={tracking}
                            canSave={canSave}
                            canDelete={canDelete}
                            siteLists={siteLists}
                            activeChild={activeChild}
                            systemAlert={systemAlert}
                        />
                    )}
                </Col>
            </Row>
            <GeneralErrorModal
                show={showGeneralErrorModal}
                handleShowGeneralErrorModal={setShowGeneralErrorModal}
            />
        </>
    );
};

export default Tracking;
