import React, {useEffect} from 'react';
import FullPageLoadingIndicator from '../../components/FullPageLoadingIndicator';
import {useHistory} from "react-router-dom";

const SignOutCallback : React.FC = () => {
    const history = useHistory();

    useEffect(() => {
                history.push('/');
    }, [history]);

    return <FullPageLoadingIndicator/>;
};

export default SignOutCallback;
