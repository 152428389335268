import React, {useState} from 'react';
import ModalBase from './ModalBase';
import {Button, Col, Row} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

type Props = {
    show : boolean;
    handleShowAddTimelineUnder3Modal : (show : boolean) => void;
    handleAddTimeline : () => Promise<void>;
};

const AddTimelineUnder3Modal = ({show, handleShowAddTimelineUnder3Modal, handleAddTimeline} : Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const handleCloseModal = () => {
        handleShowAddTimelineUnder3Modal(false);
    };

    return (
        <ModalBase
            show={show}
            title=""
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleCloseModal}
        >
            <Row className="chris-form-bg mx-0 py-3 px-2">
                <Col xs={12}> The child is under the age of 2 years and 3 months. Are you sure you want to add a timeline? </Col>
            </Row>
            <Col xs={12}><hr /></Col>
            <Row className="mx-0 mt-2">
                <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                    <Button
                        variant="outline-secondary"
                        type="button"
                        className="d-flex pt-2 flex-row align-content-center mr-2"
                        onClick={async () => {
                            handleCloseModal();
                        }}
                    >Cancel</Button>
                    <Button
                        variant="secondary"
                        type="button"
                        className="d-flex pt-2 flex-row align-content-center"
                        onClick={async () => {
                            setLoading(true);
                            await handleAddTimeline();
                        }}
                    >{loading ? <React.Fragment>
                        <Spinner animation="border" size="sm" className="mt-1"/>
                        &nbsp; Adding Timeline
                    </React.Fragment> : 'Yes, Add Timeline'}</Button>
                </Col>
            </Row>
        </ModalBase>
    );
};

export default AddTimelineUnder3Modal;