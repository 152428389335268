import React from 'react';
import ModalBase from './ModalBase';
import '../../../node_modules/react-simple-tree-menu/dist/main.css';
import {SiteForm} from '../SiteForms/Forms';
import {ActiveTab} from "../../pages/RecordLocator";
import AvailableFormsReports from "../SiteForms/AvailableFormsReports";

type Props = {
    show : boolean;
    siteForms : Array<SiteForm>;
    handleSetActiveForm : (siteForm : SiteForm) => void;
    setActiveTab: (activeTab : ActiveTab) => void;
    siteName : string;
};

const AvailableFormsModal = ({
    show,
    siteForms,
    handleSetActiveForm,
    setActiveTab,
    siteName
} : Props) => {
    const handleCloseModal = () => {
        setActiveTab('demographics');
    };

    return (
        <ModalBase
            show={show}
            title=""
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleCloseModal}
        >
            <AvailableFormsReports
                siteFormsReports={siteForms}
                siteName={siteName}
                handleSetActiveFormReport={handleSetActiveForm}
                type="Form"
            />
        </ModalBase>
    );
};

export default AvailableFormsModal;