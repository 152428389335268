import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {FacilitatorListOption} from "../../pages/ManageLists";
import Spinner from "react-bootstrap/Spinner";

type Props = {
    activeListOption: FacilitatorListOption,
    handleUpdateListOption: (
        UUID: string,
        active: number,
        activeListOptions: Array<FacilitatorListOption>,
        activeList: string
    ) => Promise<void>;
    activeListOptions: Array<FacilitatorListOption>,
    activeList: string;
};


const DataFacilitatorOptionToggle = ({
    activeListOption,
    handleUpdateListOption,
    activeListOptions,
    activeList
} : Props) => {
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Button
            variant={activeListOption.active === 1 ? 'outline-secondary' : 'danger'}
            type="button"
            className="d-flex pt-2 flex-row align-content-center"
            disabled={loading}
            onClick={async () => {
                setLoading(true);
                await handleUpdateListOption(
                    activeListOption.value,
             activeListOption.active === 1 ? 0 : 1,
                    activeListOptions,
                    activeList
                );
                setLoading(false);
            }}
        >
            {loading ? (
                <React.Fragment>
                    <Spinner animation="border" size="sm" className="mt-1"/>
                    &nbsp; Updating
                </React.Fragment>
            ) : (
                <>
                {activeListOption.active === 1
                    ? <><FontAwesomeIcon icon={faEye} size="sm" className="mt-1"/>&nbsp; Active</>
                    : <><FontAwesomeIcon icon={faEyeSlash} size="sm" className="mt-1"/>&nbsp; Inactive</>
                }
                </>
            )}
        </Button>
    );
};

export default DataFacilitatorOptionToggle;