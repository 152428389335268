import React, {useCallback, useState} from 'react';
import ModalBase from './ModalBase';
import {Form, Formik, FormikValues} from "formik";
import {Button, Col, Row} from "react-bootstrap";
import StandardField from "../Forms/Fields/StandardField";
import * as yup from "yup";
import FormControls from "../Forms/Fields/FormControls";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faPrint} from "@fortawesome/free-solid-svg-icons";

type Props = {
    show : boolean;
    handleSubmitPrint : (values : FormikValues) => Promise<void>;
    handleClose : () => void;
};


const schema = yup.object({
    printType: yup.string().required()
});


const PrintDemoTrackingModal = ({
    show,
    handleSubmitPrint,
    handleClose
} : Props) => {
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const handleSetFormIsModified = useCallback(async (formIsModified : boolean) => {
        await setFormIsModified(formIsModified);
    }, [setFormIsModified]);


    return (
        <ModalBase
            show={show}
            title="Print Interface"
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleClose}
        >
            <Formik
                initialValues={{
                    printType: ''
                }}
                validationSchema={schema}
                enableReinitialize={true}
                onSubmit={async (values, formHelpers) => {
                    setLoading(true);
                    await handleSubmitPrint(values);
                    setLoading(false);
                    handleClose();
                }}
            >
                {(props) => {
                    return <Form>
                        <Row className="chris-form-bg mx-0 py-3 px-2">
                            <Col xs={12} lg={4}>
                                <StandardField
                                    name="printType"
                                    type="select"
                                    label="Select Print Type:"
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                    options={[
                                        {label: 'Print Demographics', value: 'demo', checked: false},
                                        {label: 'Print Demo w/ Tracking', value: 'demoTrack', checked: false},
                                        {label: 'Print Demo w/ Tracking Detail (History)', value: 'demoTrackDetail', checked: false},
                                        {label: 'Print All Three Reports Above', value: 'all', checked: false}
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row className="mx-0 mt-2">
                            <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                                <Button
                                    className="d-flex pt-2 flex-row align-content-center mr-2"
                                    variant="outline-secondary"
                                    onClick={() => { handleClose(); }}
                                    disabled={loading}
                                ><FontAwesomeIcon icon={faBan} size="sm" className="mt-1"/>
                                    &nbsp; Cancel</Button>
                                <FormControls
                                    formIsModified={formIsModified}
                                    loading={loading}
                                    submitForm={props.submitForm}
                                    showSave={true}
                                    submitType={'save'}
                                    disableAutoSave={true}
                                    isValid={props.isValid}
                                    saveIcon={faPrint}
                                    saveText="Print"
                                    saveTextPast="Print"
                                    saveTextAction="Printing"
                                />
                            </Col>
                        </Row>
                    </Form>
                }}
            </Formik>
        </ModalBase>
    );
};

export default PrintDemoTrackingModal;