import React, {useCallback, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBackward, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import FormControls from "../../Fields/FormControls";
import {SCDisplayEvent, SCEvent} from "../../../Tracking/SCEvents";
import { useHistory } from "react-router-dom";
import ConfirmDeleteEventModal from "../../../Modal/ConfirmDeleteEventModal";

type Props = {
    handleClearActiveSCEvent : () => void;
    loading : boolean;
    formIsModified : boolean;
    submitForm : () => Promise<void>;
    canSave : boolean;
    canDelete : boolean;
    isValid : boolean;
    sortedEvents : Array<SCDisplayEvent>;
    activeSCDisplayEvent? : SCDisplayEvent;
    handleSetActiveSCEvent : (scEvent : SCEvent) => void;
    serviceCoordinationEvents : Array<SCEvent>;
    handleRemoveSCE: (eventUUID: string) => Promise<void>;
};

const EventControls = ({
    handleClearActiveSCEvent,
    loading,
    formIsModified,
    submitForm,
    canSave,
    canDelete,
    isValid,
    sortedEvents,
    activeSCDisplayEvent,
    handleSetActiveSCEvent,
    serviceCoordinationEvents,
    handleRemoveSCE
} : Props) => {
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
    const currentIndex = activeSCDisplayEvent ? sortedEvents.indexOf(activeSCDisplayEvent)+1 : 0;
    let history = useHistory();

    let nextSCEvent : any = null;
    if (sortedEvents[currentIndex]) {
        nextSCEvent = serviceCoordinationEvents.find(
            (event) => event.UUID === sortedEvents[currentIndex].UUID
        );
    }

    let prevSCEvent : any = null;
    if (currentIndex-1 > 0) {
        prevSCEvent = serviceCoordinationEvents.find(
            (event) => event.UUID === sortedEvents[currentIndex - 2].UUID
        );
    }
    
    const confirmDeleteYes = useCallback(async () => {
        if (activeSCDisplayEvent?.UUID) {
            await handleRemoveSCE(activeSCDisplayEvent.UUID);
            handleClearActiveSCEvent();
        }
    }, [activeSCDisplayEvent, handleClearActiveSCEvent, handleRemoveSCE]);

    const hideConfirmDelete = useCallback(() => {
        setConfirmDelete(false);
    }, [])

    return (
        <Row className="mx-0 p-2">
            {confirmDelete && canDelete && <ConfirmDeleteEventModal
                show={true}
                handleClose={hideConfirmDelete}
                handleYes={confirmDeleteYes}
                message={"Are you sure you want to delete the current SC Event?"} />}
            <Col xs={12} className="d-flex flex-row justify-content-start pb-xs-2 pb-lg-0">
                <div
                    onClick={() => {
                        history.push(`/record-locator?sub=trackingSCEvents`);
                        if (history.location.search === `?sub=trackingSCEvents`) {
                            handleClearActiveSCEvent();
                        }
                    }}
                    className="btn-link cursor-pointer"
                >
                    <FontAwesomeIcon icon={faBackward} size="sm" className="mt-1 pr-1"/>
                    Back to SC Events</div>
            </Col>
            <Col xs={12}><hr /></Col>
            <Col xs={12} lg={4} className="d-flex flex-row justify-content-start pb-xs-2 pb-lg-0">
                {canDelete && <Button
                    variant="danger"
                    type="button"
                    className="d-flex pt-2 flex-row align-content-center"
                    disabled={loading}
                    onClick={async () => {
                        setConfirmDelete(true)
                        
                    }}
                ><FontAwesomeIcon icon={faTrashAlt} size="sm" className="mt-1 pr-1"/>Delete Event</Button>}
            </Col>
            <Col xs={12} lg={4} className="d-flex flex-row justify-content-center pb-xs-2 pb-lg-0 pt-2">
                {currentIndex > 1 && prevSCEvent && (
                    <div
                        onClick={() => {
                            history.push(`/record-locator?sub=trackingSCEvents&event=${prevSCEvent.UUID}`);
                            if (history.location.search === `?sub=trackingSCEvents&event=${prevSCEvent.UUID}`) {
                                handleSetActiveSCEvent(prevSCEvent)
                            }
                        }}
                        className="btn-link cursor-pointer"
                    >Prev</div>
                )} &nbsp;
                {currentIndex} of {sortedEvents.length} &nbsp;
                {currentIndex < sortedEvents.length && nextSCEvent && (
                    <div
                        onClick={() => {
                            history.push(`/record-locator?sub=trackingSCEvents&event=${nextSCEvent.UUID}`);
                            if (history.location.search === `?sub=trackingSCEvents&event=${nextSCEvent.UUID}`) {
                                handleSetActiveSCEvent(nextSCEvent)
                            }
                        }}
                        className="btn-link cursor-pointer"
                    >Next</div>
                )}
            </Col>
            <Col xs={12} lg={4} className="d-flex flex-row justify-content-start justify-content-lg-end">
                <FormControls
                    formIsModified={formIsModified}
                    loading={loading}
                    submitForm={submitForm}
                    showSave={canSave}
                    submitType={'save'}
                    disableAutoSave={false}
                    isValid={isValid}
                />
            </Col>
            <Col xs={12}><hr /></Col>
        </Row>
    );
}

export default (EventControls);