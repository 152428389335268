import React, {useCallback, useState} from 'react';
import NavHeader from "../components/Navigation/NavHeader";
import {Button, Col, Container, Jumbotron, Row} from "react-bootstrap";
import Forms, {SiteForm} from "../components/SiteForms/Forms";
import ActiveChildNav from "../components/Navigation/ActiveChildNav";
import Demographics from "../components/Demographics/Demographics";
import Tracking, {ChildTracking} from "../components/Tracking/Tracking";
import LocatorSubNav from "../components/Navigation/LocatorSubNav";
import RecordLocatorForm from "../components/Forms/RecordLocatorForm";
import {FormikValues} from "formik";
import {apiEndpoint, apiFetch as standardFetch} from "../utils/api";
import BootstrapTable from 'react-bootstrap-table-next';
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import RecordTransferModal from "../components/Modal/RecordTransferModal";
import {StandardFieldOption} from "../components/Forms/Fields/StandardField";
import CircularLoadingIndicator from "../components/CircularLoadingIndicator";
import {GeneralDemographics} from "../components/Forms/Demographics/DemographicsGeneralForm";
import {useApiFetch} from "../components/Providers/OidcProvider";
import {SignificantAdult} from "../components/Forms/Demographics/SignificantAdultsForm";
import {OtherAdults} from "../components/Forms/Demographics/OtherAdultsForm";
import {CriticalInfo} from "../components/Forms/Demographics/CriticalInfoForm";
import ChildSearchHelperModal from "../components/Modal/ChildSearchHelperModal";
import SystemAlert from "../components/SystemAlert";
import {useHistory} from "react-router-dom";
import {mapFormikValuesForFM} from "../utils/formHelpers";
import AddChildModal from "../components/Modal/AddChildModal";
import {ChrisUserProviderContext} from "../components/Providers/ChrisUserProvider";
import PrintDemoTrackingModal from "../components/Modal/PrintDemoTrackingModal";
import {saveAs} from "file-saver";
import ActiveUserNav from "../components/Navigation/ActiveUserNav";
import {dateSort} from "../utils/sort";
import {ActiveChildProviderContext} from "../components/Providers/ActiveChildProvider";
import GeneralErrorModal from "../components/Modal/GeneralErrorModal";
import CosTooOldModal from "../components/Modal/CosTooOldModal";
import {RecordLocatorProviderContext} from "../components/Providers/RecordLocatorProvider";

export type ActiveTab = 'demographics' | 'tracking' | 'forms' | null;
export type RecordAccess = 'r' | 'rw' | 'rwd';

type Tabs = {
    demographics: JSX.Element;
    tracking: JSX.Element;
    forms: JSX.Element;
};

export type Child = {
    childUUID: string;
    databaseNumber: string;
    siteUUID: string;
    name: string;
    childId: string;
    age: string;
    recordCreator: string;
    access: RecordAccess;
    generalDemographics: GeneralDemographics;
    significantAdults: Array<SignificantAdult>;
    otherAdults: OtherAdults;
    criticalInfo: CriticalInfo;
    SY5: string;
    ageDetailedDisplay: string;
    displayDOB: string;
    thirdBirthDay: string;
    code: string;
    staffAssignedFullName: string;
    siteName: string;
    tracking: ChildTracking;
};

export type List = {
    name: string;
    values: Array<StandardFieldOption>;
};

const baseColumns = [
    {
        dataField: 'dbnum',
        text: 'DBNUM',
        sort: true,
        classes: 'd-none d-lg-table-cell',
        headerClasses: 'd-none d-lg-table-cell',
    },
    {
        dataField: 'lastName',
        text: 'Last Name',
        sort: true
    },
    {
        dataField: 'firstName',
        text: 'First Name',
        sort: true
    },
    {
        dataField: 'birthDate',
        text: 'Birth Date',
        sort: true,
        sortFunc: dateSort
    },
    {
        dataField: 'code',
        text: 'Code',
        sort: true,
        classes: 'd-none d-lg-table-cell',
        headerClasses: 'd-none d-lg-table-cell',
    },
    {
        dataField: 'countyOfResidence',
        text: 'Residence County',
        sort: true,
        classes: 'd-none d-lg-table-cell',
        headerClasses: 'd-none d-lg-table-cell',
    },
    {
        dataField: 'district',
        text: 'District',
        sort: true,
        classes: 'd-none d-lg-table-cell',
        headerClasses: 'd-none d-lg-table-cell',
    },
    {
        dataField: 'parentLastName',
        text: 'Sig Adult Last Name',
        sort: true,
        classes: 'd-none d-lg-table-cell',
        headerClasses: 'd-none d-lg-table-cell',
    },
    {dataField: 'timelineStatus', text: 'Timeline Status', headerStyle: {width: '80px'}},
    {dataField: 'cos', text: 'COS', headerStyle: {width: '70px'}}
];

const indicator7Columns = [
    ...baseColumns
];

const columns = [
    ...baseColumns,
    {dataField: 'demographics', text: 'Demo', headerStyle: {width: '70px'}},
    {dataField: 'tracking', text: 'Track', headerStyle: {width: '70px'}},
];



export const mapRawChildDemographics = (rawChildDemographics: any): Child => ({
    ...rawChildDemographics,
    generalDemographics: {
        ...rawChildDemographics.generalDemographics,
        DOB: rawChildDemographics.generalDemographics.DOB
    },
    significantAdults: rawChildDemographics.significantAdults.map((significantAdult: SignificantAdult) => ({
        ...significantAdult
    })),
    otherAdults: {
        ...rawChildDemographics.otherAdults
    },
    criticalInfo: {
        ...rawChildDemographics.criticalInfo
    },
    displayDOB: rawChildDemographics.generalDemographics.DOB,
});



const RecordLocator = () => {
    const [activeForm, setActiveForm] = useState<SiteForm | null>(null);
    const [showSystemAlert, setShowSystemAlert] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<string>('');
    const [showAddChild, setShowAddChild] = useState<boolean>(false);
    const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
    const [chrisUserState, userSiteLists] = React.useContext(ChrisUserProviderContext);
    const [loadedActiveChild] = React.useContext(ActiveChildProviderContext);
    const [loadingAddChild, setLoadingAddChild] = useState<boolean>(false);
    const { loading, handleShowChildSearchHelperModal, handleSetActiveChild, setChildSummary, childSummary, tracking, siteLists, setActiveChild, activeChild, setActiveTab, activeTab, searchOptions, cosChildUUID, showCosTooOldModal, setShowCosTooOldModal, showGeneralErrorModal, showRecordTransferModal, handleShowTransferRecordModal, transferChild, possibleDuplicates, handleClearSearchForm, setShowGeneralErrorModal, searchResults, tooManyResults} = React.useContext(RecordLocatorProviderContext);
    const apiFetch = useApiFetch();
    let history = useHistory();

    const systemAlert = useCallback(async (alertText: string, alertTimeout: number) => {
        await setAlertText(alertText);
        await setShowSystemAlert(true);
        setTimeout(() => {
            setShowSystemAlert(false);
        }, alertTimeout);
    }, [setShowSystemAlert, setAlertText]);

    const handleSetActiveTab = async (activeTab: ActiveTab) => {
        setActiveTab(activeTab);
    };



    const handleClearActiveChild = useCallback(() => {
        setActiveTab(null);
        setActiveChild(null);
        handleClearSearchForm();
    }, [setActiveTab, setActiveChild, handleClearSearchForm]);


    const getActiveContent: Tabs = {
        forms: activeChild && tracking
            ? <Forms
                activeChild={activeChild}
                handleSetActiveTab={handleSetActiveTab} siteLists={siteLists}
                canSave={activeChild.access === 'rw' || activeChild.access === 'rwd'}
                activeForm={activeForm}
                setActiveForm={setActiveForm}
            />
            : <CircularLoadingIndicator />,
        demographics: activeChild && tracking
            ? <Demographics
                activeChild={activeChild}
                siteLists={siteLists ? siteLists : []}
                generalDemographics={activeChild?.generalDemographics}
                significantAdults={activeChild?.significantAdults}
                otherAdults={activeChild?.otherAdults}
                criticalInfo={activeChild?.criticalInfo}
                setActiveChild={setActiveChild}
                systemAlert={systemAlert}
                canSave={activeChild.access === 'rw' || activeChild.access === 'rwd'}
                canDelete={activeChild.access === 'rwd'}
                childSummary={childSummary}
                setChildSummary={setChildSummary}
                staffAssigned={tracking?.trackingGeneral?.StaffAssignedFullName}
            />
            : <CircularLoadingIndicator />,
        tracking: activeChild && tracking && childSummary
            ? <Tracking
                activeChild={activeChild}
                siteLists={siteLists ? siteLists : []}
                systemAlert={systemAlert}
                canSave={activeChild.access === 'rw' || activeChild.access === 'rwd'}
                canDelete={activeChild.access === 'rwd'}
                tracking={tracking}
                childSummary={childSummary}
            />
            : <CircularLoadingIndicator />,
    };

    const handleSubmitAddChild = useCallback(async (values: FormikValues) => {
        const url = new URL(`/v1/child/add-child`, apiEndpoint);
        const mappedValues = mapFormikValuesForFM(values);

        const responseAddChild = await apiFetch(url.toString(), {
            method: 'POST',
            body: JSON.stringify({
                values: mappedValues
            })
        });

        if (responseAddChild.status === 409) {
            setShowGeneralErrorModal(true);
            return;
        }

        if (!responseAddChild.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const dataAddChild = await responseAddChild.json();
        setLoadingAddChild(false);
        setShowAddChild(false);
        if (!chrisUserState.chrisUser.isCosUser) {
            await handleSetActiveChild(dataAddChild.childUUID, 'demographics');
        } else {
            history.push(`/cos-form?id=${dataAddChild.childUUID}`)
        }
    }, [apiFetch, handleSetActiveChild, chrisUserState, history]);

    const handleSubmitPrint = useCallback(async (values: FormikValues) => {
        if (activeChild) {
            const url = new URL(`/v1/child/print/${activeChild.childUUID}`, apiEndpoint);

            const responsePrint = await apiFetch(url.toString(), {
                method: 'POST',
                body: JSON.stringify(values)
            });

            if (!responsePrint.ok) {
                setShowGeneralErrorModal(true);
                return;
            }

            const data = await responsePrint.json();

            const downloadUrl = new URL(data.pdf);
            const fileExtension = downloadUrl.pathname.split('.').slice(-1)[0];

            const pdfResponse = await standardFetch(data.pdf);
            const blob = await pdfResponse.blob();
            saveAs(
                blob,
                activeChild.childId.replace(/\s/g, '')
                + '.' + fileExtension
            );
        }
    }, [apiFetch, activeChild]);

    const handleCloseAddChild = useCallback(async () => {
        setShowAddChild(false);
    }, [setShowAddChild]);

    const handleClosePrintModal = useCallback(async () => {
        setShowPrintModal(false);
    }, [setShowPrintModal]);


    React.useEffect(() => {
        if (loadedActiveChild.activeChild) {
            handleSetActiveChild(loadedActiveChild.activeChild.childUUID, 'demographics');
        }
    }, [loadedActiveChild, handleSetActiveChild]);

    return (
        <React.Fragment>
            <NavHeader handleClearActiveChild={handleClearActiveChild} />
            <ActiveUserNav chrisUser={chrisUserState.chrisUser}>
                {activeChild && tracking && childSummary ? (
                    <ActiveChildNav
                        activeChild={activeChild}
                        handleShowChildSearchHelperModal={handleShowChildSearchHelperModal}
                        childSummary={childSummary}
                    />
                ) : (<></>)}
            </ActiveUserNav>
            <Container>
                <Row>
                    {loading ? (
                        <Col sm={12} className="bg-white py-3 mx-0 px-0">
                            <CircularLoadingIndicator />
                        </Col>
                    ) : (
                        <Col sm={12} className="bg-white py-3 mx-0 px-0">
                            {!activeTab || !activeChild || !tracking ? (
                                <React.Fragment>
                                    <RecordLocatorForm
                                        searchOptions={searchOptions}
                                        handleClearSearchForm={handleClearSearchForm}
                                        showDBNum={true}
                                        canRead={false}
                                    />
                                    {possibleDuplicates && (
                                        <Jumbotron fluid className="p-3 mt-5 alert-warning">
                                            <h2>Possible Duplicate Records Detected</h2>
                                            <p className="pt-3 mr-2">This child's record may already exist. Possible duplicate records will now be displayed. If the record
                                                you are looking for exists, select it. Otherwise click "Add Child" button to create a new child record.</p>
                                            <Button
                                                className="d-flex pt-2 flex-row align-content-center mr-2"
                                                variant="outline-secondary"
                                                onClick={() => {setShowAddChild(true);}}
                                            ><FontAwesomeIcon icon={faPlus} size="sm" className="mt-1" />
                                                &nbsp; Add a Child</Button>
                                        </Jumbotron>
                                    )}
                                    {searchResults && searchResults.length < 1 && !tooManyResults && (
                                        <div className="d-flex flex-row justify-content-center w-100 mt-5">
                                            <h3 className="pt-3 mr-2">Your request did not match any results. Would you like to</h3>
                                            <Button
                                                className="d-flex pt-2 flex-row align-content-center mr-2"
                                                variant="outline-secondary"
                                                onClick={() => {setShowAddChild(true);}}
                                            ><FontAwesomeIcon icon={faPlus} size="sm" className="mt-1" />
                                                &nbsp; Add a Child</Button>
                                        </div>
                                    )}
                                    {searchResults && tooManyResults && (
                                        <div className="d-flex flex-column align-items-center w-100 mt-5">
                                            <h3>Your search returned more than 500 results. Please add additional search
                                                criteria to limit your search results.</h3>
                                        </div>
                                    )}
                                    {searchResults && searchResults.length > 0 && (
                                        <div className="mt-5">
                                            <p className="ml-1">{searchResults.length} records found</p>
                                            <BootstrapTable
                                                bootstrap4
                                                keyField='childUUID'
                                                data={searchResults}
                                                columns={chrisUserState.chrisUser.isCosUser ? indicator7Columns : columns}
                                                pagination={paginationFactory({sizePerPage: 20})}
                                                striped
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <LocatorSubNav
                                        activeTab={activeTab}
                                        activeChild={activeChild}
                                        handleSetActiveTab={handleSetActiveTab}
                                        handleClearActiveChild={handleClearActiveChild}
                                        setActiveForm={setActiveForm}
                                        setShowPrintModal={setShowPrintModal}
                                    />
                                    {getActiveContent[activeTab]}
                                    <ChildSearchHelperModal/>
                                </React.Fragment>
                            )}
                        </Col>
                    )}
                </Row>
                <RecordTransferModal
                    show={showRecordTransferModal}
                    handleShowTransferRecordModal={handleShowTransferRecordModal}
                    transferChild={transferChild}
                />
                <AddChildModal
                    show={showAddChild}
                    handleSubmitAddChild={handleSubmitAddChild}
                    handleClose={handleCloseAddChild}
                    searchOptions={searchOptions}
                    siteLists={userSiteLists}
                    chrisUser={chrisUserState.chrisUser}
                    setLoadingAddChild={setLoadingAddChild}
                    loadingAddChild={loadingAddChild}
                />
                <PrintDemoTrackingModal
                    show={showPrintModal}
                    handleSubmitPrint={handleSubmitPrint}
                    handleClose={handleClosePrintModal}
                />
                <SystemAlert
                    show={showSystemAlert}
                    alertText={alertText}
                    setShowSystemAlert={setShowSystemAlert}
                    variant={'danger'}
                />
                <GeneralErrorModal
                    show={showGeneralErrorModal}
                    handleShowGeneralErrorModal={setShowGeneralErrorModal}
                />
                <CosTooOldModal
                    show={showCosTooOldModal}
                    handleShowModal={setShowCosTooOldModal}
                    handleContinue={() => {
                        setShowCosTooOldModal(false);
                        history.push(`/cos-form?id=${cosChildUUID}`);
                    }}
                />
            </Container>
        </React.Fragment>
    );
};

export default RecordLocator;
