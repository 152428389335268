import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../Fields/StandardField";
import * as yup from 'yup';
import {getOptionsForField} from "../../../utils/formHelpers";
import {List} from "../../../pages/RecordLocator";

export const DAYS_ELAPSED_REPORT_STRING = 'DaysElapsedReport';
export type DAYS_ELAPSED_REPORT = typeof DAYS_ELAPSED_REPORT_STRING;

export const DaysElapsedReportValues = {
    ScrFinalScreeningResultDate: '',
    TrnPartCtoPartBReferralDate : '',
    ScrFinalScreeningResult : '',
    'daysElapsedScrFinal/Consent' : '',
};

export const DaysElapsedReportSchema = {
    ScrFinalScreeningResultDate: yup.string().required(),
    TrnPartCtoPartBReferralDate : yup.string(),
    ScrFinalScreeningResult : yup.string(),
    'daysElapsedScrFinal/Consent' : yup.string().required('You must select a report type')
};

type Props = {
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
    siteLists: Array<List>;
};

const DaysElapsedReport = ({
    handleSetFormIsModified,
    siteLists
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-1">
                <Col xs={12}>
                    <h3>Find Criteria</h3>
                </Col>
            </Row>
            <Row className="mx-0 px-2 condensed-form-row">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ScrFinalScreeningResultDate"
                        type="text"
                        label="Final Screening Result Date:"
                        disabled={false}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="TrnPartCtoPartBReferralDate"
                        type="text"
                        label="Part C to Part B Transition Notification Date:"
                        disabled={false}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ScrFinalScreeningResult"
                        type="select"
                        label="Screening Final Result:"
                        disabled={false}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} className="mt-3">
                    <h3>Report Selection</h3>
                </Col>
                <Col xs={4}>
                    <StandardField
                        name="daysElapsedScrFinal/Consent"
                        type="select"
                        label="Select Report:"
                        disabled={false}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[
                            {label: 'Days Elapsed Chart', value: 'Days Elapsed Chart', checked: false},
                            {label: 'Days Elapsed Detail', value: 'Days Elapsed Detail', checked: false}
                        ]}
                    />
                </Col>
            </Row>
        </>
    );
};

export default DaysElapsedReport;