import React from 'react';
import {Col, Row} from "react-bootstrap";
import {List} from "../../../../pages/RecordLocator";
import {BaseTimelineFormValues, getDaysElapsedError, requiredFieldMsg, SudoRequiredField} from "./TimelineEventForm";
import * as yup from "yup";
import {getOptionsForField, yupDateRegex} from "../../../../utils/formHelpers";
import StandardField from "../../Fields/StandardField";
import moment from "moment";
import {isString} from "formik";

export const ESE_ELIGIBILITY_STRING = 'ESE Eligibility';
export type ESE_ELIGIBILITY = typeof ESE_ELIGIBILITY_STRING;

export type ESEEligibilityValues = BaseTimelineFormValues & {
    'DemoEntry to Timeline by AN to ESEAN::ActionNeeded' : string;
    'DemoEntry to Timeline by AN to ESEAN::ANCompletedDate' : string;
    'DemoEntry to Timeline by AN to ESEAN::ANFollowUpDate' : string;
    ESEPrimaryExceptionality : string;
    ESEAutistic : string;
    ESEDeaforHardofHearing : string;
    ESEDevelopmentallyDelayed : string;
    ESEDualSensoryImpaired : string;
    ESEEmotionalBehavioralDisability : string;
    ESEEstablishedConditions : string;
    ESEHospitalHomebound : string;
    ESEIntellectualDisability : string;
    ESELanguageImpaired : string;
    ESEOrthopedicallyImpaired : string;
    ESEOtherHealthImpaired : string;
    ESESpecificLearningDisabled : string;
    ESESpeechImpaired : string;
    ESETraumaticBrainInjured : string;
    ESEVisuallyImpaired : string;
    ESELanguageTherapy : string;
    ESEOccupationalTherapy : string;
    ESEPhysicalTherapy : string;
    ESESpeechTherapy : string;
    ESEExceptionalityStatusCalc : string;
    ESENotes : string;
    ESEEligibilityDate : string;
};

export const ESEEligibilitySchema = {
    'DemoEntry to Timeline by AN to ESEAN::ActionNeeded' : yup.string(),
    'DemoEntry to Timeline by AN to ESEAN::ANCompletedDate' : yupDateRegex,
    'DemoEntry to Timeline by AN to ESEAN::ANFollowUpDate' : yupDateRegex,
    ESEPrimaryExceptionality : yup.string(),
    ESEAutistic : yup.string(),
    ESEDeaforHardofHearing : yup.string(),
    ESEDevelopmentallyDelayed : yup.string(),
    ESEDualSensoryImpaired : yup.string(),
    ESEEmotionalBehavioralDisability : yup.string(),
    ESEEstablishedConditions : yup.string(),
    ESEHospitalHomebound : yup.string(),
    ESEIntellectualDisability : yup.string(),
    ESELanguageImpaired : yup.string(),
    ESEOrthopedicallyImpaired : yup.string(),
    ESEOtherHealthImpaired : yup.string(),
    ESESpecificLearningDisabled : yup.string(),
    ESESpeechImpaired : yup.string(),
    ESETraumaticBrainInjured : yup.string(),
    ESEVisuallyImpaired : yup.string(),
    ESELanguageTherapy : yup.string(),
    ESEOccupationalTherapy : yup.string(),
    ESEPhysicalTherapy : yup.string(),
    ESESpeechTherapy : yup.string(),
    ESEExceptionalityStatusCalc : yup.string(),
    ESENotes : yup.string(),
    ESEEligibilityDate : yup.date().min(
        moment().subtract('2', 'year').toDate(), 'ESE Eligibility Date must be later than ' +
        moment().subtract('2', 'year').format('MM/DD/YYYY')
    ).max(
        moment().toDate(),
        'Date Must Not Be In The Future.'
    )
};

export const secondaryExceptionalityFields = [
    'ESEAutistic',
    'ESEDeaforHardofHearing',
    'ESEDevelopmentallyDelayed',
    'ESEDualSensoryImpaired',
    'ESEEmotionalBehavioralDisability',
    'ESEEstablishedConditions',
    'ESEHospitalHomebound',
    'ESEIntellectualDisability',
    'ESELanguageImpaired',
    'ESESpecificLearningDisabled',
    'ESESpeechImpaired',
    'ESEVisuallyImpaired',
    'ESEOrthopedicallyImpaired',
    'ESEOtherHealthImpaired',
    'ESETraumaticBrainInjured'
];

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
    handleCheckNegativeDaysElapsed : (eventName : string, date : string) => void;
    negativeDaysElapsed : boolean;
    sudoRequiredFields: Array<SudoRequiredField>;
};

const ESEEligibilityForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified,
    handleCheckNegativeDaysElapsed,
    negativeDaysElapsed,
    sudoRequiredFields
} : Props) => {

    const secondaryExceptionalityError : Array<SudoRequiredField> | undefined = sudoRequiredFields.filter(
        (sudoRequiredField) => secondaryExceptionalityFields.includes(sudoRequiredField.name)
            && sudoRequiredField.showError
    );

    const primarySecondaryAge3AError : Array<SudoRequiredField> | undefined = sudoRequiredFields.filter(
        (sudoRequiredField) => sudoRequiredField.name === 'PrimarySecondaryAge3A'
            && sudoRequiredField.showError
    );

    return (
        <>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ESEEligibilityDate"
                        type="date"
                        label="ESE Eligibility Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        onChange={(value) => {
                            handleCheckNegativeDaysElapsed(ESE_ELIGIBILITY_STRING, isString(value) ? value : '');
                        }}
                        errorNoticeOverride={getDaysElapsedError(
                            'ESEEligibilityDate',
                            negativeDaysElapsed,
                            sudoRequiredFields
                        )}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ESEExceptionalityStatusCalc"
                        type="text"
                        label="Exceptionality Status:"
                        disabled={true}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4} className="border eligibility-types">
                    <StandardField
                        name="ESEOccupationalTherapy"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Occupational Therapy', value: 'R', checked: false}]}
                    />
                    <StandardField
                        name="ESEPhysicalTherapy"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Physical Therapy', value: 'R', checked: false}]}
                    />
                    <StandardField
                        name="ESELanguageTherapy"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Language Therapy', value: 'R', checked: false}]}
                    />
                    <StandardField
                        name="ESESpeechTherapy"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Speech Therapy', value: 'R', checked: false}]}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="ESEPrimaryExceptionality"
                        type="radio"
                        label="Primary Exceptionality (Select One)"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'PrimaryExceptionality',
                            '',
                            siteLists
                        )}
                        errorNoticeOverride={primarySecondaryAge3AError.length > 0 ?
                            requiredFieldMsg['PrimarySecondaryAge3A'] : ''
                        }
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} className="pb-2">
                    <label className="mb-0 pb-0">Secondary Exceptionality (Select as many as necessary)</label>
                    {secondaryExceptionalityError.length > 0 && (
                        <div
                            className="invalid-feedback d-block"
                        >You have not entered a Primary Exceptionality, please do so prior to exit.</div>
                    )}
                    {primarySecondaryAge3AError.length > 0 && (
                        <div
                            className="invalid-feedback d-block"
                        >{requiredFieldMsg['PrimarySecondaryAge3A']}</div>
                    )}
                </Col>
                <Col xs={12} lg={4} className="eligibility-types">
                    <StandardField
                        name="ESEAutistic"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Autism Spectrum Disorder (P)', value: 'S', checked: false}]}
                    />
                    <StandardField
                        name="ESEDeaforHardofHearing"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Deaf or Hard of Hearing (H)', value: 'S', checked: false}]}
                    />
                    <StandardField
                        name="ESEDevelopmentallyDelayed"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Developmentally Delayed (T)', value: 'S', checked: false}]}
                    />
                    <StandardField
                        name="ESEDualSensoryImpaired"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Dual Sensory Impaired (O)', value: 'S', checked: false}]}
                    />
                </Col>

                <Col xs={12} lg={4} className="eligibility-types">
                    <StandardField
                        name="ESEEmotionalBehavioralDisability"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Emotional/Behavioral Disability (J)', value: 'S', checked: false}]}
                    />
                    <StandardField
                        name="ESEEstablishedConditions"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Established Condition (U)', value: 'S', checked: false}]}
                    />
                    <StandardField
                        name="ESEHospitalHomebound"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Hospital / Homebound (M)', value: 'S', checked: false}]}
                    />
                    <StandardField
                        name="ESEIntellectualDisability"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Intellectual Disability (W)', value: 'S', checked: false}]}
                    />
                </Col>

                <Col xs={12} lg={4} className="eligibility-types">
                    <StandardField
                        name="ESELanguageImpaired"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Language Impaired (G)', value: 'S', checked: false}]}
                    />
                    <StandardField
                        name="ESESpecificLearningDisabled"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Specific Learning Disabled (K)', value: 'S', checked: false}]}
                    />
                    <StandardField
                        name="ESESpeechImpaired"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Speech Impaired (F)', value: 'S', checked: false}]}
                    />
                    <StandardField
                        name="ESEVisuallyImpaired"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Visually Impaired (I)', value: 'S', checked: false}]}
                    />
                </Col>
                <Col xs={12}>
                    <label>Physically Impaired with:</label>
                </Col>
                <Col xs={12} lg={4} className="eligibility-types">
                    <StandardField
                        name="ESEOrthopedicallyImpaired"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Orthopedic Impairment (C)', value: 'S', checked: false}]}
                    />
                    <StandardField
                        name="ESEOtherHealthImpaired"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Other Health Impairment (V)', value: 'S', checked: false}]}
                    />
                    <StandardField
                        name="ESETraumaticBrainInjured"
                        type="checkbox"
                        label=""
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[{label: 'Traumatic Brian Injury (S)', value: 'S', checked: false}]}
                    />
                </Col>
            </Row>
        </>
    );
};

export default ESEEligibilityForm;