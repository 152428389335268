import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../Fields/StandardField";
import * as yup from 'yup';
export const TIMELINE_REPORT_STRING = 'TimelineReport';
export type TIMELINE_REPORT = typeof TIMELINE_REPORT_STRING;

export const TimelineReportValues = {
    TrnTransitionMeetingDate: '',
    ScrReferralForScreeningDate : '',
    staffAssignedFullName : '',
    timelineIntervalReports: ''
};

export const TimelineReportSchema = {
    TrnTransitionMeetingDate: yup.string().test(
        'mustEnterAtLeastOneValue',
        'You must enter at least one find criteria.',
        function (value : any) {
            if (!this.parent.ScrReferralForScreeningDate && !this.parent.staffAssignedFullName && !value) {
                return false;
            }

            return true;
        }
    ),
    ScrReferralForScreeningDate : yup.string(),
    staffAssignedFullName : yup.string(),
    timelineIntervalReports : yup.string().required('You must select a report to run')
};

type Props = {
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
};

const TimelineReport = ({
    handleSetFormIsModified
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-1">
                <Col xs={12}>
                    <h3>Find Criteria</h3>
                </Col>
            </Row>
            <Row className="mx-0 px-2 condensed-form-row">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="TrnTransitionMeetingDate"
                        type="text"
                        label="Transition Meeting Date:"
                        disabled={false}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ScrReferralForScreeningDate"
                        type="text"
                        label="Referral For Screening Date:"
                        disabled={false}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="staffAssignedFullName"
                        type="text"
                        label="Staff Assigned:"
                        disabled={false}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} className="mt-3">
                    <h3>Report Selection</h3>
                </Col>
                <Col xs={4}>
                    <StandardField
                        name="timelineIntervalReports"
                        type="select"
                        label="Select Report:"
                        disabled={false}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[
                            {label: 'Intervals By County', value: 'Intervals By County', checked: false},
                            {label: 'Intervals By Size Alike', value: 'Intervals By Size Alike', checked: false},
                            {label: 'Intervals By Primary Exceptionality', value: 'Intervals By Primary Exceptionality', checked: false},
                            {label: 'Intervals Combined', value: 'Intervals Combined', checked: false},
                            {label: 'More Intervals By County (Summary Only)', value: 'More Intervals By County (Summary Only)', checked: false}
                        ]}
                    />
                </Col>
            </Row>
        </>
    );
};

export default TimelineReport;