import React, {useCallback, useState} from 'react';
import {Form, Formik, FormikValues} from "formik";
import {NameToValueMap} from "./FormBuilder";
import {Button, Col, Row} from "react-bootstrap";
import StandardField from "./Fields/StandardField";
import * as yup from 'yup';
import FormControls from "./Fields/FormControls";
import {faSearch, faBan, faFile, faIdCard, faCalendar, faPeopleArrows} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RecordLocatorProviderContext, SearchOptions, SearchResult} from "../Providers/RecordLocatorProvider";
import {apiEndpoint} from "../../utils/api";
import {useHistory} from "react-router-dom";
import {useApiFetch} from "../Providers/OidcProvider";
import * as H from "history";
import {ActiveTab} from "../../pages/RecordLocator";
import moment from "moment/moment";

type Props = {
    searchOptions : SearchOptions;
    handleClearSearchForm : () => void;
    canRead : boolean;
    showDBNum? : boolean;
};


const getResultIcon = (
    icon: any,
    child: SearchResult,
    activeTab: ActiveTab,
    handleSetActiveChild: (childUUID: string, activeTab: ActiveTab) => void,
    handleShowTransferRecordModal: (show: boolean, child: SearchResult | null) => void,
    history: H.History
) => {
    return <div className="d-flex flex-column align-items-center">{child.canRead
        ? <FontAwesomeIcon
            className="cursor-pointer"
            icon={icon}
            size={"lg"}
            onClick={async () => {
                history.push(`/record-locator?sub=${activeTab}`);
                await handleSetActiveChild(child.childUUID, activeTab);
            }}
        />
        : <FontAwesomeIcon
            className="cursor-pointer"
            icon={faPeopleArrows}
            size={"lg"}
            onClick={() => handleShowTransferRecordModal(true, child)}
        />}</div>;
};

const cosFormIcon = (
    icon: any,
    child: SearchResult,
    activeTab: ActiveTab,
    history: H.History,
    handleSetActiveChild: (childUUID: string, activeTab: ActiveTab) => void,
    setShowCosTooOldModal : (a : boolean) => void,
    setCosChildUUID: (a : string) => void,
    handelClearActiveChild: () => void,
) => {
    return <div className="d-flex flex-column align-items-center">{child.cosForm
        && <FontAwesomeIcon
            className="cursor-pointer"
            icon={icon}
            size={"lg"}
            onClick={async () => {
                const childBirth =  moment(child.birthDate).toDate()
                if (moment().subtract('6.5', 'year').toDate() > childBirth
                    ||  childBirth > moment().subtract('2.5', 'year').toDate()) {
                    setShowCosTooOldModal(true);
                    setCosChildUUID(child.childUUID)
                } else {
                    handelClearActiveChild();
                    history.push(`/cos-form?id=${child.childUUID}`);
                }
            }}
        />}
    </div>;
};

const mapRawSearchResult = (
    rawSearchResult: any,
    handleSetActiveChild: (childUUID: string, activeTab: ActiveTab) => void,
    handleShowTransferRecordModal: (show: boolean, child: SearchResult | null) => void,
    history: H.History,
    setShowCosTooOldModal : (a : boolean) => void,
    setCosChildUUID: (a : string) => void,
    handelClearActiveChild : () => void,
): SearchResult => ({
    ...rawSearchResult,
    cos: cosFormIcon(
        faFile,
        rawSearchResult,
        'forms',
        history,
        handleSetActiveChild,
        setShowCosTooOldModal,
        setCosChildUUID,
        handelClearActiveChild
    ),
    demographics: getResultIcon(
        faIdCard,
        rawSearchResult,
        'demographics',
        handleSetActiveChild,
        handleShowTransferRecordModal,
        history
    ),
    tracking: getResultIcon(
        faCalendar,
        rawSearchResult,
        'tracking',
        handleSetActiveChild,
        handleShowTransferRecordModal,
        history
    )
});

export const RecordLocatorSchema = yup.object({
    dbnum: yup.string().when([
        'lastName',
        'firstName',
        'birthDate',
        'Addresses::AdultFirstName',
        'Addresses::AdultLastName',
        'Addresses::AdultPermStreet',
        'Addresses::AdultStreet',
        'Addresses::AdultPhone1',
        'Addresses::AdultPhone2',
        'Addresses::AdultPhone3',
        'Addresses::AdultEmail',
        'DemoEntry::StaffAssignedFullName',
        'DemoEntry::HomeZonedSchool',
        'DemoEntry to ServiceCoordination by Appt Event::ApptDate',
        'StudentNumber'
    ], {
        is: (
            lastName,
            firstName,
            birthDate,
            adultFirstName,
            adultLastName,
            adultPermStreet,
            adultStreet,
            adultPhone1,
            adultPhone2,
            adultPhone3,
            adultEmail,
            staffAssignedFullName,
            homeZonedSchool,
            apptDate,
            studentNumber
        ) =>
            !lastName &&
            !firstName &&
            !birthDate &&
            !adultFirstName &&
            !adultLastName &&
            !adultPermStreet &&
            !adultStreet &&
            !adultPhone1 &&
            !adultPhone2 &&
            !adultPhone3 &&
            !adultEmail &&
            !staffAssignedFullName &&
            !homeZonedSchool &&
            !apptDate &&
            !studentNumber,
        then: yup.string().required('You must enter at least one search criteria')
    }),
    lastName: yup.string(),
    firstName: yup.string(),
    birthDate: yup.date(),
    birthDateEnd: yup.date(),
    'Addresses::AdultFirstName': yup.string(),
    'Addresses::AdultLastName' : yup.string(),
    'Addresses::AdultPermStreet': yup.string(),
    'Addresses::AdultStreet': yup.string(),
    'Addresses::AdultPhone1': yup.string(),
    'Addresses::AdultPhone2': yup.string(),
    'Addresses::AdultPhone3': yup.string(),
    'Addresses::AdultEmail': yup.string(),
    'DemoEntry::StaffAssignedFullName': yup.string(),
    'DemoEntry::HomeZonedSchool': yup.string(),
    'DemoEntry to ServiceCoordination by Appt Event::ApptDate': yup.string(),
    'StudentNumber': yup.string()
});

const RecordLocatorForm = ({
    handleClearSearchForm,
    canRead,
    showDBNum,
} : Props) => {
    const {searchOptions, setPossibleDuplicates,setShowGeneralErrorModal, handleShowTransferRecordModal,
        setShowCosTooOldModal, setCosChildUUID,handleSetActiveChild,
        setSearchOptions, setSearchResults, setTooManyResults, handelClearActiveChild} = React.useContext(RecordLocatorProviderContext);
    const apiFetch = useApiFetch();
    let history = useHistory();
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showAdvancedOptions, setShowAdvancedOptions] = useState<boolean>(false);

    const handleRecordLocatorSubmit = useCallback(async (values: FormikValues, canRead: boolean) => {
        setPossibleDuplicates(false);
        setSearchResults(null);
        setSearchOptions({
            dbnum: values.dbnum,
            lastName: values.lastName,
            firstName: values.firstName,
            birthDate: values.birthDate,
            birthDateEnd: values.birthDateEnd,
            'Addresses::AdultFirstName': values['Addresses::AdultFirstName'],
            'Addresses::AdultLastName': values['Addresses::AdultLastName'],
            'Addresses::AdultPermStreet': values['Addresses::AdultPermStreet'],
            'Addresses::AdultStreet': values['Addresses::AdultStreet'],
            'Addresses::AdultPhone1': values['Addresses::AdultPhone1'],
            'Addresses::AdultPhone2': values['Addresses::AdultPhone2'],
            'Addresses::AdultPhone3': values['Addresses::AdultPhone3'],
            'Addresses::AdultEmail': values['Addresses::AdultEmail'],
            'DemoEntry::StaffAssignedFullName': values['DemoEntry::StaffAssignedFullName'],
            'DemoEntry::HomeZonedSchool': values['DemoEntry::HomeZonedSchool'],
            'DemoEntry to ServiceCoordination by Appt Event::ApptDate':
                values['DemoEntry to ServiceCoordination by Appt Event::ApptDate'],
            'StudentNumber': values['StudentNumber']
        });
        setTooManyResults(false);

        const url = new URL(`/v1/record-locator/${canRead ? 1 : 0}`, apiEndpoint);

        const response = await apiFetch(url.toString(), {
            method: 'POST',
            body: JSON.stringify({
                dbnum: values.dbnum,
                lastName: values.lastName,
                firstName: values.firstName,
                birthDate: values.birthDate,
                birthDateEnd: values.birthDateEnd,
                'Addresses::AdultFirstName': values['Addresses::AdultFirstName'],
                'Addresses::AdultLastName': values['Addresses::AdultLastName'],
                'Addresses::AdultPermStreet': values['Addresses::AdultPermStreet'],
                'Addresses::AdultStreet': values['Addresses::AdultStreet'],
                'Addresses::AdultPhone1': values['Addresses::AdultPhone1'],
                'Addresses::AdultPhone2': values['Addresses::AdultPhone2'],
                'Addresses::AdultPhone3': values['Addresses::AdultPhone3'],
                'Addresses::AdultEmail': values['Addresses::AdultEmail'],
                'DemoEntry::StaffAssignedFullName': values['DemoEntry::StaffAssignedFullName'],
                'DemoEntry::HomeZonedSchool': values['DemoEntry::HomeZonedSchool'],
                'DemoEntry to ServiceCoordination by Appt Event::ApptDate':
                    values['DemoEntry to ServiceCoordination by Appt Event::ApptDate'],
                'StudentNumber': values['StudentNumber']
            })
        });

        if (response.status === 204) {
            setSearchResults([]);
            return;
        }

        if (response.status === 413) {
            setSearchResults([]);
            setTooManyResults(true);
            return;
        }

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const data = await response.json();

        if (data.possibleDuplicates) {
            setPossibleDuplicates(true);
        }

        const mappedData = data.results.map((data: any) => mapRawSearchResult(
            data,
            handleSetActiveChild,
            handleShowTransferRecordModal,
            history,
            setShowCosTooOldModal,
            setCosChildUUID,
            handelClearActiveChild
        ));
        setSearchResults(mappedData);
    }, [setSearchOptions, handleSetActiveChild, handleShowTransferRecordModal, setTooManyResults, apiFetch, history]);


    const handleSetFormIsModified = useCallback(async (formIsModified : boolean) => {
        await setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const handleSetShowAdvancedOptions = useCallback(async () => {
        setShowAdvancedOptions(!showAdvancedOptions);
    }, [setShowAdvancedOptions, showAdvancedOptions]);

    const initialValues : NameToValueMap = searchOptions;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RecordLocatorSchema}
            onSubmit={async (values, formikHelpers) => {
                setLoading(true);
                await handleRecordLocatorSubmit(values, canRead);
                await handleSetFormIsModified(false);
                setLoading(false);
            }}
            enableReinitialize={true}
        >
        {(props) => {
            return <Form>
                <Row className="mx-0">
                    <Col xs={12}>
                        <p>Enter your desired search criteria below, then click "Search".</p>
                    </Col>
                </Row>
                <Row className="chris-form-bg mx-0 py-3 px-2">
                    {showDBNum && (
                        <Col xs={12} lg={3}>
                            <StandardField
                                name="dbnum"
                                type="text"
                                label="DBNUM"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                                autoFocus={true}
                            />
                        </Col>
                    )}
                    <Col xs={12} lg={showDBNum ? 3 : 6}>
                        <StandardField
                            name="lastName"
                            type="text"
                            label="Last Name"
                            disabled={false}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={showDBNum ? 3 : 6}>
                        <StandardField
                            name="firstName"
                            type="text"
                            label="First Name"
                            disabled={false}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="chris-form-bg mx-0 pb-3 px-2">
                    <Col xs={12} lg={showDBNum ? 6 : 12} className="d-flex flex-column align-items-center">
                        <span>Birth Date Range</span>
                    </Col>
                </Row>
                <Row className="chris-form-bg mx-0 pb-3 px-2">
                    <Col xs={12} lg={showDBNum ? 3 : 6}>
                        <StandardField
                            name="birthDate"
                            type="date"
                            label="From"
                            disabled={false}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={showDBNum ? 3 : 6}>
                        <StandardField
                            name="birthDateEnd"
                            type="date"
                            label="To"
                            disabled={false}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="chris-form-bg mx-0 pb-3 px-0">
                    <Col xs={12}>
                        <div
                            className="btn btn-link"
                            onClick={handleSetShowAdvancedOptions}
                        >{showAdvancedOptions ? '-' : '+'} Advanced Search Options</div>
                    </Col>
                </Row>
                {showAdvancedOptions && (
                    <Row className="chris-form-bg mx-0 pb-3 px-2">
                        <Col xs={12} lg={3}>
                            <StandardField
                                name="Addresses::AdultFirstName"
                                type="text"
                                label="SA First Name"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name="Addresses::AdultLastName"
                                type="text"
                                label="SA Last Name"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name="Addresses::AdultPermStreet"
                                type="text"
                                label="SA Physical Address"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name="Addresses::AdultStreet"
                                type="text"
                                label="SA Mailing Address"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name="Addresses::AdultPhone1"
                                type="text"
                                label="SA Phone 1"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name="Addresses::AdultPhone2"
                                type="text"
                                label="SA Phone 2"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name="Addresses::AdultPhone3"
                                type="text"
                                label="SA Phone 3"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name="Addresses::AdultEmail"
                                type="text"
                                label="SA Email"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name="DemoEntry::StaffAssignedFullName"
                                type="text"
                                label="Staff Assigned"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name="DemoEntry::HomeZonedSchool"
                                type="text"
                                label="Home School Zone"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name="DemoEntry to ServiceCoordination by Appt Event::ApptDate"
                                type="text"
                                label="Appointment Date"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name="StudentNumber"
                                type="text"
                                label="Student Number"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col xs={12}>
                        <hr />
                    </Col>
                </Row>
                <Row className="mx-0">
                    <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                        <Button
                            className="d-flex pt-2 flex-row align-content-center mr-2"
                            variant="outline-secondary"
                            onClick={() => {
                                props.handleReset();
                                handleClearSearchForm();
                            }}
                            disabled={loading}
                        ><FontAwesomeIcon icon={faBan} size="sm" className="mt-1"/>
                            &nbsp; Clear</Button>
                        <FormControls
                            formIsModified={formIsModified}
                            loading={loading}
                            submitForm={props.submitForm}
                            showSave={true}
                            submitType={'save'}
                            saveText={'Search'}
                            saveTextAction={'Searching'}
                            saveTextPast={'Search'}
                            saveIcon={faSearch}
                            disableAutoSave={true}
                            isValid={props.isValid}
                        />
                    </Col>
                </Row>
            </Form>
        }}
        </Formik>
    );
};

export default RecordLocatorForm;
