import React, {useState} from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField, {StandardFieldOption} from "../Fields/StandardField";
import * as yup from 'yup';
import {getOptionsForField} from "../../../utils/formHelpers";
import {List} from "../../../pages/RecordLocator";

export const STANDARD_REPORTS_STRING = 'StandardReports';
export type STANDARD_REPORTS = typeof STANDARD_REPORTS_STRING;

export const StandardReportsValues = {
    'Report::Sort' : '',
    'Report::ReportTableName1' : '',
    'Report::ReportEvent1' : '',
    'Report::ReportFieldName1' : '',
    'Report::ReportFieldValue1' : '',
    'Report::ReportOperand1' : '',
    'Report::ReportTableName2' : '',
    'Report::ReportEvent2' : '',
    'Report::ReportFieldName2' : '',
    'Report::ReportFieldValue2' : '',
    'Report::ReportOperand2' : '',
    'Report::ReportTableName3' : '',
    'Report::ReportEvent3' : '',
    'Report::ReportFieldName3' : '',
    'Report::ReportFieldValue3' : '',
    'Report::ReportOperand3' : '',
    'Report::ReportTableName4' : '',
    'Report::ReportEvent4' : '',
    'Report::ReportFieldName4' : '',
    'Report::ReportFieldValue4' : '',
    'Report::ReportOperand4' : '',
};

export const StandardReportsSchema = {
    'Report::ReportTableName1' : yup.string().required('You must select a table above'),
    'Report::ReportFieldName1' : yup.string().required('You must select an field above'),
    'Report::ReportFieldValue1' : yup.string().required('You must enter a value above'),
    'Report::ReportEvent1' : yup.string().test(
        'requiredWhen',
        'You must select an event above',
        function (value : any) {
            if (['Timeline', 'ServiceCoordination'].includes(this.parent['Report::ReportTableName1']) && !value) {
                return false;
            }

            return true;
        }
    )
};

export const sortOptions : Array<StandardFieldOption> = [
    {label: 'ActionNeeded', value: 'ActionNeeded', checked: false},
    {label: 'ActionNeededFUDate', value: 'ActionNeededFUDate', checked: false},
    {label: 'ApptDate_Name', value: 'ApptDate_Name', checked: false},
    {label: 'ApptDate_Time', value: 'ApptDate_Time', checked: false},
    {label: 'Code', value: 'Code', checked: false},
    {label: 'COR', value: 'COR', checked: false},
    {label: 'COR_Age', value: 'COR_Age', checked: false},
    {label: 'COR_Age_SCStatus', value: 'COR_Age_SCStatus', checked: false},
    {label: 'COR_DOB_Name', value: 'COR_DOB_Name', checked: false},
    {label: 'COR_ScrFinalResult', value: 'COR_ScrFinalResult', checked: false},
    {label: 'DOB_Name', value: 'DOB_Name', checked: false},
    {label: 'EducationEnvironment', value: 'EducationEnvironment', checked: false},
    {label: 'EvaluationDiagnosticians', value: 'EvaluationDiagnosticians', checked: false},
    {label: 'ExceptionalityStatus', value: 'ExceptionalityStatus', checked: false},
    {label: 'LanguageProficiency', value: 'LanguageProficiency', checked: false},
    {label: 'Location', value: 'Location', checked: false},
    {label: 'Child_Name', value: 'Child_Name', checked: false},
    {label: 'Name_ApptDate', value: 'Name_ApptDate', checked: false},
    {label: 'Name_Staff_ANFollowUpDate', value: 'Name_Staff_ANFollowUpDate', checked: false},
    {label: 'PlanType', value: 'PlanType', checked: false},
    {label: 'PrimaryExceptionality', value: 'PrimaryExceptionality', checked: false},
    {label: 'ReferralAgency', value: 'ReferralAgency', checked: false},
    {label: 'ReferralInactiveReason', value: 'ReferralInactiveReason', checked: false},
    {label: 'ServCoordStatus', value: 'ServCoordStatus', checked: false},
    {label: 'Speech_Language_Diagnostician', value: 'Speech_Language_Diagnostician', checked: false},
    {label: 'StaffAssigned_ANFollowUpDate', value: 'StaffAssigned_ANFollowUpDate', checked: false},
    {label: 'StaffAssigned', value: 'StaffAssigned', checked: false},
    {label: 'SubLocation', value: 'SubLocation', checked: false},
    {label: 'TimelineStatus', value: 'TimelineStatus', checked: false},
    {label: 'TimelineStatus_Age', value: 'TimelineStatus_Age', checked: false},
    {label: 'AdultZip', value: 'AdultZip', checked: false},
];

type DisabledFieldEvent = {
   iteration: number;
   disabled: boolean;
};

type FieldEvent = {
    iteration: number;
    options: Array<StandardFieldOption>;
};

type Props = {
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
    siteLists: Array<List>;
    reportName: string;
    contextTable?: string;
};

const StandardReports = ({
    handleSetFormIsModified,
    siteLists,
    reportName,
    contextTable
} : Props) => {
    const allEventOptions = getOptionsForField('AllEventNameList', '', siteLists);
    const allFieldOptions = getOptionsForField('AllTableFieldsDropDown', '', siteLists);

    const [events, setEvents] = useState<Array<FieldEvent>>([
        {iteration: 1, options: allEventOptions},
        {iteration: 2, options: allEventOptions},
        {iteration: 3, options: allEventOptions},
        {iteration: 4, options: allEventOptions},
    ]);
    const [fields, setFields] = useState<Array<FieldEvent>>([
        {iteration: 1, options: allFieldOptions},
        {iteration: 2, options: allFieldOptions},
        {iteration: 3, options: allFieldOptions},
        {iteration: 4, options: allFieldOptions},
    ]);
    const [eventDisabled, setEventDisabled] = useState<Array<DisabledFieldEvent>>([
        {iteration: 1, disabled: true},
        {iteration: 2, disabled: true},
        {iteration: 3, disabled: true},
        {iteration: 4, disabled: true}
    ]);
    const [fieldDisabled, setFieldDisabled] = useState<Array<DisabledFieldEvent>>([
        {iteration: 1, disabled: true},
        {iteration: 2, disabled: true},
        {iteration: 3, disabled: true},
        {iteration: 4, disabled: true}
    ]);

    const handleEventChange = (e : any, iteration: number) => {
        const fieldDisabledIndex = fieldDisabled.findIndex(
            (disabledField) => disabledField.iteration === iteration
        );
        const fieldsIndex = fields.findIndex(
            (fieldEvent) => fieldEvent.iteration === iteration
        );

        if (e.currentTarget.value !== '') {
            fieldDisabled[fieldDisabledIndex].disabled = false;
        } else {
            fieldDisabled[fieldDisabledIndex].disabled = true;
        }

        setFieldDisabled(fieldDisabled);

        if (e.currentTarget.value === 'EndTimeline') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'EndAgeAtEvent',
                    'EndDate',
                    'EndNotes',
                    'EndReason',
                    'TotalDays'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'ESEEligibility') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'ESEAgeAtEvent',
                    'ESEAutistic',
                    'ESEDeaforHardofHearing',
                    'ESEDevelopmentallyDelayed',
                    'ESEDualSensoryImpaired',
                    'ESEEligibilityDate',
                    'ESEEmotionalBehavioralDisability',
                    'ESEEstablishedConditions',
                    'ESEHospitalHomebound',
                    'ESEIntellectualDisability',
                    'ESELanguageImpaired',
                    'ESELanguageTherapy',
                    'ESENotes',
                    'ESEOccupationalTherapy',
                    'ESEOrthopedicallyImpaired',
                    'ESEOtherHealthImpaired',
                    'ESEPhysicalTherapy',
                    'ESEPrimaryExceptionality',
                    'ESESpecificLearningDisabled',
                    'ESESpeechImpaired',
                    'ESESpeechTherapy',
                    'ESETraumaticBrianInjured',
                    'ESEVisuallyImpaired',
                    'TotalDays'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'IEPIFSP') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'IEPIFSPAgeAtEvent',
                    'IEPIFSPDate',
                    'IEPIFSPEducationalEnvironment1',
                    'IEPIFSPEducationalEnvironment2',
                    'IEPIFSPNotes',
                    'IEPIFSPParentConsentDate',
                    'IEPIFSPServiceInitiatedDate1',
                    'IEPIFSPServiceInitiatedDate2',
                    'IEPIFSPServiceLocation1',
                    'IEPIFSPServiceLocation2',
                    'IEPIFSPServiceType1',
                    'IEPIFSPServiceType2',
                    'TotalDays'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'ReferralForEvaluation') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'EvlAdaptiveCompleted',
                    'EvlAdaptiveDiag',
                    'EvlAdaptiveRecordReview',
                    'EvlAdaptiveRR',
                    'EvlAgeAtEvent',
                    'EvlAudiologCompleted',
                    'EvlAudiologDiag',
                    'EvlAudiologRecordReview',
                    'EvlAudiologRR',
                    'EvlBehObsrvCompleted',
                    'EvlBehObsrvDiag',
                    'EvlBehObsrvRecordReview',
                    'EvlBehObsrvRR',
                    'EvlCommunCompleted',
                    'EvlCommunDiag',
                    'EvlCommunRecordReview',
                    'EvlCommunRR',
                    'EvlDevelopmentalCompleted',
                    'EvlDevelopmentalDiag',
                    'EvlDevelopmentalRecordReview',
                    'EvlDevelopmentalRR',
                    'EvlEducationCompleted',
                    'EvlEducationDiag',
                    'EvlEducationRecordReview',
                    'EvlEducationRR',
                    'EvlEmBhvrCompleted',
                    'EvlEmBhvrDiag',
                    'EvlEmBhvrRecordReview',
                    'EvlEmBhvrRR',
                    'EvlFinalEvalDate',
                    'EvlFinalEvalResult',
                    'EvlFuncVsnCompleted',
                    'EvlFuncVsnDiag',
                    'EvlFuncVsnRecordReview',
                    'EvlFuncVsnRR',
                    'EvlIntellectCompleted',
                    'EvlIntellectDiag',
                    'EvlIntellectRecordReview',
                    'EvlIntellectRR',
                    'EvlLanguageCompleted',
                    'EvlLanguageDiag',
                    'EvlLanguageRecordReview',
                    'EvlLanguageRR',
                    'EvlMedicalCompleted',
                    'EvlMedicalDiag',
                    'EvlMedicalRecordReview',
                    'EvlMedicalRR',
                    'EvlMotorCompleted',
                    'EvlMotorDiag',
                    'EvlMotorRecordReview',
                    'EvlMotorRR',
                    'EvlNotes',
                    'EvlParentConsentForEvan',
                    'EvlPresentingProblem1',
                    'EvlPresentingProblem2',
                    'EvlPresentingProblem3',
                    'EvlRecordReview',
                    'EvlRecReviewCompleted',
                    'EvlRecReviewDiag',
                    'EvlRecReviewRR',
                    'EvlReferralForEvaluationDate',
                    'EvlSocDevHCompleted',
                    'EvlSocDevHDiag',
                    'EvlSocDevHRecordReview',
                    'EvlSocDevHRR',
                    'EvlSpeechCompleted',
                    'EvlSpeechDiag',
                    'EvlSpeechRecordReview',
                    'EvlSpeechRR',
                    'EvlVisionCompleted',
                    'EvlVisionDiag',
                    'EvlVisionRecordReview',
                    'EvlVisionRR',
                    'TotalDays'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'ReferralForScreening') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'ScrAgeAtEvent',
                    'ScrBehaviorDate',
                    'ScrBehaviorLocation',
                    'ScrBehaviorPF',
                    'ScrBehaviorPrvd',
                    'ScrCognitionDate',
                    'ScrCognitionLocation',
                    'ScrCognitionPF',
                    'ScrCognitionPrvd',
                    'ScrDevelopDate',
                    'ScrDevelopLocation',
                    'ScrDevelopPF',
                    'ScrDevelopPrvd',
                    'ScrFinalScreeningResult',
                    'ScrFinalScreeningResultDate',
                    'ScrHearingDate',
                    'ScrHearingLocation',
                    'ScrHearingPF',
                    'ScrHearingPrvd',
                    'ScrInstrumentsUsed',
                    'ScrLanguageDate',
                    'ScrLanguageLocation',
                    'ScrLanguagePF',
                    'ScrLanguagePrvd',
                    'ScrMotorDate',
                    'ScrMotorLocation',
                    'ScrMotorPF',
                    'ScrMotorPrvd',
                    'ScrNotes',
                    'ScrObsrvDate',
                    'ScrObsrvLocation',
                    'ScrObsrvPF',
                    'ScrObsrvPrvd',
                    'ScrParentCnfDate',
                    'ScrParentCnfLocation',
                    'ScrParentCnfPF',
                    'ScrParentCnfPrvd',
                    'ScrParentRprtDate',
                    'ScrParentRprtLocation',
                    'ScrParentRprtPF',
                    'ScrParentRprtPrvd',
                    'ScrPresentingProblem1',
                    'ScrPresentingProblem2',
                    'ScrPresentingProblem3',
                    'ScrProfRprtDate',
                    'ScrProfRprtLocation',
                    'ScrProfRprtPF',
                    'ScrProfRprtPrvd',
                    'ScrRcrdRevDate',
                    'ScrRcrdRevLocation',
                    'ScrRcrdRevPF',
                    'ScrRcrdRevPrvd',
                    'ScrReferralForScreeningDate',
                    'ScrSpeechDate',
                    'ScrSpeechLocation',
                    'ScrSpeechPF',
                    'ScrSpeechPrvd',
                    'ScrVisionDate',
                    'ScrVisionLocation',
                    'ScrVisionPF',
                    'ScrVisionPrvd',
                    'TotalDays'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'Transition') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'TotalDays',
                    'TrnAgeAtPartCtoPartB',
                    'TrnAgeAtTrnConf',
                    'TrnConsentForReferralDate',
                    'TrnConsentRecordTransferDate',
                    'TrnLEANotAtConference',
                    'TrnLEAParticipationMethod',
                    'TrnNote',
                    'TrnNotes',
                    'TrnParentNotAtConference',
                    'TrnParentParticipationMethod',
                    'TrnPartCProvider',
                    'TrnPartCServCoord',
                    'TrnPartCtoPartBReferralDate',
                    'TrnRefPacketReceivedDate',
                    'TrnTransitionMeetingDate'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'Appointments') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'AgeByEventDate',
                    'ApptAddress',
                    'ApptConfirmed',
                    'ApptDate',
                    'ApptDirections',
                    'ApptEndTime',
                    'ApptLocation',
                    'ApptProvider1',
                    'ApptProvider2',
                    'ApptProvider3',
                    'ApptProvider4',
                    'ApptProvider5',
                    'ApptStatus',
                    'ApptTime',
                    'ApptTransport',
                    'ApptType1',
                    'ApptType2',
                    'ApptType3',
                    'ApptType4',
                    'ApptType5',
                    'EventNotes'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'ConLog') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'AgeByEventDate',
                    'ConLogContactMadeBy',
                    'ConLogContactMadeTo',
                    'ConLogContactNotes',
                    'ConLogDateOfContact',
                    'ConLogFromTo',
                    'ConLogMethodOfContact',
                    'ConLogReasonForContact',
                    'EventNotes'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'FolderToESE') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'AgeByEventDate',
                    'EventNotes',
                    'FolderToESEComments',
                    'FolderToESEDate'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'OnHold') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'AgeByEventDate',
                    'EventNotes',
                    'OnHoldComments',
                    'OnHoldDate',
                    'OnHoldReason'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'PacketReturned') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'AgeByEventDate',
                    'EventNotes',
                    'PacketReturnedComments',
                    'PacketReturnedDate'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'PacketSent') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'AgeByEventDate',
                    'EventNotes',
                    'PacketSentComments',
                    'PacketSentDate'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'PeriodicFollowUp') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'AgeByEventDate',
                    'EventNotes',
                    'PeriodicFollowUpComments',
                    'PeriodicFollowUpDate'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'ReferralClosed') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'AgeByEventDate',
                    'EventNotes',
                    'ReferralClosedDate',
                    'ReferralClosedReason'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'ReferralFirstContact') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'AgeByEventDate',
                    'EventNotes',
                    'ReferralFirstContactAwareness',
                    'ReferralFirstContactDate',
                    'ReferralFirstContactReason',
                    'ReferralFirstContactRefBy',
                    'ReferralFirstContactSource'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'ReferralIn') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'AgeByEventDate',
                    'EventNotes',
                    'ReferralInAwareness',
                    'ReferralInDate',
                    'ReferralInReason',
                    'ReferralInRefBy',
                    'ReferralInSource'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'ReferralInactive') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'AgeByEventDate',
                    'EventNotes',
                    'ReferralInactiveDate',
                    'ReferralInactiveReason'
                ].includes(standardFieldOption.value)
            );
        }

        if (e.currentTarget.value === 'ReferralOut') {
            fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                [
                    'AgeByEventDate',
                    'EventNotes',
                    'ReferralOutDate',
                    'ReferralOutReason',
                    'ReferralOutRefTo',
                    'ReferralOutResponsibleFullName'
                ].includes(standardFieldOption.value)
            );
        }

        setFields(fields);
    };

    const handleTableChange = (e : any, iteration: number) => {
        const fieldDisabledIndex = fieldDisabled.findIndex(
            (disabledField) => disabledField.iteration === iteration
        );
        const eventDisabledIndex = eventDisabled.findIndex(
            (disabledField) => disabledField.iteration === iteration
        );
        const fieldsIndex = fields.findIndex(
            (fieldEvent) => fieldEvent.iteration === iteration
        );
        const eventsIndex = events.findIndex(
            (fieldEvent) => fieldEvent.iteration === iteration
        );

        if (['Demo', 'ActionNeeded', 'Addresses'].includes(e.currentTarget.value)) {
            fieldDisabled[fieldDisabledIndex].disabled = false;
            eventDisabled[eventDisabledIndex].disabled = true;
            setEventDisabled(eventDisabled);
            setFieldDisabled(fieldDisabled);

            if (e.currentTarget.value === 'Demo') {
                fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                    [
                        'Age',
                        'AgenciesKnown1',
                        'AgenciesKnown2',
                        'AgenciesKnown3',
                        'AlternateSurname',
                        'BirthCounty',
                        'BirthPlace',
                        'BirthStateCounty',
                        'CenterID',
                        'ChildID',
                        'Code',
                        'CountyOfResidence',
                        'CriticalNotes',
                        'Dbnum',
                        'DOB',
                        'Ethnicity',
                        'ExceptionalityStatus',
                        'FDLRSStaff',
                        'FirstName',
                        'HereID',
                        'HomeZonedSchool',
                        'InterAgencyEndDate',
                        'InterAgencyRelease',
                        'InterAgencyStartDate',
                        'ISCorSCFullName',
                        'LastName',
                        'Location',
                        'MedicaidNumber',
                        'MiddleName',
                        'Migrant',
                        'Nickname',
                        'OtherAdultInfo',
                        'OtherLanguage',
                        'PrimaryLanguage',
                        'ProvidersKnown1',
                        'ProvidersKnown2',
                        'ProvidersKnown3',
                        'Race',
                        'RecordCreator',
                        'ReferralFirstContactDate',
                        'Relation',
                        'ServiceCoordinationStatus',
                        'ServiceCounty',
                        'Sex',
                        'SSN',
                        'StaffAssignedFullName',
                        'StudentNumber',
                        'SubLocation',
                        'Suffix',
                        'SY5',
                        'ThirdBDay',
                        'TimelineStatus',
                        'COSFormEntryCOSDate',
                        'COSFormExitCOSDate',
                    ].includes(standardFieldOption.value)
                );
            }

            if (e.currentTarget.value === 'ActionNeeded') {
                fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                    [
                       'ActionNeeded',
                       'ANCompletedDate',
                       'ANFollowUpDate'
                    ].includes(standardFieldOption.value)
                );
            }

            if (e.currentTarget.value === 'Addresses') {
                fields[fieldsIndex].options = allFieldOptions.filter((standardFieldOption) =>
                    [
                        'AdultCity',
                        'AdultCourtesyTitle',
                        'AdultEmail',
                        'AdultFirstName',
                        'AdultLastName',
                        'AdultLivesWith',
                        'AdultMail',
                        'AdultNotes1',
                        'AdultNotes2',
                        'AdultNotes3',
                        'AdultPermCity',
                        'AdultPermState',
                        'AdultPermStreet',
                        'AdultPermZip',
                        'AdultPhone1',
                        'AdultPhone2',
                        'AdultPhone3',
                        'AdultRelation',
                        'AdultState',
                        'AdultStreet',
                        'AdultZip'
                    ].includes(standardFieldOption.value)
                );
            }

            setFields(fields);

        } else {
            fieldDisabled[fieldDisabledIndex].disabled = true;
            eventDisabled[eventDisabledIndex].disabled = false;
            setEventDisabled(eventDisabled);
            setFieldDisabled(fieldDisabled);

            if (e.currentTarget.value === 'Timeline') {
                events[eventsIndex].options = allEventOptions.filter((standardFieldOption) =>
                    [
                        'EndTimeline',
                        'ESEEligibility',
                        'IEPIFSP',
                        'ReferralForEvaluation',
                        'ReferralForScreening',
                        'Transition'
                    ].includes(standardFieldOption.value)
                );
            }

            if (e.currentTarget.value === 'ServiceCoordination') {
                events[eventsIndex].options = allEventOptions.filter((standardFieldOption) =>
                    [
                        'Appointments',
                        'ConLog',
                        'FolderToESE',
                        'OnHold',
                        'PacketReturned',
                        'PacketSent',
                        'PeriodicFollowUp',
                        'ReferralClosed',
                        'ReferralFirstContact',
                        'ReferralIn',
                        'ReferralInactive',
                        'ReferralOut'
                    ].includes(standardFieldOption.value)
                );
            }

            setEvents(events);
        }
    };

    const tableOptions : Array<StandardFieldOption> = [
        {label: 'Demo', value: 'Demo', checked: false},
        {label: 'Timeline', value: 'Timeline', checked: false},
        {label: 'Service Coordination', value: 'ServiceCoordination', checked: false},
        {label: 'Action Needed', value: 'ActionNeeded', checked: false},
        {label: 'Addresses', value: 'Addresses', checked: false}
    ];

    let limitedTableOptions = tableOptions;

    switch (reportName) {
        case 'Address Labels':
        case 'Children\'s List':
        case 'Code Summary':
        case 'Early Steps Referral Follow-up Report':
        case 'End Timeline/Inactive Reason Report':
        case 'Referral Report (First Contact & In)':
        case 'Screening Labels':
        case 'Timeline Status Summary Data (Brief)(Year)':
        case 'Timeline Status Summary Data (Brief)(Yr&Mnth)':
        case 'Tracking Status of Referral for Screening':
            limitedTableOptions = limitedTableOptions.filter((tableOption) => tableOption.value === 'Demo');
            break;
        case 'Appointment Schedule':
        case 'Event Count By Age Range (Year)':
        case 'Event Count By Age Range (Year/Month)':
        case 'Screening Appointment Report':
        case 'Screening Results Report':
        case 'Screening Schedule Report':
            limitedTableOptions = limitedTableOptions.filter(
                (tableOption) => tableOption.value === 'ServiceCoordination'
            );
            break;
        case '5 Y/O Active Report':
        case 'ESE Staffing Report':
        case 'IEP/IFSP Report':
        case 'Inactive Report':
        case '% Increase of Transitions(Indicator 12)':
        case 'Master Timeline Reason/Close/Total Report':
        case 'Presenting Problem Report':
        case 'Speech/Language Evaluation Report':
        case 'Timeline Status Summary Data (Detailed)':
        case 'Timelines Report':
        case 'Verification Activity for Indicator 12':
            limitedTableOptions = limitedTableOptions.filter(
                (tableOption) => tableOption.value === 'Timeline'
            );
            break;
    }

    if (contextTable !== "") {
        limitedTableOptions = limitedTableOptions.filter((tableOption) => tableOption.value === contextTable);
    }

    const omitOptions : Array<StandardFieldOption> = [
        {label: 'Or', value: 'Or', checked: false},
        {label: 'Omit', value: 'Omit', checked: false},
    ];

    const findIteration = [1, 2, 3, 4];

    return (
        <>
            <Row className="mx-0 px-1">
                <Col xs={12}>
                    <h3>Find Criteria</h3>
                </Col>
            </Row>
            <Row className="mx-0 px-2 condensed-form-row">
                <Col xs={12} lg={3} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>Table</strong>
                </Col>
                <Col xs={12} lg={3} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>Event</strong>
                </Col>
                <Col xs={12} lg={3} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>Field</strong>
                </Col>
                <Col xs={12} lg={3} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>Value</strong>
                </Col>

                {findIteration.map((iteration) => (
                    <React.Fragment key={iteration}>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name={`Report::ReportTableName${iteration}`}
                                type="select"
                                label={`Table ${iteration}:`}
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                                onChange={(e) => handleTableChange(e, iteration)}
                                options={iteration === 1 ? limitedTableOptions : tableOptions}
                                fieldLabelClass={"d-lg-none"}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name={`Report::ReportEvent${iteration}`}
                                type="select"
                                label={`Event ${iteration}:`}
                                onChange={(e) => handleEventChange(e, iteration)}
                                disabled={eventDisabled.find(
                                    (event) => event.iteration === iteration
                                )?.disabled}
                                handleSetFormIsModified={handleSetFormIsModified}
                                options={events.find(
                                    (event) => event.iteration === iteration
                                )?.options}
                                fieldLabelClass={"d-lg-none"}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name={`Report::ReportFieldName${iteration}`}
                                type="select"
                                label={`Field ${iteration}:`}
                                disabled={fieldDisabled.find(
                                    (field) => field.iteration === iteration
                                )?.disabled}
                                handleSetFormIsModified={handleSetFormIsModified}
                                options={fields.find(
                                    (field) => field.iteration === iteration
                                )?.options}
                                fieldLabelClass={"d-lg-none"}
                            />
                        </Col>
                        <Col xs={12} lg={3}>
                            <StandardField
                                name={`Report::ReportFieldValue${iteration}`}
                                type="text"
                                label={`Value ${iteration}:`}
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                                fieldLabelClass={"d-lg-none"}
                            />
                        </Col>
                        {iteration > 1 && (
                            <Col xs={12} className="mb-3">
                                <StandardField
                                    name={`Report::ReportOperand${iteration}`}
                                    type="radio"
                                    label={`Omit ${iteration}:`}
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                    options={omitOptions}
                                    fieldLabelClass={"d-lg-none"}
                                />
                            </Col>
                        )}
                    </React.Fragment>
                ))}
            </Row>
            <Row className="mx-0 px-1">
                <Col xs={12}>
                    <h3>Sort Criteria</h3>
                </Col>
                <Col xs={12}>
                    <StandardField
                        name={`Report::Sort`}
                        type="select"
                        label={`Sort By:`}
                        disabled={false}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ReportsAvailableSorts',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
            </Row>
        </>
    );
};

export default StandardReports;
