import React, {useCallback, useState} from 'react';
import ModalBase from './ModalBase';
import {Form, Formik, FormikValues, isString} from "formik";
import {Button, Col, Row} from "react-bootstrap";
import StandardField from "../Forms/Fields/StandardField";
import * as yup from "yup";
import FormControls from "../Forms/Fields/FormControls";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faPlus} from "@fortawesome/free-solid-svg-icons";
import {List} from "../../pages/RecordLocator";
import {getOptionsForField} from "../../utils/formHelpers";
import CircularLoadingIndicator from "../CircularLoadingIndicator";
import moment from "moment";
import {ChrisUser} from "../Providers/ChrisUserProvider";
import {SearchOptions} from "../Providers/RecordLocatorProvider";

type Props = {
    show : boolean;
    handleSubmitAddChild : (values : FormikValues) => Promise<void>;
    handleClose : () => void;
    searchOptions: SearchOptions;
    siteLists : List[];
    chrisUser : ChrisUser;
    setLoadingAddChild : (loadingActiveChild : boolean) => void;
    loadingAddChild : boolean;
};

const buildCountyOptions = (chrisUser : ChrisUser, siteLists : List[]) => {
    if (!chrisUser.isCosUser) {
        return chrisUser.childSiteUUID ? chrisUser.childSiteUUID.map((option) => ({
            label: option.label,
            value: option.label,
            checked: option.checked
        })) : getOptionsForField(
            'ResidenceServiceCountyList',
            '',
            siteLists
        )
    }

    return [
        {
            label: "Indicator 7",
            value: "Indicator 7",
            checked: false
        },
        {
            label: "FSDB",
            value: "FSDB",
            checked: false
        },
        {
            label: "UCP",
            value: "UCP",
            checked: false
        },
    ]
}

const AddChildModal = ({
    show,
    handleSubmitAddChild,
    handleClose,
    searchOptions,
    siteLists,
    chrisUser,
    setLoadingAddChild,
    loadingAddChild
} : Props) => {
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const handleSetFormIsModified = useCallback(async (formIsModified : boolean) => {
        await setFormIsModified(formIsModified);
    }, [setFormIsModified]);
    const [hasValidRFCD, setValidRFCD] = useState<boolean>(true);
    const countyOptions = buildCountyOptions(chrisUser, siteLists);

    const handleCheckReferralFirstContactDate = useCallback(async (date: string) => {

        if (date === '') {
            setValidRFCD(true);
            return;
        }

        if (date !== ''
            && moment(new Date(date)) <= moment(moment.now()).add('1', 'year')
            && moment(new Date(date)) >= moment(moment.now()).subtract('1', 'year')) {
            setValidRFCD(true);
        } else {
            setValidRFCD(false);
        }

    }, [setValidRFCD]);

    const schema = yup.object({
        LastName: yup.string().required('You enter a Last Name.'),
        FirstName: yup.string().required('You enter a First Name.'),
        MiddleName: yup.string(),
        DOB : yup.date().required().min(
            moment().subtract('7', 'year').toDate(), 'Please check the birthdate. The child is over the age of seven.'
        ).max(
            moment(moment.now()).toDate(), 'Please check the birthdate. The date is in the future.'
        ),
        Sex: yup.string().required('You enter a Sex for the child.'),
        SSN: yup.string(),
        CountyOfResidence: yup.string().required('You enter a Residence County.'),
        ReferralFirstContactDate: yup.string().required('You enter a Referral First Contact Date.'),
        ReferralFirstContactReason: yup.string(),
        childSiteUUID: chrisUser.childSiteUUID ? yup.string().required() : yup.string()
    });

    let sexOptions = [
        {label: 'M', value: 'M', checked: false},
        {label: 'F', value: 'F', checked: false}
    ];

    if (!chrisUser.isCosUser) {
        sexOptions = getOptionsForField(
            'Sex',
            '',
            siteLists
        );
    }

    return (
        <ModalBase
            show={show}
            title="Add New Child"
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleClose}
        >
            {(siteLists.length > 0 || chrisUser.isCosUser) ? (
            <Formik
                initialValues={{
                    FirstName : searchOptions.firstName,
                    MiddleName : '',
                    LastName : searchOptions.lastName,
                    DOB : searchOptions.birthDate,
                    Sex: '',
                    SSN: '',
                    CountyOfResidence: '',
                    ReferralFirstContactDate: '',
                    ReferralFirstContactReason: '',
                    childSiteUUID: '',
                }}
                validationSchema={schema}
                enableReinitialize={true}
                onSubmit={async (values, formHelpers) => {
                    setLoadingAddChild(true);
                    await handleSubmitAddChild(values);
                }}
            >
                {(props) => {
                    return <Form>
                        <Row className="chris-form-bg mx-0 py-3 px-2">
                            <Col xs={12} lg={4}>
                                <StandardField
                                    name="LastName"
                                    type="text"
                                    label="Last Name:"
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                />
                            </Col>
                            <Col xs={12} lg={4}>
                                <StandardField
                                    name="FirstName"
                                    type="text"
                                    label="First Name:"
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                />
                            </Col>
                            <Col xs={12} lg={4}>
                                <StandardField
                                    name="MiddleName"
                                    type="text"
                                    label="Middle Name:"
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                />
                            </Col>
                            <Col xs={12} lg={4}>
                                <StandardField
                                    name="DOB"
                                    type="date"
                                    label="Date of Birth:"
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                />
                            </Col>
                            <Col xs={12} lg={4}>
                                <StandardField
                                    name="Sex"
                                    type="select"
                                    label="Sex:"
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                    options={sexOptions}
                                />
                            </Col>
                            <Col xs={12} lg={4}>
                                <StandardField
                                    name="SSN"
                                    type="text"
                                    label="Social Security Number:"
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                />
                            </Col>
                            <Col xs={12} lg={4}>
                                <StandardField
                                    name="CountyOfResidence"
                                    type="select"
                                    label="Residence County:"
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                    options={countyOptions}
                                />
                            </Col>
                            <Col xs={12} lg={4}>
                                <StandardField
                                    name="ReferralFirstContactDate"
                                    type="date"
                                    label="Referral First Contact Date:"
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                    errorNoticeOverride={!hasValidRFCD
                                        ? 'The date you have entered is not within a year of today. Are you sure it is correct?' : ''
                                    }
                                    onChange={async (value) => {
                                        await handleCheckReferralFirstContactDate(isString(value) ? value : '');
                                    }}
                                />
                            </Col>
                            <Col xs={12} lg={12}>
                                <StandardField
                                    name="ReferralFirstContactReason"
                                    type="text"
                                    label="Referral Reason:"
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                />
                            </Col>
                            {chrisUser.childSiteUUID && (
                                <Col xs={12} lg={4}>
                                    <StandardField
                                        name="childSiteUUID"
                                        type="select"
                                        label="FDLRS Site:"
                                        disabled={false}
                                        handleSetFormIsModified={handleSetFormIsModified}
                                        options={chrisUser.childSiteUUID}
                                    />
                                </Col>
                            )}
                        </Row>
                        <Row className="mx-0 mt-2">
                            <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                                <Button
                                    className="d-flex pt-2 flex-row align-content-center mr-2"
                                    variant="outline-secondary"
                                    onClick={() => { handleClose(); }}
                                    disabled={loadingAddChild}
                                ><FontAwesomeIcon icon={faBan} size="sm" className="mt-1"/>
                                    &nbsp; Cancel</Button>
                                <FormControls
                                    formIsModified={formIsModified}
                                    loading={loadingAddChild}
                                    submitForm={props.submitForm}
                                    showSave={true}
                                    submitType={'save'}
                                    disableAutoSave={true}
                                    isValid={props.isValid}
                                    saveIcon={faPlus}
                                    saveText="Add Child"
                                    saveTextPast="Add Child"
                                />
                            </Col>
                        </Row>
                    </Form>
                }}
            </Formik>
            ) : (
                <Col sm={12} className="bg-white py-3 mx-0 px-0">
                    <CircularLoadingIndicator/>
                </Col>
            )}
        </ModalBase>
    );
};

export default AddChildModal;
