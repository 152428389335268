import React, {useCallback, useEffect, useState} from 'react';
import {apiEndpoint, apiFetch as standardFetch} from '../../utils/api';
import AvailableFormsModal from '../Modal/AvailableFormsModal';
import {ActiveTab, Child, List} from '../../pages/RecordLocator';
import CircularLoadingIndicator from '../CircularLoadingIndicator';
import FormBuilder, {SubmitType} from '../Forms/FormBuilder';
import {StandardFieldOption} from '../Forms/Fields/StandardField';
import { useHistory } from "react-router-dom";
import {FormikValues} from "formik";
import {saveAs} from 'file-saver';
import {useApiFetch} from "../Providers/OidcProvider";
import {mapFormikValuesForFM} from "../../utils/formHelpers";
import GeneralErrorModal from "../Modal/GeneralErrorModal";

export type SiteForm = {
    id : string;
    title : string;
    short : string | null;
    description : string | null;
    parentId : string | null;
    isParent : boolean;
};

export type FormField = {
    name : string;
    type : string;
    label : string;
    breakAfter : boolean;
    labelOnly : boolean;
    value : string;
    options : Array<StandardFieldOption>;
    required : boolean;
    disabled : boolean;
    listName : string;
};

type Props = {
    activeChild : Child | null;
    handleSetActiveTab : (activeTab : ActiveTab) => void;
    siteLists : Array<List> | null;
    canSave : boolean;
    activeForm : SiteForm | null;
    setActiveForm : (activeForm : SiteForm | null) => void;
};

const mapRawForm = (rawForm : any) : SiteForm => ({
    ...rawForm,
    parentId: rawForm.parentFormId
});

export const mapRawField = (rawField : any, siteLists : Array<List>) : FormField => {
    let foundFieldOptions = null;

    if (rawField?.listName) {
        foundFieldOptions = siteLists.find(
            list => (list.name === rawField.listName)
        )?.values;
    }

    return ({
        ...rawField,
        options: foundFieldOptions ? foundFieldOptions.map((option: StandardFieldOption) => ({
            label: option.label,
            value: option.value,
            checked: rawField?.value === option.value || rawField?.value.includes(option.value)
        })) : []
    });
};

const Forms = ({
    activeChild,
    handleSetActiveTab,
    siteLists,
    canSave,
    activeForm,
    setActiveForm
} : Props) => {
    const [siteForms, setSiteForms] = useState<SiteForm[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [activeFormFields, setActiveFormFields] = useState<FormField[] | null>(null);
    const [showGeneralErrorModal, setShowGeneralErrorModal] = useState<boolean>(false);

    let history = useHistory();
    const apiFetch  = useApiFetch();

    const loadAvailableSiteForms = useCallback(async (childId : string) => {
        setSiteForms(null);
        setLoading(true);

        const url = new URL(`/v1/forms/${childId}`, apiEndpoint);

        const response = await apiFetch(url.toString());

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const data = await response.json();

        setSiteForms(data.map(mapRawForm));
        setLoading(false);
    }, [setSiteForms, setLoading, apiFetch]);

    const loadFormFields = useCallback(async (formId : string, childId : string) => {
        if (siteLists?.length) {
            setActiveFormFields(null);
            setLoading(true);

            const url = new URL(`/v1/forms/form/${formId}/${childId}`, apiEndpoint);

            const response = await apiFetch(url.toString());

            if (!response.ok) {
                setShowGeneralErrorModal(true);
                return;
            }

            const data = await response.json();

            setActiveFormFields(data.map((data: any) => mapRawField(data, siteLists)));
            setLoading(false);
        }
    }, [setActiveFormFields, setLoading, siteLists, apiFetch]);

    const handleSetActiveForm = useCallback(async (activeForm : SiteForm) => {
        if (activeChild) {
            setActiveForm(activeForm);
            await loadFormFields(activeForm.id, activeChild.childUUID);
            history.push(`/record-locator?sub=forms&form=${activeForm.id}`);
        }
    }, [setActiveForm, loadFormFields, activeChild, history]);

    const handleFormBuilderSubmit = useCallback(async (values : FormikValues, submitType : SubmitType) => {
        const mappedValues = mapFormikValuesForFM(values);
        const url = new URL(`/v1/forms/${activeForm?.id}/${activeChild?.childUUID}`, apiEndpoint);
        const response = await apiFetch(url.toString(), {
            method: 'PUT',
            body: JSON.stringify({
                withPdf: submitType === 'print',
                withEmail: submitType === 'email',
                values: mappedValues
            })
        });

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        if (submitType === 'print' && activeChild) {
            const data = await response.json();

            const pdfResponse = await standardFetch(data.pdf);
            const blob = await pdfResponse.blob();
            saveAs(
                blob,
                activeForm?.title.replace(/\s/g,'')
                + '-'
                + activeChild.generalDemographics.FirstName
                + activeChild.generalDemographics.LastName
                + '.pdf'
            );
        }
    }, [activeForm, activeChild, apiFetch]);

    useEffect( () => {
        if (activeChild) {
            loadAvailableSiteForms(activeChild.childUUID);
        }
    }, [activeChild, loadAvailableSiteForms]);

    if (loading) {
        return <CircularLoadingIndicator/>;
    }

    return (
        <React.Fragment>
            {!activeForm && siteForms ? (
               <AvailableFormsModal
                   show={true}
                   siteForms={siteForms}
                   handleSetActiveForm={handleSetActiveForm}
                   setActiveTab={handleSetActiveTab}
                   siteName={activeChild?.siteName ? activeChild.siteName : ''}
               />
            ) : !activeFormFields || !activeForm || !siteLists ? (
                    <CircularLoadingIndicator/>
                ) : (
                    <FormBuilder
                        activeForm={activeForm}
                        activeFormFields={activeFormFields}
                        setActiveForm={setActiveForm}
                        handleFormBuilderSubmit={handleFormBuilderSubmit}
                        canSave={canSave}
                        addSchema={{}}
                        disabledConditions={{}}
                    />
                )
            }
            <GeneralErrorModal
                show={showGeneralErrorModal}
                handleShowGeneralErrorModal={setShowGeneralErrorModal}
            />
        </React.Fragment>
    );
};

export default Forms;
