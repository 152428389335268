import {UserManager} from 'oidc-client-ts';

const {protocol, hostname, port} = window.location;
const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

export const userManager = new UserManager({
    client_id: process.env.REACT_APP_OAUTH2_CLIENT_ID ?? '',
    redirect_uri: `${baseUrl}/oidc/sign-in-callback`,
    response_type: 'code',
    scope: 'openid profile',
    extraQueryParams: process.env.REACT_APP_OAUTH2_AUDIENCE ? {
        audience: process.env.REACT_APP_OAUTH2_AUDIENCE,
    } : undefined,
    authority: process.env.REACT_APP_OAUTH2_AUTHORITY ?? '',
    silent_redirect_uri: `${baseUrl}/oidc/silent-callback`,
    post_logout_redirect_uri: `${baseUrl}/oidc/sign-out-callback`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: false,
});
