import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../../Fields/StandardField";
import * as yup from 'yup';
import {List} from "../../../../pages/RecordLocator";
import {BaseFormValues} from "./SCEventForm";
import {yupDateRegex} from "../../../../utils/formHelpers";

export const PERIODIC_FOLLOW_UP_STRING = 'Periodic Follow-Up';
export type PERIODIC_FOLLOW_UP = typeof PERIODIC_FOLLOW_UP_STRING;

export type PeriodicFollowUpValues = BaseFormValues & {
    PeriodicFollowUpComments : string;
    PeriodicFollowUpDate : string;
};

export const PeriodicFollowUpSchema = {
    PeriodicFollowUpComments: yup.string(),
    PeriodicFollowUpDate: yupDateRegex.required('You must enter a date'),
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
};

const PeriodicFollowUpForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="PeriodicFollowUpDate"
                        type="date"
                        label="Periodic Follow Up Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="PeriodicFollowUpComments"
                        type="textarea"
                        label="Comments"
                        className="textarea-lg"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
        </>
    );
};

export default PeriodicFollowUpForm;