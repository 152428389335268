import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../Fields/StandardField";
import * as yup from 'yup';

export const SOD_COMPLETED_REPORT_STRING = 'SODCompletedReport';
export type SOD_COMPLETED_REPORT = typeof SOD_COMPLETED_REPORT_STRING;

export const SODCompletedReportValues = {
    ScrFinalScreeningResultDate : ''
};

export const SODCompletedReportSchema = {
    ScrFinalScreeningResultDate: yup.string().required()
};

type Props = {
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
};

const SODCompletedReport = ({
    handleSetFormIsModified
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-1">
                <Col xs={12}>
                    <h3>Find Criteria</h3>
                </Col>
            </Row>
            <Row className="mx-0 px-2 condensed-form-row">
                    <React.Fragment >
                        <Col xs={12} lg={4}>
                            <StandardField
                                name="ScrFinalScreeningResultDate"
                                type="text"
                                label="Screening Final Date:"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                    </React.Fragment>
            </Row>
        </>
    );
};

export default SODCompletedReport;