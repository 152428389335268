import React, {useCallback, useState} from 'react';
import {Child, List} from '../../pages/RecordLocator';
import DemographicsSubNav, {ActiveDemographicsTab} from "../Navigation/DemographicsSubNav";
import {Col, Row} from "react-bootstrap";
import DemographicsGeneralForm, {GeneralDemographics} from "../Forms/Demographics/DemographicsGeneralForm";
import {FormikValues} from "formik";
import {apiEndpoint} from "../../utils/api";
import {useApiFetch} from "../Providers/OidcProvider";
import SignificantAdultsForm, {SignificantAdult} from "../Forms/Demographics/SignificantAdultsForm";
import {mapFormikValuesForFM} from "../../utils/formHelpers";
import OtherAdultsForm, {OtherAdults} from "../Forms/Demographics/OtherAdultsForm";
import CriticalInfoForm, {CriticalInfo} from "../Forms/Demographics/CriticalInfoForm";
import moment from "moment";
import GeneralErrorModal from "../Modal/GeneralErrorModal";
import {ChildSummary} from "../Providers/RecordLocatorProvider";

type Props = {
    activeChild : Child;
    siteLists : Array<List>;
    generalDemographics : GeneralDemographics;
    significantAdults : Array<SignificantAdult>;
    otherAdults : OtherAdults;
    criticalInfo : CriticalInfo;
    setActiveChild : (activeChild : Child) => void;
    systemAlert : (alertText : string, alertTimeout : number) => Promise<void>;
    canSave : boolean;
    canDelete : boolean;
    childSummary : ChildSummary | null;
    setChildSummary : (childSummary : ChildSummary | null) => void;
    staffAssigned: string;
}


const mapGeneralDemographics = (values : any) : GeneralDemographics => ({
    ...values,
});

const mapOtherAdults = (values : any) : OtherAdults => ({
    ...values
});

const mapCriticalInfo = (values : any) : CriticalInfo => ({
    ...values
});

const mapSignificantAdults = (values : any) : SignificantAdult => ({
    ...values
});

const Demographics = ({
    activeChild,
    siteLists,
    generalDemographics,
    significantAdults,
    otherAdults,
    setActiveChild,
    criticalInfo,
    systemAlert,
    canSave,
    canDelete,
    childSummary,
    setChildSummary,
    staffAssigned
} : Props) => {
    const [activeDemographicsTab, setActiveDemographicsTab] = useState<ActiveDemographicsTab>('general');
    const [showGeneralErrorModal, setShowGeneralErrorModal] = useState<boolean>(false);

    const apiFetch = useApiFetch();

    const handleSetActiveDemographicsTab = useCallback( (activeTab : ActiveDemographicsTab) => {
        setActiveDemographicsTab(activeTab);
    }, [setActiveDemographicsTab]);

    const handleDemographicsSubmit = useCallback(async (values : FormikValues) => {
        const mappedValues = mapFormikValuesForFM(values);
        const url = new URL(`/v1/child/demographics/${activeChild.childUUID}`, apiEndpoint);

        const response = await apiFetch(url.toString(), {
            method: 'PUT',
            body: JSON.stringify({
                values: mappedValues
            })
        });

        if (response.status === 403) {
            await systemAlert('Invalid access for child', 5000);
            return;
        }

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const data = await response.json();

        if (activeDemographicsTab === 'general') {
            activeChild.generalDemographics = mapGeneralDemographics(values);
        }

        if (activeDemographicsTab === 'otherAdults') {
            activeChild.otherAdults = mapOtherAdults(values);
        }

        if (activeDemographicsTab === 'criticalInfo') {
            activeChild.criticalInfo = mapCriticalInfo(values);
        }

        if (childSummary) {
            childSummary.age = data.Age;
            childSummary.SY5 = data.SY5;
            childSummary.childCurrently = data.childCurrently;
            childSummary.middleName = activeChild.generalDemographics.MiddleName;
            childSummary.firstName = activeChild.generalDemographics.FirstName;
            childSummary.lastName = activeChild.generalDemographics.LastName;
            childSummary.suffix = activeChild.generalDemographics.Suffix;
            childSummary.dob = activeChild.generalDemographics.DOB;
            childSummary.thirdBirthday = data.thirdBirthday;
            childSummary.primaryLanguage = activeChild.generalDemographics.PrimaryLanguage;
            childSummary.updateDate = moment().format('MM/DD/YYYY').toString();
            childSummary.location = activeChild.generalDemographics.Location;
            childSummary.subLocation = activeChild.generalDemographics.SubLocation;
            childSummary.countyOfResidence = activeChild.generalDemographics.CountyOfResidence;
        }
        setChildSummary(null);
        setChildSummary(childSummary);

    }, [apiFetch, activeChild, systemAlert, activeDemographicsTab, childSummary, setChildSummary]);

    const handleSignificantAdultsSubmit = useCallback(async (values : FormikValues) => {
        if (Array.isArray(values.significantAdults)) {
            await values.significantAdults.map(async (significantAdult : SignificantAdult) => {
                const mappedValues = mapFormikValuesForFM(significantAdult);
                const url = new URL(
                    `/v1/child/significantAdult/${activeChild.childUUID}/${significantAdult['Addresses::UUID']}`,
                    apiEndpoint
                );

                const response = await apiFetch(url.toString(), {
                    method: 'PUT',
                    body: JSON.stringify({
                        values: mappedValues
                    })
                });

                if (!response.ok) {
                    setShowGeneralErrorModal(true);
                    return;
                }
            });

            activeChild.significantAdults = values.significantAdults.map(mapSignificantAdults);
        }
    }, [apiFetch, activeChild]);

    const handleRemoveSignificantAdult = useCallback(async (significantAdultUUID : string) => {
        const url = new URL(
            `/v1/child/significantAdult/${activeChild.childUUID}/${significantAdultUUID}`,
            apiEndpoint
        );

        const response = await apiFetch(url.toString(), {
            method: 'DELETE',
            body: undefined
        });

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        activeChild.significantAdults = activeChild.significantAdults.filter(
            (significantAdult) => significantAdult["Addresses::UUID"] !== significantAdultUUID
        );
    }, [apiFetch, activeChild]);

    const handleAddSignificantAdult = useCallback( async () : Promise<SignificantAdult | undefined> => {
        const url = new URL(`/v1/child/significantAdult/${activeChild.childUUID}`, apiEndpoint);

        const response = await apiFetch(url.toString(), {
            method: 'POST',
            body: JSON.stringify({
            })
        });

        if (response.status === 403) {
            await systemAlert('Invalid access for child', 5000);
            return;
        }

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const data = await response.json();

        const lastSortOrder = Math.max(
            0, ...activeChild.significantAdults.map(a => a["Addresses::AdultSortOrder"])
        );

        let significantAdult = {
            'Addresses::UUID' : data['Addresses::UUID'],
            'Addresses::AdultSortOrder' : lastSortOrder+1,
            'Addresses::AdultLastName' : '',
            'Addresses::AdultFirstName' : '',
            'Addresses::AdultRelation' : '',
            'Addresses::AdultLivesWith' : '',
            'Addresses::AdultLegalGuardian' : '',
            'Addresses::AdultCourtesyTitle' : '',
            'Addresses::AdultEmail' : '',
            'Addresses::AdultEmailNotes' : '',
            'Addresses::AdultLanguage' : '',
            'Addresses::AdultSecondLanguage' : '',
            'Addresses::AdultPermStreet' : '',
            'Addresses::AdultPermCity' : '',
            'Addresses::AdultPermState' : 'FL',
            'Addresses::AdultPermZip' : '',
            'Addresses::AdultMail' : '',
            'Addresses::AdultPhone1' : '',
            'Addresses::AdultPhone2' : '',
            'Addresses::AdultPhone3' : '',
            'Addresses::AdultNotes1' : '',
            'Addresses::AdultNotes2' : '',
            'Addresses::AdultNotes3' : '',
            'Addresses::AdultStreet' : '',
            'Addresses::AdultCity' : '',
            'Addresses::AdultState' : 'FL',
            'Addresses::AdultZip' : '',
            'Addresses::AdultDirectionsToHome' : '',
            'Addresses::AdultPreviousAddresses' : '',
        };

        activeChild.significantAdults.push(significantAdult);
        await setActiveChild(activeChild);
        return significantAdult;
    }, [setActiveChild, activeChild, systemAlert, apiFetch]);

    return (
        <>
            <Row className="mt-4">
                <Col xs={12} lg={3}>
                    <DemographicsSubNav
                        activeDemographicsTab={activeDemographicsTab}
                        handleSetActiveDemographicsTab={handleSetActiveDemographicsTab}
                        hasCriticalInfo={criticalInfo.CriticalNotes !== '' || criticalInfo.MedicaidNumber !== '' || criticalInfo.SSN !== ''}
                    />
                </Col>
                <Col xs={12} lg={9}>
                    {activeDemographicsTab === 'general' && (
                        <DemographicsGeneralForm
                            canSave={canSave}
                            siteLists={siteLists}
                            generalDemographics={mapGeneralDemographics(generalDemographics)}
                            handleDemographicsSubmit={handleDemographicsSubmit}
                            staffAssigned={staffAssigned}
                        />
                    )}
                    {activeDemographicsTab === 'significantAdults' && (
                        <SignificantAdultsForm
                            canSave={canSave}
                            canDelete={canDelete}
                            siteLists={siteLists}
                            significantAdults={significantAdults}
                            handleSignificantAdultsSubmit={handleSignificantAdultsSubmit}
                            handleAddSignificantAdult={handleAddSignificantAdult}
                            handleRemoveSignificantAdult={handleRemoveSignificantAdult}
                        />
                    )}
                    {activeDemographicsTab === 'otherAdults' && (
                        <OtherAdultsForm
                            canSave={canSave}
                            otherAdults={otherAdults}
                            handleDemographicsSubmit={handleDemographicsSubmit}
                        />
                    )}
                    {activeDemographicsTab === 'criticalInfo' && (
                        <CriticalInfoForm
                            canSave={canSave}
                            criticalInfo={criticalInfo}
                            handleDemographicsSubmit={handleDemographicsSubmit}
                        />
                    )}
                </Col>
            </Row>
            <GeneralErrorModal
                show={showGeneralErrorModal}
                handleShowGeneralErrorModal={setShowGeneralErrorModal}
            />
        </>
    );
};

export default Demographics;
