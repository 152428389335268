import React, {useCallback, useState} from 'react';
import {Form, Formik, FormikValues} from "formik";
import {NameToValueMap} from "../FormBuilder";
import {Col, Row} from "react-bootstrap";
import StandardField from "../Fields/StandardField";
import * as yup from 'yup';
import FormControls from "../Fields/FormControls";
import ModifiedPrompt from "../ModifiedPrompt";

export type OtherAdults = {
    OtherAdultInfo : string;
};

type Props = {
    canSave : boolean;
    otherAdults : OtherAdults;
    handleDemographicsSubmit: (values : FormikValues) => void;
};

const OtherAdultsForm = ({
    canSave,
    otherAdults,
    handleDemographicsSubmit
} : Props) => {
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSetFormIsModified = useCallback(async (formIsModified : boolean) => {
        await setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const initialValues : NameToValueMap = otherAdults;

    const schema = yup.object({
        OtherAdultInfo : yup.string()
    });

    return (
        <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (values, formikHelpers) => {
            setLoading(true);
            await handleDemographicsSubmit(values);
            await handleSetFormIsModified(false);
            setLoading(false);
        }}
        enableReinitialize={true}
        >
        {(props) => {
            return <Form className="chris-form-bg pt-2">
                <Row className="mx-0 px-2">
                    <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                        <FormControls
                            formIsModified={formIsModified}
                            loading={loading}
                            submitForm={props.submitForm}
                            showSave={canSave}
                            submitType={'save'}
                            disableAutoSave={false}
                            isValid={props.isValid}
                        />
                    </Col>
                </Row>
                <Row className="mx-1">
                    <Col xs={12}>
                        <hr />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12}>
                        <StandardField
                            name="OtherAdultInfo"
                            type="textarea"
                            label="Additional Significant Adult Information"
                            disabled={!canSave}
                            className="textarea-lg"
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <ModifiedPrompt formIsModified={formIsModified} loading={loading}/>
            </Form>
        }}
        </Formik>
    );
};

export default OtherAdultsForm;