import React, {useCallback} from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../../Fields/StandardField";
import * as yup from 'yup';
import {List} from "../../../../pages/RecordLocator";
import {getOptionsForField, yupDateRegex} from "../../../../utils/formHelpers";
import {BaseFormValues, contactListTypes} from "./SCEventForm";
import {FormikProps} from "formik";

export const REFERRAL_FIRST_CONTACT_STRING = 'Referral First Contact';
export type REFERRAL_FIRST_CONTACT = typeof REFERRAL_FIRST_CONTACT_STRING;

export type ReferralFirstContactValues = BaseFormValues & {
    ReferralFirstContactAwareness : string;
    ReferralFirstContactReason : string;
    ReferralFirstContactRefBy : string;
    ReferralFirstContactRefByEmail : string;
    ReferralFirstContactRefByFax : string;
    ReferralFirstContactRefByPhone : string;
    ReferralFirstContactSource : string;
    ReferralFirstContactSourceEmail : string;
    ReferralFirstContactSourceFax : string;
    ReferralFirstContactSourcePhone : string;
    ReferralFirstContactDate : string;
};

export const ReferralFirstContactSchema = {
    ReferralFirstContactAwareness: yup.string(),
    ReferralFirstContactReason: yup.string(),
    ReferralFirstContactRefBy: yup.string(),
    ReferralFirstContactRefByEmail: yup.string(),
    ReferralFirstContactRefByFax: yup.string(),
    ReferralFirstContactRefByPhone: yup.string(),
    ReferralFirstContactSource: yup.string(),
    ReferralFirstContactSourceEmail: yup.string(),
    ReferralFirstContactSourceFax: yup.string(),
    ReferralFirstContactSourcePhone: yup.string(),
    ReferralFirstContactDate: yupDateRegex.required('You must enter a date')
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
    formProps: FormikProps<any>
    updateListAPRFCRI: (
        contactListType: contactListTypes,
        formProps: FormikProps<any>,
        selectField: string,
        faxField: string,
        phoneField: string,
        emailFiled: string
    ) => Promise<void>
};

const ReferralFirstContactForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified,
    formProps,
    updateListAPRFCRI
} : Props) => {
    const updateReferredBy = useCallback(async (e :  React.ChangeEvent<any>) => {
        await updateListAPRFCRI(
            'ListAPRFCRI',
            formProps,
            e.currentTarget.value,
            'ReferralFirstContactRefByFax',
            'ReferralFirstContactRefByPhone',
            'ReferralFirstContactRefByEmail'
        );
    },[formProps, updateListAPRFCRI]);

    //ts-expect-error
    const updateProvider = useCallback((e : React.ChangeEvent<any>) => {
        updateListAPRFCRI(
            'ProviderList',
            formProps,
            e.currentTarget.value,
            'ReferralFirstContactSourceFax',
            'ReferralFirstContactSourcePhone',
            'ReferralFirstContactSourceEmail'
        );
    },[formProps, updateListAPRFCRI]);

    return (
        <>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralFirstContactDate"
                        type="date"
                        label="Referral First Contact Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralFirstContactRefBy"
                        type="select"
                        label="Referred By"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListAPRFCRI',
                            '',
                            siteLists
                        )}
                        // @ts-expect-error
                        onChange={updateReferredBy}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralFirstContactRefByPhone"
                        type="text"
                        label="Phone"
                        disabled={true}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralFirstContactRefByFax"
                        type="text"
                        label="Fax"
                        disabled={true}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralFirstContactRefByEmail"
                        type="text"
                        label="Email"
                        disabled={true}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralFirstContactSource"
                        type="select"
                        label="Referral Source"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderList',
                            '',
                            siteLists
                        )}
                        // @ts-expect-error
                        onChange={updateProvider}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralFirstContactSourcePhone"
                        type="text"
                        label="Phone"
                        disabled={true}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralFirstContactSourceFax"
                        type="text"
                        label="Fax"
                        disabled={true}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralFirstContactSourceEmail"
                        type="text"
                        label="Email"
                        disabled={true}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="ReferralFirstContactReason"
                        type="text"
                        label="Referral Reason"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralFirstContactAwareness"
                        type="select"
                        label="Awareness Source"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'AwarenessSource',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
        </>
    );
};

export default ReferralFirstContactForm;
