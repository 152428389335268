import React from 'react';
import NavBase from './NavBase';
import {faQuestionCircle, faSearch, faFile, faList, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Nav, Navbar} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {ChrisUserProviderContext} from "../Providers/ChrisUserProvider";
import {ActiveChildProviderContext} from "../Providers/ActiveChildProvider";
import {RecordLocatorProviderContext} from "../Providers/RecordLocatorProvider";

type Props = {
    handleClearActiveChild?: () => void;
    handleClearActiveReport?: () => void;
}

const NavHeader = ({handleClearActiveChild, handleClearActiveReport} : Props) => {
    const history = useHistory();
    const [chrisUserState] = React.useContext(ChrisUserProviderContext);
    const [,, clearActiveChild] = React.useContext(ActiveChildProviderContext);
    const {handleClearSearchForm, handelClearActiveChild} = React.useContext(RecordLocatorProviderContext);

    return (
        <NavBase>
            <div className="text-right d-flex flex-row">
                <Navbar.Toggle aria-controls="chris-nav" />
                <Navbar.Collapse id="chris-nav" className="">
                    <Nav className="mr-auto">
                        <Nav.Link
                            className="d-flex flex-column align-items-center chris-menu-item px-2 pt-3"
                            onClick={() => {
                                window.open(
                                    'http://www.chris.miami.edu/downloads/field_reference_guide.pdf',
                                    '_blank'
                                );
                            }}
                            onKeyDown={(keyPress : any) => {
                                keyPress.preventDefault();
                            }}
                        >
                        <span className="align-items-center flex-column d-flex">
                            <FontAwesomeIcon icon={faQuestionCircle} size="lg" className="cursor-pointer" />
                            <span className="p-2 text-label">Help</span>
                        </span>
                        </Nav.Link>
                        <Nav.Link
                            className="d-flex flex-column align-items-center chris-menu-item mx-1 px-2 pt-3"
                            onClick={async (event : any) => {
                                await clearActiveChild();
                                handleClearSearchForm();
                                handelClearActiveChild();

                                history.push(`/record-locator?sub=recordLocator`);
                                if (history.location.search === `?sub=recordLocator` && handleClearActiveChild) {
                                    handleClearActiveChild();
                                }
                            }}
                            onKeyDown={(keyPress : any) => {
                                keyPress.preventDefault();
                            }}
                        >
                        <span className="align-items-center flex-column d-flex">
                            <FontAwesomeIcon icon={faSearch} size="lg" className="cursor-pointer" />
                            <span className="p-2 text-label">Record Locator</span>
                        </span>
                        </Nav.Link>
                        {chrisUserState.chrisUser.siteUUID && <Nav.Link
                            className="d-flex flex-column align-items-center chris-menu-item mx-1 px-2 pt-3"
                            onClick={(event : any) => {
                                history.push(`/reports?sub=reports`);
                                if (history.location.search === `?sub=reports` && handleClearActiveReport) {
                                    handleClearActiveReport();
                                }
                            }}
                            onKeyDown={(keyPress : any) => {
                                keyPress.preventDefault();
                            }}
                        >
                        <span className="align-items-center flex-column d-flex">
                            <FontAwesomeIcon icon={faFile} size="lg" className="cursor-pointer" />
                            <span className="p-2 text-label">Reports</span>
                        </span>
                        </Nav.Link>}
                        {chrisUserState.chrisUser.isDataFacilitator && (
                            <Nav.Link
                                className="d-flex flex-column align-items-center chris-menu-item mx-1 pt-3 px-2 pr-3 chris-menu-item-border-right"
                                onClick={() => {
                                    history.push(`/manage-lists`);
                                }}
                                onKeyDown={(keyPress : any) => {
                                    keyPress.preventDefault();
                                }}
                            >
                            <span className="align-items-center flex-column d-flex">
                                 <FontAwesomeIcon icon={faList} size="lg" className="cursor-pointer" />
                                <span className="p-2 text-label">Manage Lists</span>
                            </span>
                            </Nav.Link>
                        )}
                        <Nav.Link
                            className="d-flex flex-column align-items-center chris-menu-item pt-3 px-2"
                            onClick={async (event : any) => {
                                history.push(`/logout`);
                            }}
                            onKeyDown={(keyPress : any) => {
                                keyPress.preventDefault();
                            }}
                        >
                        <span className="align-items-center flex-column d-flex">
                             <FontAwesomeIcon icon={faSignOutAlt} size="lg" className="cursor-pointer" />
                            <span className="p-2 text-label">Sign Out</span>
                        </span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </div>
        </NavBase>
    );
};

export default NavHeader;
