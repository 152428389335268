import React from 'react';
import ModalBase from './ModalBase';
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import RecordLocatorForm from "../Forms/RecordLocatorForm";
import {dateSort} from "../../utils/sort";
import {RecordLocatorProviderContext} from "../Providers/RecordLocatorProvider";
import {ChrisUserProviderContext} from "../Providers/ChrisUserProvider";

const ChildSearchHelperModal = () => {
    const [{chrisUser}] = React.useContext(ChrisUserProviderContext);
    const {
        showChildSearchHelperModal,
        handleShowChildSearchHelperModal,
        searchOptions,
        handleClearSearchForm,
        searchResults,
        tooManyResults
    } = React.useContext(RecordLocatorProviderContext);
    const handleCloseModal = () => {
        handleShowChildSearchHelperModal(false);
    };

    const columns = chrisUser.isCosUser ? [
        {
            dataField: 'lastName',
            text: 'Last Name',
            sort: true
        },
        {
            dataField: 'firstName',
            text: 'First Name',
            sort: true
        },
        {
            dataField: 'birthDate',
            text: 'Birth Date',
            sort: true,
            sortFunc: dateSort
        },
        {dataField: 'cos', text: 'COS', headerStyle: {width: '70px'}},
    ] : [
        {
            dataField: 'lastName',
            text: 'Last Name',
            sort: true
        },
        {
            dataField: 'firstName',
            text: 'First Name',
            sort: true
        },
        {
            dataField: 'birthDate',
            text: 'Birth Date',
            sort: true,
            sortFunc: dateSort
        },
        {dataField: 'cos', text: 'COS', headerStyle: {width: '70px'}},
        {dataField: 'demographics', text: 'Demo', headerStyle: {width: '70px'}},
        {dataField: 'tracking', text: 'Track', headerStyle: {width: '70px'}},
    ];

    const availableRecords = searchResults?.filter(
        (searchResult) => chrisUser.isCosUser ? searchResult.cosForm : searchResult.canRead
    );

    return (
        <ModalBase
            show={showChildSearchHelperModal}
            title=""
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleCloseModal}
        >
            <RecordLocatorForm
                searchOptions={searchOptions}
                handleClearSearchForm={handleClearSearchForm}
                canRead={!chrisUser.isCosUser}
            />
            {searchResults !== null && searchResults && searchResults.length < 1 && !tooManyResults && (
                <div className="d-flex flex-column align-items-center w-100 mt-5">
                    <h3>Your request did not match any results.
                        Update your search criteria and try again.</h3>
                </div>
            )}
            {searchResults !== null && searchResults && tooManyResults && (
                <div className="d-flex flex-column align-items-center w-100 mt-5">
                    <h3>Your search returned more than 500 results. Please add additional search
                        criteria to limit your search results.</h3>
                </div>
            )}
            {searchResults && searchResults.length > 0 && (
                <React.Fragment>
                    <p className="ml-1">{availableRecords?.length} available records found</p>
                    <div className="d-flex flex-column align-items-center">
                        <BootstrapTable
                            bootstrap4
                            keyField='childUUID'
                            data={availableRecords ? availableRecords : []}
                            columns={columns}
                            pagination={ paginationFactory({sizePerPage: 15}) }
                            striped
                        />
                    </div>
                </React.Fragment>
            )}
        </ModalBase>
    );
};

export default ChildSearchHelperModal;
