import React, {useCallback, useState} from 'react';
import {Form, Formik, FormikValues} from "formik";
import {NameToValueMap} from "../FormBuilder";
import {Col, Row} from "react-bootstrap";
import StandardField from "../Fields/StandardField";
import * as yup from 'yup';
import FormControls from "../Fields/FormControls";
import ModifiedPrompt from "../ModifiedPrompt";

export type CriticalInfo = {
    CriticalNotes : string;
    MedicaidNumber : string;
    SSN : string;
};

type Props = {
    canSave : boolean;
    criticalInfo : CriticalInfo;
    handleDemographicsSubmit: (values : FormikValues) => void;
};

const CriticalInfoForm = ({
    canSave,
    criticalInfo,
    handleDemographicsSubmit
} : Props) => {
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSetFormIsModified = useCallback(async (formIsModified : boolean) => {
        await setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const initialValues : NameToValueMap = criticalInfo;

    const schema = yup.object({
        CriticalNotes : yup.string(),
        MedicaidNumber : yup.string(),
        SSN : yup.string().matches(
            /[0-9]{3}-[0-9]{2}-[0-9]{4}/,
            'Social Security Number must be entered in the following format: 123-45-6789'
        )
    });

    return (
        <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (values, formikHelpers) => {
            setLoading(true);
            await handleDemographicsSubmit(values);
            await handleSetFormIsModified(false);
            setLoading(false);
        }}
        enableReinitialize={true}
        >
        {(props) => {
            return <Form className="chris-form-bg pt-2">
                <Row className="mx-0 px-2">
                    <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                        <FormControls
                            formIsModified={formIsModified}
                            loading={loading}
                            submitForm={props.submitForm}
                            showSave={canSave}
                            submitType={'save'}
                            disableAutoSave={false}
                            isValid={props.isValid}
                        />
                    </Col>
                </Row>
                <Row className="mx-1">
                    <Col xs={12}>
                        <hr />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={4}>
                        <StandardField
                            name="MedicaidNumber"
                            type="text"
                            label="Medicaid Number"
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={4}>
                        <StandardField
                            name="SSN"
                            type="text"
                            label="Social Security Number"
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12}>
                        <StandardField
                            name="CriticalNotes"
                            type="textarea"
                            label="Critical Notes"
                            disabled={!canSave}
                            className="textarea-lg"
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <ModifiedPrompt formIsModified={formIsModified} loading={loading}/>
            </Form>
        }}
        </Formik>
    );
};

export default CriticalInfoForm;