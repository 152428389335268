import React, {useCallback, useMemo, useState} from 'react';
import {Form, Formik, FormikValues} from "formik";
import {NameToValueMap} from "../FormBuilder";
import {Col, Row} from "react-bootstrap";
import StandardField from "../Fields/StandardField";
import * as yup from 'yup';
import FormControls from "../Fields/FormControls";
import {List} from "../../../pages/RecordLocator";
import ModifiedPrompt from "../ModifiedPrompt";
import {getOptionsForField} from "../../../utils/formHelpers";

export type GeneralDemographics = {
    LastName : string;
    Suffix : string;
    FirstName : string;
    MiddleName : string;
    Relation : string;
    DOB : string;
    Sex : string;
    AlternateSurname : string;
    Nickname : string;
    BirthStateCountry : string;
    BirthCounty : string;
    HomeZonedSchool : string;
    CountyOfResidence : string;
    ServiceCounty : string;
    Location : string;
    PrimaryLanguage : string;
    OtherLanguage : string;
    SubLocation : string;
    StudentNumber : string;
    Migrant : string;
    Ethnicity : string;
    Race : string;
    TimelineStatus : string;
}

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    generalDemographics : GeneralDemographics;
    handleDemographicsSubmit: (values : FormikValues) => void;
    staffAssigned: string;
}

const DemographicsGeneralForm = ({
    canSave,
    siteLists,
    generalDemographics,
    handleDemographicsSubmit,
    staffAssigned
} : Props) => {
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const residenceCountyList = useMemo(() => {
        return getOptionsForField('ResidenceServiceCountyList', '', siteLists)
    }, [siteLists]);

    const extraCounty = useMemo(() => {
        if (!generalDemographics.ServiceCounty) {
            return [];
        }

        const foundCount = residenceCountyList.find((c) => c.label === generalDemographics.ServiceCounty);
        if (!foundCount) {
            return [
                {label: generalDemographics.ServiceCounty, value: generalDemographics.ServiceCounty, checked: true}];
        }
        return [];
    }, [generalDemographics.ServiceCounty, residenceCountyList]);

    const handleSetFormIsModified = useCallback(async (formIsModified : boolean) => {
        await setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const initialValues : NameToValueMap = generalDemographics;

    const schema = yup.object({
        LastName : yup.string(),
        Suffix : yup.string(),
        FirstName : yup.string(),
        MiddleName :yup.string(),
        Relation : yup.string(),
        DOB : yup.date().required(),
        Sex : yup.string(),
        AlternateSurname : yup.string(),
        Nickname : yup.string(),
        BirthStateCountry : yup.string(),
        BirthCounty : yup.string(),
        HomeZonedSchool : yup.string(),
        CountyOfResidence : yup.string(),
        ServiceCounty : yup.string(),
        Location : yup.string(),
        PrimaryLanguage :yup.string(),
        OtherLanguage : yup.string(),
        SubLocation : yup.string(),
        StudentNumber : yup.string(),
        Migrant : yup.string(),
        Ethnicity : yup.string(),
        Race : yup.array().test(
            'hasRace',
            'You must select a Race',
            (value) => {
                if (Array.isArray(value)) {
                    let hasValue = false;
                    value.map((arrayItem : string) => {
                        if (!hasValue) {
                            hasValue = [
                                'Asian',
                                'American Indian or Alaska Native',
                                'Black or African American',
                                'Native Hawaiian or Other Pacific Islander',
                                'Parent Refused Designation',
                                'Unknown',
                                'White'
                            ].includes(arrayItem);
                        }
                        return null;
                    });
                    return hasValue;
                }

                return false;
            }
        ),
    });

    return (
        <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (values, formikHelpers) => {
            setLoading(true);
            await handleDemographicsSubmit(values);
            await handleSetFormIsModified(false);
            setLoading(false);
        }}
        enableReinitialize={true}
        >
        {(props) => {
            return <Form className="chris-form-bg pt-2">
                <Row className="mx-0 px-2">
                    <Col xs={12} lg={8}>
                        <h3 className="pt-3">
                            <strong>Timeline Status: {generalDemographics.TimelineStatus}</strong> &nbsp;
                            <strong>Staff Assigned: {staffAssigned}</strong>
                        </h3>
                    </Col>
                    <Col xs={12} lg={4} className="d-flex flex-row justify-content-start justify-content-lg-end">
                        <FormControls
                            formIsModified={formIsModified}
                            loading={loading}
                            submitForm={props.submitForm}
                            showSave={canSave}
                            submitType={'save'}
                            disableAutoSave={false}
                            isValid={props.isValid}
                        />
                    </Col>
                </Row>
                <Row className="mx-1">
                    <Col xs={12}>
                        <hr />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="LastName"
                            type="text"
                            label="Last Name"
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="Suffix"
                            type="select"
                            label="Suffix"
                            options={getOptionsForField('Suffix', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="FirstName"
                            type="text"
                            label="First Name"
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="MiddleName"
                            type="text"
                            label="Middle Name"
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="Relation"
                            type="select"
                            label="Relation"
                            options={getOptionsForField('Relation', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="DOB"
                            type="date"
                            label="Birth Date"
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="mx-1">
                    <Col xs={12}>
                        <hr />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="Sex"
                            type="select"
                            label="Sex"
                            options={getOptionsForField('Sex', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="AlternateSurname"
                            type="text"
                            label="Alternate Surname"
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="Nickname"
                            type="text"
                            label="Nickname"
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="BirthStateCountry"
                            type="select"
                            label="Birth (State/Country)"
                            options={getOptionsForField('StateCountryList', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="BirthCounty"
                            type="select"
                            label="Birth (County)"
                            options={getOptionsForField('AllCounties', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="HomeZonedSchool"
                            type="select"
                            label="Home School Zone"
                            options={getOptionsForField('HomeSchoolZoneList', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="CountyOfResidence"
                            type="select"
                            label="Residence County"
                            options={residenceCountyList}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="ServiceCounty"
                            type="select"
                            label="Service County"
                            options={[...residenceCountyList, ...extraCounty]}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="Location"
                            type="select"
                            label="Current Location"
                            options={getOptionsForField('LocationList', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="mx-1">
                    <Col xs={12}>
                        <hr />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="PrimaryLanguage"
                            type="select"
                            label="Primary Language"
                            options={getOptionsForField('LanguageList', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="OtherLanguage"
                            type="select"
                            label="Other Language"
                            options={getOptionsForField('LanguageList', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="SubLocation"
                            type="select"
                            label="Current Sub Location"
                            options={getOptionsForField('SubLocationList', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="StudentNumber"
                            type="text"
                            label="Student Number"
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="Migrant"
                            type="select"
                            label="Migrant"
                            options={getOptionsForField('YesNo', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="Ethnicity"
                            type="radio"
                            label="Ethnicity (Hispanic)"
                            options={getOptionsForField('YesNo', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="mx-1">
                    <Col xs={12}>
                        <hr />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} lg={12}>
                        <StandardField
                            name="Race"
                            type="checkbox"
                            label="Race (Check all that apply.)"
                            options={getOptionsForField('Race', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <ModifiedPrompt formIsModified={formIsModified} loading={loading}/>
            </Form>
        }}
        </Formik>
    );
};

export default DemographicsGeneralForm;
