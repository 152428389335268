import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Child} from "../../../../pages/RecordLocator";
import {SCEventValues} from "../../../Tracking/SCEvents";

type Props = {
    activeChild : Child;
    eventValues : SCEventValues;
};

const EventSummary = ({activeChild, eventValues} : Props) => {
    return (
        <Row className="m-3 p-2 significant-adult demo-summary">
            <Col xs={12} lg={4} className="py-2">
                Name: <strong>{activeChild.name}</strong>
            </Col>
            <Col xs={12} lg={4} className="py-2">
                DBNUM: <strong>{activeChild.databaseNumber}</strong>
            </Col>
            <Col xs={12} lg={4} className="py-2">
                Child ID: <strong>{activeChild.childId}</strong>
            </Col>
            <Col xs={12} lg={4} className="py-2">
                Code: <strong>{activeChild.code}</strong>
            </Col>
            <Col xs={12} lg={4} className="py-2">
                DOB: <strong>{activeChild.displayDOB}</strong>
            </Col>
            <Col xs={12} lg={4} className="py-2">
                Age: <strong>{activeChild.age}</strong>
            </Col>
            <Col xs={12} lg={4} className="py-2">
                SY5: <strong>{activeChild.SY5}</strong>
            </Col>
            <Col xs={12} lg={4} className="py-2">
                Update Date: <strong>{eventValues.UpdateDate}</strong>
            </Col>
            <Col xs={12} lg={4} className="py-2">
                Record Creator: <strong>{eventValues.RecordCreator}</strong>
            </Col>
            <Col xs={12} lg={4} className="py-2">
                3rd BDay: <strong>{activeChild.thirdBirthDay}</strong>
            </Col>
            <Col xs={12} lg={4} className="py-2">
                Language: <strong>{activeChild.generalDemographics.PrimaryLanguage}</strong>
            </Col>
            <Col xs={12} lg={4} className="py-2">
                Event Modifier: <strong>{eventValues.EventModifier}</strong>
            </Col>
            <Col xs={12} lg={4} className="py-2">
                County of Residence: <strong>{activeChild.generalDemographics.CountyOfResidence}</strong>
            </Col>
            <Col xs={12} lg={4} className="py-2">
                Staff Assigned: <strong>{activeChild.staffAssignedFullName}</strong>
            </Col>
        </Row>
    );
}

export default (EventSummary);