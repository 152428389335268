import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../../Fields/StandardField";
import * as yup from 'yup';
import {List} from "../../../../pages/RecordLocator";
import {BaseFormValues} from "./SCEventForm";
import {yupDateRegex} from "../../../../utils/formHelpers";

export const PACKET_SENT_STRING = 'Packet Sent';
export type PACKET_SENT = typeof PACKET_SENT_STRING;

export type PacketSentValues = BaseFormValues & {
    PacketSentComments : string;
    PacketSentDate : string;
};

export const PacketSentSchema = {
    PacketSentComments: yup.string(),
    PacketSentDate: yupDateRegex.required('You must enter a date'),
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
};

const PacketSentForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="PacketSentDate"
                        type="date"
                        label="Packet Sent Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="PacketSentComments"
                        type="textarea"
                        label="Comments"
                        className="textarea-lg"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
        </>
    );
};

export default PacketSentForm;