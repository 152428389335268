import {FormikValues} from "formik";
import {List} from "../pages/RecordLocator";
import {StandardFieldOption} from "../components/Forms/Fields/StandardField";
import * as yup from "yup";
import {isMoment} from "moment";

export const mapFormikValuesForFM = (values : FormikValues) => {
    return Object.entries(values).map((fieldValue : Array<any>) => ({
        name: fieldValue[0],
        value: Array.isArray(fieldValue[1])
            ? fieldValue[1].join('|~|')
            : (isMoment(fieldValue[1]) ? fieldValue[1].format("MM/DD/YYYY") : fieldValue[1])
    }));
};

export const getOptionsForField = (listName : string, fieldValue : string, siteLists : Array<List>) => {
    const foundFieldOptions = siteLists.find(
        list => (list.name === listName)
    )?.values;

    return foundFieldOptions ? foundFieldOptions.map((option: StandardFieldOption) => ({
        label: option.label,
        value: option.value,
        checked: fieldValue === option.value || fieldValue.includes(option.value)
    })) : []
};

export const phoneValidation = yup.string().matches(
    /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
    'Phone numbers must be entered in the following format: 555-555-5555'
);

export const yupDateRegex = yup.string().matches(
    /^\d{2}\/\d{2}\/\d{4}$/,
    'Invalid date format. Use MM/DD/YYYY.'
);
