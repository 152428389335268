import React, {useCallback, useState} from 'react';
import ModalBase from './ModalBase';
import {Form, Formik, FormikValues} from "formik";
import {Button, Col, Row} from "react-bootstrap";
import StandardField, {StandardFieldOption} from "../Forms/Fields/StandardField";
import * as yup from "yup";
import FormControls from "../Forms/Fields/FormControls";
import {CONTACT_LOG_STRING} from "../Forms/Tracking/SCEvents/ContactLogForm";
import {REFERRAL_CLOSED_STRING} from "../Forms/Tracking/SCEvents/ReferralClosedForm";
import {APPOINTMENTS_STRING} from "../Forms/Tracking/SCEvents/AppointmentsForm";
import {REFERRAL_OUT_STRING} from "../Forms/Tracking/SCEvents/ReferralOutForm";
import {REFERRAL_IN_STRING} from "../Forms/Tracking/SCEvents/ReferralInForm";
import {REFERRAL_INACTIVE_STRING} from "../Forms/Tracking/SCEvents/ReferralInactiveForm";
import {FOLDER_TO_ESE_DISPLAY, FOLDER_TO_ESE_STRING} from "../Forms/Tracking/SCEvents/FolderToESEForm";
import {ON_HOLD_STRING} from "../Forms/Tracking/SCEvents/OnHoldForm";
import {PACKET_RETURNED_STRING} from "../Forms/Tracking/SCEvents/PacketReturnedForm";
import {PACKET_SENT_STRING} from "../Forms/Tracking/SCEvents/PacketSentForm";
import {PERIODIC_FOLLOW_UP_STRING} from "../Forms/Tracking/SCEvents/PeriodicFollowUpForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faPlus} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

type Props = {
    show : boolean;
    handleSubmitAddSCE : (values : FormikValues) => Promise<boolean | undefined>;
    handleClose : () => void;
};

const SCEventTypeNames = [
    APPOINTMENTS_STRING,
    CONTACT_LOG_STRING,
    FOLDER_TO_ESE_STRING,
    ON_HOLD_STRING,
    PACKET_RETURNED_STRING,
    PACKET_SENT_STRING,
    PERIODIC_FOLLOW_UP_STRING,
    REFERRAL_CLOSED_STRING,
    REFERRAL_IN_STRING,
    REFERRAL_INACTIVE_STRING,
    REFERRAL_OUT_STRING,
] as const;

const typeOptions = SCEventTypeNames.map((e) : StandardFieldOption => {
    if (e === FOLDER_TO_ESE_STRING) {
        return {
            label: FOLDER_TO_ESE_DISPLAY,
            value: FOLDER_TO_ESE_STRING,
            checked: false
        }
    }
    return {
        label: e,
        value: e,
        checked: false
    } as StandardFieldOption;
});

const schema = yup.object({
    eventType: yup.string().required('You must pick an event type'),
    eventDate: yup.string().required('You must pick an event date'),
    nextAction: yup.string().required('You must pick the next action'),
});


export const returnToTracking = 'Return to Tracking Summary';
export const addAnotherEvent = 'Add Another New Event';
export const goToDetail = 'Go To Detail Event Screen';

export const addSCERadioOptions = [
    {
        label : goToDetail,
        value : goToDetail,
        checked : true,
    },
    {
        label : returnToTracking,
        value : returnToTracking,
        checked : false,
    },
    {
        label : addAnotherEvent,
        value : addAnotherEvent,
        checked : false,
    }
];

const AddSCEModal = ({
    show,
    handleSubmitAddSCE,
    handleClose
} : Props) => {
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const handleSetFormIsModified = useCallback(async (formIsModified : boolean) => {
        await setFormIsModified(formIsModified);
    }, [setFormIsModified]);
    const currentDateParts = new Date().toISOString().split('T');

    return (
        <ModalBase
            show={show}
            title=""
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleClose}
        >
            <Formik
                initialValues={{
                    eventType: '',
                    eventDate: moment(currentDateParts[0]).format('MM/DD/YYYY'),
                    nextAction: 'Go To Detail Event Screen'
                }}
                validationSchema={schema}
                enableReinitialize={true}
                onSubmit={async (values, formHelpers) => {
                    setLoading(true);
                    if (await handleSubmitAddSCE(values)) {
                        await handleSetFormIsModified(false);
                        formHelpers.setFieldValue('eventType', '', false);
                        formHelpers.setFieldValue('eventDate', values['eventDate'], false);
                        setLoading(false);
                    }
                }}
            >
                {(props) => {
                    return <Form>
                        <Row className="chris-form-bg mx-0 py-3 px-2">
                            <Col xs={12} lg={6}>
                                <StandardField
                                    name="eventType"
                                    type="select"
                                    label="Event Type"
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                    options={typeOptions}
                                />
                            </Col>
                            <Col xs={12} lg={6}>
                                <StandardField
                                    name="eventDate"
                                    type="date"
                                    label="Event Date"
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                />
                            </Col>
                            <Col xs={12} lg={6}>
                                <StandardField
                                    name="nextAction"
                                    type="radio"
                                    label="Navigate To"
                                    disabled={false}
                                    handleSetFormIsModified={handleSetFormIsModified}
                                    options={addSCERadioOptions}
                                />
                            </Col>
                        </Row>
                        <Row className="mx-0 mt-2">
                            <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                                <Button
                                    className="d-flex pt-2 flex-row align-content-center mr-2"
                                    variant="outline-secondary"
                                    onClick={() => { handleClose(); }}
                                    disabled={loading}
                                ><FontAwesomeIcon icon={faBan} size="sm" className="mt-1"/>
                                    &nbsp; Cancel</Button>
                                <FormControls
                                    formIsModified={formIsModified}
                                    loading={loading}
                                    submitForm={props.submitForm}
                                    showSave={true}
                                    submitType={'save'}
                                    disableAutoSave={true}
                                    isValid={props.isValid}
                                    saveIcon={faPlus}
                                    saveText="Add Event"
                                    saveTextPast="Add Event"
                                />
                            </Col>
                        </Row>
                    </Form>
                }}
            </Formik>
        </ModalBase>
    );
};

export default AddSCEModal;
