import React from 'react';
import {Col, Row} from "react-bootstrap";
import {List} from "../../../../pages/RecordLocator";
import {BaseTimelineFormValues, getDaysElapsedError, requiredFieldMsg, SudoRequiredField} from "./TimelineEventForm";
import StandardField from "../../Fields/StandardField";
import {getOptionsForField, yupDateRegex} from "../../../../utils/formHelpers";
import * as yup from "yup";
import moment from "moment";
import {isString} from "formik";

export const EVALUATION_STRING = 'Evaluation';
export type EVALUATION = typeof EVALUATION_STRING;

export type EvaluationValues = BaseTimelineFormValues & {
    EvlAdaptiveCompleted : string;
    EvlAdaptiveDiag : string;
    EvlAdaptiveRecordReview : string;
    EvlAdaptiveRR : string;
    EvlAudiologCompleted : string;
    EvlAudiologDiag : string;
    EvlAudiologRecordReview : string;
    EvlAudiologRR : string;
    EvlBehObsrvCompleted : string;
    EvlBehObsrvDiag : string;
    EvlBehObsrvRecordReview : string;
    EvlBehObsrvRR : string;
    EvlCommunCompleted : string;
    EvlCommunDiag : string;
    EvlCommunRecordReview : string;
    EvlCommunRR : string;
    EvlDevelopmentalCompleted : string;
    EvlDevelopmentalDiag : string;
    EvlDevelopmentalRecordReview : string;
    EvlDevelopmentalRR : string;
    EvlEducationCompleted : string;
    EvlEducationDiag : string;
    EvlEducationRecordReview : string;
    EvlEducationRR : string;
    EvlEmBhvrCompleted : string;
    EvlEmBhvrDiag : string;
    EvlEmBhvrRecordReview : string;
    EvlEmBhvrRR : string;
    EvlFuncVsnCompleted : string;
    EvlFuncVsnDiag : string;
    EvlFuncVsnRecordReview : string;
    EvlFuncVsnRR : string;
    EvlIntellectCompleted : string;
    EvlIntellectDiag : string;
    EvlIntellectRecordReview : string;
    EvlIntellectRR : string;
    EvlLanguageCompleted : string;
    EvlLanguageDiag : string;
    EvlLanguageRecordReview : string;
    EvlLanguageRR : string;
    EvlMedicalCompleted : string;
    EvlMedicalDiag : string;
    EvlMedicalRecordReview : string;
    EvlMedicalRR : string;
    EvlMotorCompleted : string;
    EvlMotorDiag : string;
    EvlMotorRecordReview : string;
    EvlMotorRR : string;
    EvlSocDevHCompleted : string;
    EvlSocDevHDiag : string;
    EvlSocDevHRecordReview : string;
    EvlSocDevHRR : string;
    EvlSpeechCompleted : string;
    EvlSpeechDiag : string;
    EvlSpeechRecordReview : string;
    EvlSpeechRR : string;
    EvlVisionCompleted : string;
    EvlVisionDiag : string;
    EvlVisionRecordReview : string;
    EvlVisionRR : string;
    EvlPresentingProblem1 : string;
    EvlPresentingProblem2 : string;
    EvlPresentingProblem3 : string;
    EvlReferralForEvaluationDate : string;
    EvlParentConsentForEval : string;
    EvlFinalEvalDate : string;
    EvlInterpreterUsed : string;
    EvlInstrumentsUsed : string;
    EvlNotes : string;
    'DemoEntry to Timeline by AN to EvlAN::ActionNeeded' : string;
    'DemoEntry to Timeline by AN to EvlAN::ANCompletedDate' : string;
    'DemoEntry to Timeline by AN to EvlAN::ANFollowUpDate' : string;
};

export const EvaluationSchema = {
    EvlAdaptiveCompleted: yupDateRegex,
    EvlAdaptiveDiag: yup.string(),
    EvlAdaptiveRecordReview: yup.string(),
    EvlAdaptiveRR: yupDateRegex,
    EvlAudiologCompleted: yupDateRegex,
    EvlAudiologDiag: yup.string(),
    EvlAudiologRecordReview: yup.string(),
    EvlAudiologRR: yupDateRegex,
    EvlBehObsrvCompleted: yupDateRegex,
    EvlBehObsrvDiag: yup.string(),
    EvlBehObsrvRecordReview: yup.string(),
    EvlBehObsrvRR: yupDateRegex,
    EvlCommunCompleted: yupDateRegex,
    EvlCommunDiag: yup.string(),
    EvlCommunRecordReview: yup.string(),
    EvlCommunRR: yupDateRegex,
    EvlDevelopmentalCompleted: yupDateRegex,
    EvlDevelopmentalDiag: yup.string(),
    EvlDevelopmentalRecordReview: yup.string(),
    EvlDevelopmentalRR: yupDateRegex,
    EvlEducationCompleted: yupDateRegex,
    EvlEducationDiag: yup.string(),
    EvlEducationRecordReview: yup.string(),
    EvlEducationRR: yupDateRegex,
    EvlEmBhvrCompleted: yupDateRegex,
    EvlEmBhvrDiag: yup.string(),
    EvlEmBhvrRecordReview: yup.string(),
    EvlEmBhvrRR: yupDateRegex,
    EvlFuncVsnCompleted: yupDateRegex,
    EvlFuncVsnDiag: yup.string(),
    EvlFuncVsnRecordReview: yup.string(),
    EvlFuncVsnRR: yupDateRegex,
    EvlIntellectCompleted: yupDateRegex,
    EvlIntellectDiag: yup.string(),
    EvlIntellectRecordReview: yup.string(),
    EvlIntellectRR: yupDateRegex,
    EvlLanguageCompleted: yupDateRegex,
    EvlLanguageDiag: yup.string(),
    EvlLanguageRecordReview: yup.string(),
    EvlLanguageRR: yupDateRegex,
    EvlMedicalCompleted: yupDateRegex,
    EvlMedicalDiag: yup.string(),
    EvlMedicalRecordReview: yup.string(),
    EvlMedicalRR: yupDateRegex,
    EvlMotorCompleted: yupDateRegex,
    EvlMotorDiag: yup.string(),
    EvlMotorRecordReview: yup.string(),
    EvlMotorRR: yupDateRegex,
    EvlSocDevHCompleted: yupDateRegex,
    EvlSocDevHDiag: yup.string(),
    EvlSocDevHRecordReview: yup.string(),
    EvlSocDevHRR: yupDateRegex,
    EvlSpeechCompleted: yupDateRegex,
    EvlSpeechDiag: yup.string(),
    EvlSpeechRecordReview: yup.string(),
    EvlSpeechRR: yupDateRegex,
    EvlVisionCompleted: yupDateRegex,
    EvlVisionDiag: yup.string(),
    EvlVisionRecordReview: yup.string(),
    EvlVisionRR: yupDateRegex,
    EvlPresentingProblem1: yup.string(),
    EvlPresentingProblem2: yup.string(),
    EvlPresentingProblem3: yup.string(),
    EvlReferralForEvaluationDate: yup.date().min(
        moment().subtract('2', 'year').toDate(), 'Referral Date must be later than ' +
        moment().subtract('2', 'year').format('MM/DD/YYYY')
    ).max(
        moment().toDate(), 'Date Must Not Be In The Future.'
    ),
    EvlParentConsentForEval: yup.date().max(
        moment().toDate(),
        'Date Must Not Be In The Future.'
    ),
    EvlFinalEvalDate: yup.date().min(
        moment().subtract('2', 'year').toDate(), 'Completion Date must be later than ' +
        moment().subtract('2', 'year').format('MM/DD/YYYY')
    ).max(
        moment().toDate(),
        'Date Must Not Be In The Future.'
    ),
    EvlInterpreterUsed: yup.string(),
    EvlInstrumentsUsed: yup.string(),
    EvlNotes: yup.string(),
    'DemoEntry to Timeline by AN to EvlAN::ActionNeeded': yup.string(),
    'DemoEntry to Timeline by AN to EvlAN::ANCompletedDate': yupDateRegex,
    'DemoEntry to Timeline by AN to EvlAN::ANFollowUpDate': yupDateRegex,
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
    handleCheckNegativeDaysElapsed : (eventName : string, date : string) => void;
    negativeDaysElapsed : boolean;
    sudoRequiredFields: Array<SudoRequiredField>;
};

const EvaluationForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified,
    handleCheckNegativeDaysElapsed,
    negativeDaysElapsed,
    sudoRequiredFields
} : Props) => {

    return (
        <div id="screeningEvaluationForm">
            <Row className="mx-0 px-2">
                <Col xs={12} lg={3}>
                </Col>
                <Col xs={12} lg={6} className="d-flex flex-row justify-content-center">
                    <h3 className="pt-lg-4">Presenting Problems at Time of Evaluation</h3>
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlInterpreterUsed"
                        type="radio"
                        label="Interpreter Used"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'YesNo',
                            '',
                            siteLists
                        )}
                    />
                </Col>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="EvlPresentingProblem1"
                        type="select"
                        label="Presenting Problem (Primary)"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'PresentingProblem',
                            '',
                            siteLists
                        )}
                        errorNoticeOverride={sudoRequiredFields.find(
                            (sudoRequiredField) =>
                                sudoRequiredField.name === 'EvlPresentingProblem1'
                        )?.showError ? requiredFieldMsg['EvlPresentingProblem1'] : ''}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="EvlPresentingProblem2"
                        type="select"
                        label="Secondary I"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'PresentingProblem',
                            '',
                            siteLists
                        )}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="EvlPresentingProblem3"
                        type="select"
                        label="Secondary II"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'PresentingProblem',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="EvlReferralForEvaluationDate"
                        type="date"
                        label="Referral Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        errorNoticeOverride={sudoRequiredFields.find(
                            (sudoRequiredField) =>
                                sudoRequiredField.name === 'EvlReferralForEvaluationDate'
                        )?.showError ? requiredFieldMsg['EvlReferralForEvaluationDate'] : ''}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="EvlParentConsentForEval"
                        type="date"
                        label="Parent Consent Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        errorNoticeOverride={sudoRequiredFields.find(
                            (sudoRequiredField) =>
                                sudoRequiredField.name === 'EvlParentConsentForEval'
                        )?.showError ? requiredFieldMsg['EvlParentConsentForEval'] : ''}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="EvlFinalEvalDate"
                        type="date"
                        label="Completion Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        onChange={(value) => {
                            handleCheckNegativeDaysElapsed(EVALUATION_STRING, isString(value) ? value : '');
                        }}
                        errorNoticeOverride={getDaysElapsedError(
                            'EvlFinalEvalDate',
                            negativeDaysElapsed,
                            sudoRequiredFields
                        )}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2 condensed-form-row">
                <Col xs={12} lg={2} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>Evaluation</strong>
                </Col>
                <Col xs={12} lg={3} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>Comp Date</strong>
                </Col>
                <Col xs={12} lg={1} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>RR*</strong>
                </Col>
                <Col xs={12} lg={3} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>Report Rcvd</strong>
                </Col>
                <Col xs={12} lg={3} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>Diagnostician</strong>
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Adaptive
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlAdaptiveCompleted"
                        type="date"
                        label="Adaptive Comp Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={1} className="pt-2 d-flex flex-column flex-lg-row justify-content-center">
                    <StandardField
                        name="EvlAdaptiveRecordReview"
                        type="checkbox"
                        label="Adaptive RR*:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'Yes',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlAdaptiveRR"
                        type="date"
                        label="Adaptive Report Rcvd:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlAdaptiveDiag"
                        type="select"
                        label="Adaptive Diagnostician:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Audiological
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlAudiologCompleted"
                        type="date"
                        label="Audiological Comp Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={1} className="pt-2 d-flex flex-column flex-lg-row justify-content-center">
                    <StandardField
                        name="EvlAudiologRecordReview"
                        type="checkbox"
                        label="Audiological RR*:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'Yes',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlAudiologRR"
                        type="date"
                        label="Audiological Report Rcvd:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlAudiologDiag"
                        type="select"
                        label="Audiological Diagnostician:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Behave Observ
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlBehObsrvCompleted"
                        type="date"
                        label="Behave Observ Comp Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={1} className="pt-2 d-flex flex-column flex-lg-row justify-content-center">
                    <StandardField
                        name="EvlBehObsrvRecordReview"
                        type="checkbox"
                        label="Behave Observ RR*:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'Yes',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlBehObsrvRR"
                        type="date"
                        label="Behave Observ Report Rcvd:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlBehObsrvDiag"
                        type="select"
                        label="Behave Observ Diagnostician:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Communication
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlCommunCompleted"
                        type="date"
                        label="Communication Comp Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={1} className="pt-2 d-flex flex-column flex-lg-row justify-content-center">
                    <StandardField
                        name="EvlCommunRecordReview"
                        type="checkbox"
                        label="Communication RR*:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'Yes',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlCommunRR"
                        type="date"
                        label="Communication Report Rcvd:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlCommunDiag"
                        type="select"
                        label="Communication Diagnostician:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Developmental
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlDevelopmentalCompleted"
                        type="date"
                        label="Developmental Comp Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={1} className="pt-2 d-flex flex-column flex-lg-row justify-content-center">
                    <StandardField
                        name="EvlDevelopmentalRecordReview"
                        type="checkbox"
                        label="Developmental RR*:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'Yes',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlDevelopmentalRR"
                        type="date"
                        label="Developmental Report Rcvd:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlDevelopmentalDiag"
                        type="select"
                        label="Developmental Diagnostician:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Educational
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlEducationCompleted"
                        type="date"
                        label="Educational Comp Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={1} className="pt-2 d-flex flex-column flex-lg-row justify-content-center">
                    <StandardField
                        name="EvlEducationRecordReview"
                        type="checkbox"
                        label="Educational RR*:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'Yes',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlEducationRR"
                        type="date"
                        label="Educational Report Rcvd:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlEducationDiag"
                        type="select"
                        label="Educational Diagnostician:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Emotional/Behav
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlEmBhvrCompleted"
                        type="date"
                        label="Emotional/Behav Comp Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={1} className="pt-2 d-flex flex-column flex-lg-row justify-content-center">
                    <StandardField
                        name="EvlEmBhvrRecordReview"
                        type="checkbox"
                        label="Emotional/Behav RR*:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'Yes',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlEmBhvrRR"
                        type="date"
                        label="Emotional/Behav Report Rcvd:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlEmBhvrDiag"
                        type="select"
                        label="Emotional/Behav Diagnostician:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Function Vision
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlFuncVsnCompleted"
                        type="date"
                        label="Function Vision Comp Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={1} className="pt-2 d-flex flex-column flex-lg-row justify-content-center">
                    <StandardField
                        name="EvlFuncVsnRecordReview"
                        type="checkbox"
                        label="Function Vision RR*:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'Yes',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlFuncVsnRR"
                        type="date"
                        label="Function Vision Report Rcvd:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlFuncVsnDiag"
                        type="select"
                        label="Function Vision Diagnostician:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Intellectual
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlIntellectCompleted"
                        type="date"
                        label="Intellectual Comp Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={1} className="pt-2 d-flex flex-column flex-lg-row justify-content-center">
                    <StandardField
                        name="EvlIntellectRecordReview"
                        type="checkbox"
                        label="Intellectual RR*:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'Yes',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlIntellectRR"
                        type="date"
                        label="Intellectual Report Rcvd:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlIntellectDiag"
                        type="select"
                        label="Intellectual Diagnostician:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Language
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlLanguageCompleted"
                        type="date"
                        label="Language Comp Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={1} className="pt-2 d-flex flex-column flex-lg-row justify-content-center">
                    <StandardField
                        name="EvlLanguageRecordReview"
                        type="checkbox"
                        label="Language RR*:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'Yes',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlLanguageRR"
                        type="date"
                        label="Language Report Rcvd:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlLanguageDiag"
                        type="select"
                        label="Language Diagnostician:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Medical
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlMedicalCompleted"
                        type="date"
                        label="Medical Comp Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={1} className="pt-2 d-flex flex-column flex-lg-row justify-content-center">
                    <StandardField
                        name="EvlMedicalRecordReview"
                        type="checkbox"
                        label="Medical RR*:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'Yes',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlMedicalRR"
                        type="date"
                        label="Medical Report Rcvd:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlMedicalDiag"
                        type="select"
                        label="Medical Diagnostician:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Motor
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlMotorCompleted"
                        type="date"
                        label="Motor Comp Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={1} className="pt-2 d-flex flex-column flex-lg-row justify-content-center">
                    <StandardField
                        name="EvlMotorRecordReview"
                        type="checkbox"
                        label="Motor RR*:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'Yes',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlMotorRR"
                        type="date"
                        label="Motor Report Rcvd:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlMotorDiag"
                        type="select"
                        label="Motor Diagnostician:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Soc Dev Hst
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlSocDevHCompleted"
                        type="date"
                        label="Soc Dev Hst Comp Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={1} className="pt-2 d-flex flex-column flex-lg-row justify-content-center">
                    <StandardField
                        name="EvlSocDevHRecordReview"
                        type="checkbox"
                        label="Soc Dev Hst RR*:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'Yes',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlSocDevHRR"
                        type="date"
                        label="Soc Dev Hst Report Rcvd:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlSocDevHDiag"
                        type="select"
                        label="Soc Dev Hst Diagnostician:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Speech
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlSpeechCompleted"
                        type="date"
                        label="Speech Comp Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={1} className="pt-2 d-flex flex-column flex-lg-row justify-content-center">
                    <StandardField
                        name="EvlSpeechRecordReview"
                        type="checkbox"
                        label="Speech RR*:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'Yes',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlSpeechRR"
                        type="date"
                        label="Speech Report Rcvd:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlSpeechDiag"
                        type="select"
                        label="Speech Diagnostician:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Vision
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlVisionCompleted"
                        type="date"
                        label="Vision Comp Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={1} className="pt-2 d-flex flex-column flex-lg-row justify-content-center">
                    <StandardField
                        name="EvlVisionRecordReview"
                        type="checkbox"
                        label="Vision RR*:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                        options={getOptionsForField(
                            'Yes',
                            '',
                            siteLists
                        )}
                        hideCheckboxDescription={true}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlVisionRR"
                        type="date"
                        label="Vision Report Rcvd:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="EvlVisionDiag"
                        type="select"
                        label="Vision Diagnostician:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="EvlInstrumentsUsed"
                        type="text"
                        label="Instruments Used:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default EvaluationForm;