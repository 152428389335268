import moment from "moment";

export function comparator(a : any, b : any) {
    let result;
    if (typeof b === 'string') {
        result = b.localeCompare(a);
    } else {
        result = a > b ? -1 : ((a < b) ? 1 : 0);
    }
    return result;
}

export const sort = (data : any, sortOrder : string, dataField : string) => {
    const _data = [...data];
    _data.sort((a, b) => {
        let result;
        let valueA = a[dataField];
        let valueB = b[dataField];
        valueA = typeof valueA === 'undefined' ? '' : valueA;
        valueB = typeof valueB === 'undefined' ? '' : valueB;

        if (sortOrder === 'desc') {
            result = comparator(valueA, valueB);
        } else {
            result = comparator(valueB, valueA);
        }
        return result;
    });
    return _data;
};

export const dateSort = (a : string, b : string, order : string) => {
    if (order === 'asc') {
        return moment(b).toDate().getTime() - moment(a).toDate().getTime();
    }
    return moment(a).toDate().getTime() - moment(b).toDate().getTime(); // desc
};
