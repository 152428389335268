import React, {useCallback, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import NavHeader from "../components/Navigation/NavHeader";
import AvailableFormsReports from "../components/SiteForms/AvailableFormsReports";
import {apiEndpoint, apiFetch as standardFetch} from "../utils/api";
import {useApiFetch} from "../components/Providers/OidcProvider";
import CircularLoadingIndicator from "../components/CircularLoadingIndicator";
import {useHistory} from "react-router-dom";
import {FormikValues} from "formik";
import {mapFormikValuesForFM} from "../utils/formHelpers";
import {saveAs} from "file-saver";
import ReportBuilder, {ReportSubmitType} from "../components/Reports/ReportBuilder";
import ReportAlternateResponseModal from "../components/Modal/ReportAlternateResponseModal";
import {STANDARD_REPORTS_STRING} from "../components/Forms/Reports/StandardReports";
import {TIMELINE_REPORT_STRING} from "../components/Forms/Reports/TimelineReport";
import {DAYS_ELAPSED_REPORT_STRING} from "../components/Forms/Reports/DaysElapsedReport";
import {SIXTY_DAY_REPORT_STRING} from "../components/Forms/Reports/SixtyDayReport";
import {SOD_REFERRAL_REPORT_STRING} from "../components/Forms/Reports/SODReferralReport";
import {SOD_COMPLETED_REPORT_STRING} from "../components/Forms/Reports/SODCompletedReport";
import {SOD_EVALUATION_REPORT_STRING} from "../components/Forms/Reports/SODEvaluationReport";
import {ChrisUserProviderContext} from "../components/Providers/ChrisUserProvider";
import {ReportsProviderContext} from "../components/Providers/ReportsProvider";
import ActiveUserNav from "../components/Navigation/ActiveUserNav";
import {ActiveChildProviderContext} from "../components/Providers/ActiveChildProvider";
import ActiveChildNav from "../components/Navigation/ActiveChildNav";
import GeneralErrorModal from "../components/Modal/GeneralErrorModal";
import {COS_REPORT_STRING} from "../components/Forms/Reports/COSReport";

export type Report = {
    id : string;
    title : string;
    short : string | null;
    description : string | null;
    parentId : string | null;
    isParent : boolean;
    contextTable? : string;
};

const COSReportLabel = 'Child Outcomes Form Excel Report';

const Reports = () => {
    const [{chrisUser}] = React.useContext(ChrisUserProviderContext);
    const [activeReport, setActiveReport] = useState<Report | null>(null);
    const [showAlternateResponseModal, setShowAlternateResponseModal] = useState<boolean>(false);
    const [alternateResponse, setAlternateResponse] = useState<string>('');
    const [chrisUserState, siteLists] = React.useContext(ChrisUserProviderContext);
    const [reports] = React.useContext(ReportsProviderContext);
    const [activeChild, loadActiveChild] = React.useContext(ActiveChildProviderContext);
    const [showGeneralErrorModal, setShowGeneralErrorModal] = useState<boolean>(false);

    const apiFetch  = useApiFetch();
    let history = useHistory();

    const handleSetActiveReport = useCallback(async (activeReport : Report) => {
            setActiveReport(activeReport);
            history.push(`/reports?sub=reports&report=${activeReport.id}`);
    }, [setActiveReport, history]);

    const handleClearActiveReport = useCallback( () => {
        setActiveReport(null);
    }, [setActiveReport]);

    const handleReportBuilderSubmit = useCallback(async (values : FormikValues, submitType : ReportSubmitType) => {
        const mappedValues = mapFormikValuesForFM(values);
        const url = new URL(`/v1/reports/${activeReport?.id}`, apiEndpoint);
        const response = await apiFetch(url.toString(), {
            method: 'PUT',
            body: JSON.stringify({
                type: submitType,
                values: mappedValues
            })
        });

        // No Records Match Request
        if (response.status === 204) {
            setAlternateResponse('Find criteria did not match any responses. Please modify your find criteria and try your request again.')
            setShowAlternateResponseModal(true);
            return;
        }

        // Too many records
        if (response.status === 413) {
            setAlternateResponse('Your report request returned too many results. Please add additional find criteria to limit your report results or contact the Help Desk for assistance.')
            setShowAlternateResponseModal(true);
            return;
        }

        // Response sent to email
        if (response.status === 206) {
            setAlternateResponse('Your report request is taking longer to complete than expected. Your report will be emailed to your account email when complete. There is no need to regenerate the report.')
            setShowAlternateResponseModal(true);
            return;
        }

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        if (submitType === 'print' && activeReport) {
            const data = await response.json();

            const pdfResponse = await standardFetch(data.pdf);
            const downloadUrl = new URL(data.pdf);
            const fileExtension = downloadUrl.pathname.split('.').slice(-1)[0];

            const blob = await pdfResponse.blob();
            saveAs(
                blob,
                activeReport?.title.replace(/\s/g,'')
                + '.' + fileExtension
            );
        }

        if (submitType === 'count' && activeReport) {
            const data = await response.json();
            setAlternateResponse(`The generated report returned ${data.count} results.`);
            setShowAlternateResponseModal(true);
        }
    }, [activeReport, apiFetch]);

    const getReportTypeForName = (reportName : string) => {
        switch (reportName) {
            case 'Timeline Report':
                return TIMELINE_REPORT_STRING;
            case 'Days Elapsed between Screening and Parent Consent':
                return DAYS_ELAPSED_REPORT_STRING;
            case '60 Day Rule Report':
                return SIXTY_DAY_REPORT_STRING;
            case 'SOD Referral Report':
                return SOD_REFERRAL_REPORT_STRING;
            case 'SOD Completed Screening Report':
                return SOD_COMPLETED_REPORT_STRING;
            case 'SOD Referral for Evaluation Report':
                return SOD_EVALUATION_REPORT_STRING;
            case COSReportLabel:
                return COS_REPORT_STRING;
            default:
                return STANDARD_REPORTS_STRING;
        }
    };

    React.useEffect(() => {
        loadActiveChild();
    }, []);

    return (
        <>
            <NavHeader handleClearActiveReport={handleClearActiveReport} />
            <ActiveUserNav chrisUser={chrisUserState.chrisUser}>
                {activeChild && activeChild.activeChild ? (
                    <ActiveChildNav
                        activeChild={activeChild.activeChild}
                    />
                ) : (<></>)}
            </ActiveUserNav>
            <Container>
                <Row>
                    {reports.length < 1 ? (
                        <Col sm={12} className="bg-white py-3 mx-0 px-0">
                            <CircularLoadingIndicator/>
                        </Col>
                    ) : (
                        <Col
                            xs={12}
                            className="d-flex flex-column align-items-center mt-4 available-reports-container w-100"
                        >
                            {reports && !activeReport && (
                                <AvailableFormsReports
                                    siteFormsReports={reports.filter((r) => {
                                        if (!chrisUser.isCosUser) {
                                            return true;
                                        }
                                        if (r.title===COSReportLabel) {
                                            return true;
                                        }
                                        return false;
                                    })}
                                    siteName={null}
                                    handleSetActiveFormReport={handleSetActiveReport}
                                    type="Report"
                                />
                            )}
                            {activeReport && (siteLists.length > 0 || chrisUser.isCosUser) && (
                                <ReportBuilder
                                    activeReport={activeReport}
                                    setActiveReport={setActiveReport}
                                    handleReportBuilderSubmit={handleReportBuilderSubmit}
                                    reportType={[
                                        'Timelines Report',
                                        'Days Elapsed between Screening and Parent Consent',
                                        '60 Day Rule Report',
                                        'SOD Referral Report',
                                        'SOD Completed Screening Report',
                                        'SOD Referral for Evaluation Report',
                                        COSReportLabel
                                    ].includes(activeReport.title) ?
                                        getReportTypeForName(activeReport.title) : "StandardReports"
                                    }
                                    reportName={activeReport.short ? activeReport.short : ''}
                                    siteLists={siteLists}
                                />
                            )}
                        </Col>
                    )}
                    <ReportAlternateResponseModal
                        show={showAlternateResponseModal}
                        handleShowModal={setShowAlternateResponseModal}
                        notice={alternateResponse}
                    />
                </Row>
                <GeneralErrorModal
                    show={showGeneralErrorModal}
                    handleShowGeneralErrorModal={setShowGeneralErrorModal}
                />
            </Container>
        </>
    );
};

export default Reports;
