import React, {useCallback, useState} from 'react';
import {Form, Formik, FormikValues} from "formik";
import {NameToValueMap} from "../FormBuilder";
import {Col, Row} from "react-bootstrap";
import StandardField from "../Fields/StandardField";
import * as yup from 'yup';
import FormControls from "../Fields/FormControls";
import {Child, List} from "../../../pages/RecordLocator";
import ModifiedPrompt from "../ModifiedPrompt";
import {getOptionsForField, yupDateRegex} from "../../../utils/formHelpers";
import {ChildSummary} from "../../Providers/RecordLocatorProvider";

export type TrackingGeneral = {
    RecordCreator: string;
    UpdateDate: string;
    ThirdBDay: string;
    PrimaryLanguage: string;
    CountyOfResidence: string;
    Location: string;
    SubLocation: string;
    StaffAssignedFullName: string;
    ISCorSCFullName: string;
    Code: string;
    AgenciesKnown1: string;
    AgenciesKnown2: string;
    AgenciesKnown3: string;
    ProvidersKnown1: string;
    ProvidersKnown2: string;
    ProvidersKnown3: string;
    ExceptionalityStatus: string;
    TimelineStatus: string;
    ServiceCoordinationStatus: string;
    InterAgencyRelease: string;
    InterAgencyStartDate: string;
    InterAgencyEndDate: string;
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleTrackingSubmit: (values : FormikValues) => void;
    activeChild : Child,
    trackingGeneral : TrackingGeneral
    childSummary : ChildSummary
};

const TrackingGeneralForm = ({
    canSave,
    siteLists,
    handleTrackingSubmit,
    activeChild,
    trackingGeneral,
    childSummary
} : Props) => {
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSetFormIsModified = useCallback(async (formIsModified : boolean) => {
        await setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    let initialValues : NameToValueMap = trackingGeneral;
    initialValues.ThirdBDay = childSummary.thirdBirthday;
    initialValues.PrimaryLanguage = childSummary.primaryLanguage;
    initialValues.CountyOfResidence = childSummary.countyOfResidence;
    initialValues.Location = childSummary.location;
    initialValues.SubLocation = childSummary.subLocation;
    initialValues.UpdateDate = childSummary.updateDate;

    const schema = yup.object({
        RecordCreator: yup.string(),
        UpdateDate: yup.string(),
        ThirdBDay: yup.string(),
        PrimaryLanguage: yup.string(),
        CountyOfResidence: yup.string(),
        Location: yup.string(),
        SubLocation: yup.string(),
        StaffAssignedFullName: yup.string(),
        ISCorSCFullName: yup.string(),
        Code: yup.string(),
        AgenciesKnown1: yup.string(),
        AgenciesKnown2: yup.string(),
        AgenciesKnown3: yup.string(),
        ProvidersKnown1: yup.string(),
        ProvidersKnown2: yup.string(),
        ProvidersKnown3: yup.string(),
        ExceptionalityStatus: yup.string(),
        TimelineStatus: yup.string(),
        ServiceCoordinationStatus: yup.string(),
        InterAgencyRelease: yup.string(),
        InterAgencyStartDate: yupDateRegex,
        InterAgencyEndDate: yupDateRegex
    });

    return (
        <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (values, formikHelpers) => {
            setLoading(true);
            await handleTrackingSubmit(values);
            await handleSetFormIsModified(false);
            setLoading(false);
        }}
        enableReinitialize={true}
        >
        {(props) => {
            return <Form className="chris-form-bg pt-2">
                <Row className="mx-0 px-2">
                    <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                        <FormControls
                            formIsModified={formIsModified}
                            loading={loading}
                            submitForm={props.submitForm}
                            showSave={canSave}
                            submitType={'save'}
                            disableAutoSave={false}
                            isValid={props.isValid}
                        />
                    </Col>
                </Row>
                <Row className="m-3 px-2 significant-adult demo-summary">
                    <Col xs={12} lg={3} className="py-2">
                        DBNUM: <strong>{activeChild.databaseNumber}</strong>
                    </Col>
                    <Col xs={12} lg={3} className="py-2">
                        Child ID: <strong>{activeChild.childId}</strong>
                    </Col>
                    <Col xs={12} lg={3} className="py-2">
                        DOB: <strong>{childSummary.dob}</strong>
                    </Col>
                    <Col xs={12} lg={3} className="py-2">
                        Age: <strong>{childSummary.age}</strong>
                    </Col>
                    <Col xs={12} lg={3} className="py-2">
                        Last Name: <strong>{childSummary.lastName}</strong>
                    </Col>
                    <Col xs={12} lg={3} className="py-2">
                        Suffix: <strong>{childSummary.suffix}</strong>
                    </Col>
                    <Col xs={12} lg={3} className="py-2">
                        First Name: <strong>{childSummary.firstName}</strong>
                    </Col>
                    <Col xs={12} lg={3} className="py-2">
                        Middle Name: <strong>{childSummary.middleName}</strong>
                    </Col>
                    <Col xs={12} lg={3} className="py-2">
                        SY5: <strong>{childSummary.SY5}</strong>
                    </Col>
                    <Col xs={12} lg={9} className="py-2">
                        This child is currently: <strong>{childSummary.childCurrently}</strong>
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="RecordCreator"
                            type="text"
                            label="Record Creator"
                            disabled={true}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="UpdateDate"
                            type="date"
                            label="Update Date"
                            disabled={true}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="ThirdBDay"
                            type="date"
                            label="3rd Birth Day"
                            disabled={true}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="PrimaryLanguage"
                            type="text"
                            label="Primary Language"
                            disabled={true}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="CountyOfResidence"
                            type="text"
                            label="County of Residence"
                            disabled={true}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="Location"
                            type="text"
                            label="Location"
                            disabled={true}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="SubLocation"
                            type="text"
                            label="Sub Location"
                            disabled={true}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="StaffAssignedFullName"
                            type="select"
                            label="Staff Assigned"
                            options={getOptionsForField('StaffAssignedAndISCList', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="ISCorSCFullName"
                            type="select"
                            label="ISC or Service Coordinator"
                            options={getOptionsForField('StaffAssignedAndISCList', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="Code"
                            type="select"
                            label="Code"
                            options={getOptionsForField('CodeList', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} className="px-2 mb-2 mt-2">
                        <h3>PreK Funding Programs</h3>
                        <hr />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="AgenciesKnown1"
                            type="select"
                            label="Program 1"
                            options={getOptionsForField('PreKFundingList', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="AgenciesKnown2"
                            type="select"
                            label="Program 2"
                            options={getOptionsForField('PreKFundingList', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="AgenciesKnown3"
                            type="select"
                            label="Program 3"
                            disabled={!canSave}
                            options={getOptionsForField('PreKFundingList', '', siteLists)}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} className="px-2 mb-2 mt-2">
                        <h3>Agencies/Providers</h3>
                        <hr />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="ProvidersKnown1"
                            type="select"
                            label="Agency / Provider 1"
                            options={getOptionsForField('ProviderList', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="ProvidersKnown2"
                            type="select"
                            label="Agency / Provider 2"
                            options={getOptionsForField('ProviderList', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="ProvidersKnown3"
                            type="select"
                            label="Agency / Provider 3"
                            options={getOptionsForField('ProviderList', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12}>
                        <hr />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="ExceptionalityStatus"
                            type="text"
                            label="Exceptionality Status"
                            disabled={true}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="TimelineStatus"
                            type="text"
                            label="Timeline Status"
                            disabled={true}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="ServiceCoordinationStatus"
                            type="text"
                            label="Service Coordination Status"
                            disabled={true}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <Row className="mx-0 px-2">
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="InterAgencyRelease"
                            type="select"
                            label="Inter Agency Release"
                            options={getOptionsForField('YesNo', '', siteLists)}
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="InterAgencyStartDate"
                            type="date"
                            label="Start Date"
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="InterAgencyEndDate"
                            type="date"
                            label="End Date"
                            disabled={!canSave}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </Row>
                <ModifiedPrompt formIsModified={formIsModified} loading={loading}/>
            </Form>
        }}
        </Formik>
    );
};

export default TrackingGeneralForm;
