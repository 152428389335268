import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBackward} from "@fortawesome/free-solid-svg-icons";
import FormControls from "../../Fields/FormControls";
import {useHistory} from "react-router-dom";
import {getTimelineNavigationError, TimelineEvent} from "../../../Tracking/TimelineEvents";
import TimelineErrorConfirmationModal from "../../../Modal/TimelineErrorConfirmation";
import {TRANSITION_STRING} from "./TransitionForm";
import {SCREENING_STRING} from "./ScreeningForm";
import {END_TIMELINE_STRING} from "./EndTimelineForm";

export type NextPrev = {
   next : string;
   prev : string;
};

export const getNextPrevEvent : any = {
    'Transition' : {next : 'Screening', prev : null},
    'Screening' : {next : 'Evaluation', prev : 'Transition'},
    'Evaluation' : {next : 'ESE Eligibility', prev : 'Screening'},
    'ESE Eligibility' : {next : 'IEP/IFSP', prev : 'Evaluation'},
    'IEP/IFSP' : {next : 'End Timeline', prev : 'ESE Eligibility'},
    'End Timeline' : {next : null, prev : 'IEP/IFSP'}
};

type Props = {
    handleClearActiveEvent : () => void;
    loading : boolean;
    formIsModified : boolean;
    submitForm : () => Promise<void>;
    canSave : boolean;
    isValid : boolean;
    handleSetActiveTimelineEvent : (timelineEvent : TimelineEvent) => void;
    eventName : string;
    timelineEvents : Array<TimelineEvent>,
    timelineEvent : TimelineEvent
};

const TimelineEventControls = ({
    handleClearActiveEvent,
    loading,
    formIsModified,
    submitForm,
    canSave,
    isValid,
    handleSetActiveTimelineEvent,
    eventName,
    timelineEvents,
    timelineEvent
} : Props) => {
    let history = useHistory();
    const [showTimelineErrorConfirmationModal, setShowTimelineErrorConfirmationModal] = useState<boolean>(
        false
    );
    const [clickedTimelineEvent, setClickedTimelineEvent] = useState<TimelineEvent | null>(null);
    const [clickedPrevNext, setClickedPrevNext] = useState<string>('');
    const nextPrev : NextPrev = getNextPrevEvent[eventName];
    const nextTimelineEvent : TimelineEvent | undefined = timelineEvents.find(
        (timelineEvent) => timelineEvent.name === nextPrev.next
    );
    const prevTimelineEvent : TimelineEvent | undefined = timelineEvents.find(
        (timelineEvent) => timelineEvent.name === nextPrev.prev
    );
    const endTimelineEvent = nextPrev.next ? timelineEvents.find(
        (timelineEvent) => timelineEvent.name === 'End Timeline'
    ) : null;
    let canClickNext = false;
    let canClickPrev = true;

    if (nextTimelineEvent) {
        if (timelineEvent.date !== '' || nextTimelineEvent.name === END_TIMELINE_STRING || nextTimelineEvent.name === SCREENING_STRING) {
            canClickNext = true;
        }
    }


    if (prevTimelineEvent && endTimelineEvent) {
        if (prevTimelineEvent.name === TRANSITION_STRING
            && prevTimelineEvent.date === ''
            && endTimelineEvent.date !== '') {
            canClickPrev = false;
        }
    }

    return (
        <Row className="mx-0 p-2">
            <Col xs={12} lg={4} className="d-flex flex-row justify-content-start">
                <div
                    onClick={() => {
                        history.push(`/record-locator?sub=trackingTimeline`);
                        if (history.location.search === `?sub=trackingTimeline`) {
                            handleClearActiveEvent();
                        }
                    }}
                    className="btn-link cursor-pointer pt-1"
                >
                    <FontAwesomeIcon icon={faBackward} size="sm" className="mt-1 pr-1"/>
                    Back to Timeline</div>
            </Col>
            <Col xs={12} lg={4} className="d-flex flex-row justify-content-center">
                {nextPrev.prev && (
                    <div
                        onClick={() => {
                            if (prevTimelineEvent) {
                                history.push(`/record-locator?sub=trackingTimeline&event=${prevTimelineEvent.displayUUID}`);
                                if (history.location.search === `?sub=trackingTimeline&event=${prevTimelineEvent.displayUUID}`) {
                                    setClickedPrevNext('prev');
                                    if (!canClickPrev) {
                                        setClickedTimelineEvent(prevTimelineEvent);
                                        setShowTimelineErrorConfirmationModal(true);
                                    } else {
                                        handleSetActiveTimelineEvent(prevTimelineEvent);
                                    }
                                }
                            }
                        }}
                        className="btn-link cursor-pointer pt-1 pr-2"
                    >Prev</div>
                )} &nbsp;
                <h3 className="event-title">{eventName}</h3>
                {nextPrev.next && (
                    <div
                        onClick={() => {
                            if (nextTimelineEvent) {
                                history.push(`/record-locator?sub=trackingTimeline&event=${nextTimelineEvent.displayUUID}`);
                                if (history.location.search === `?sub=trackingTimeline&event=${nextTimelineEvent.displayUUID}`) {
                                    setClickedPrevNext('next');
                                    if (!canClickNext) {
                                        setClickedTimelineEvent(nextTimelineEvent);
                                        setShowTimelineErrorConfirmationModal(true);
                                    } else {
                                        handleSetActiveTimelineEvent(nextTimelineEvent);
                                    }
                                }
                            }
                        }}
                        className="btn-link cursor-pointer pt-1 pl-2"
                    >Next</div>
                )} &nbsp;
            </Col>
            <Col xs={12} lg={4} className="d-flex flex-row justify-content-start justify-content-lg-end">
                <FormControls
                    formIsModified={formIsModified}
                    loading={loading}
                    submitForm={submitForm}
                    showSave={canSave}
                    submitType={'save'}
                    disableAutoSave={false}
                    isValid={isValid}
                />
            </Col>
            <Col xs={12}><hr /></Col>
            <TimelineErrorConfirmationModal
                show={showTimelineErrorConfirmationModal}
                handleClose={() => { setShowTimelineErrorConfirmationModal(false); }}
                handleConfirm={async () => {
                    if (clickedTimelineEvent) {
                        setClickedTimelineEvent(null);
                        setShowTimelineErrorConfirmationModal(false);
                        await handleSetActiveTimelineEvent(clickedTimelineEvent);
                    }
                }}
                message={getTimelineNavigationError[clickedPrevNext === 'prev'
                    ? (timelineEvent?.name ? timelineEvent?.name.toString() : '')
                    : (clickedTimelineEvent?.name ? clickedTimelineEvent?.name.toString() : '')]}
                confirmText={`Continue to ${clickedTimelineEvent?.name} event`}
            />
        </Row>
    );
};

export default (TimelineEventControls);