import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../../Fields/StandardField";
import * as yup from 'yup';
import {List} from "../../../../pages/RecordLocator";
import {getOptionsForField, yupDateRegex} from "../../../../utils/formHelpers";
import {BaseFormValues} from "./SCEventForm";

export const REFERRAL_OUT_STRING = 'Referral Out';
export type REFERRAL_OUT = typeof REFERRAL_OUT_STRING;

export type ReferralOutValues = BaseFormValues & {
    ReferralOutDate : string;
    ReferralOutReason : string;
    ReferralOutRefTo: string;
};

export const ReferralOutSchema = {
    ReferralOutReason: yup.string(),
    ReferralOutRefTo: yup.string(),
    ReferralOutDate: yupDateRegex.required('You must enter a date')
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
};

const ReferralOutForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="ReferralOutDate"
                        type="date"
                        label="Referral Out Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="ReferralOutRefTo"
                        type="select"
                        label="Referred To"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderList',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="ReferralOutReason"
                        type="text"
                        label="Reason"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="ReferralOutResponsibleFullName"
                        type="select"
                        label="Who is Responsible"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
        </>
    );
};

export default ReferralOutForm;