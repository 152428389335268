import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {apiEndpoint, useApiFetch} from "./OidcProvider";
import {List} from "../../pages/RecordLocator";
import {StandardFieldOption} from "../Forms/Fields/StandardField";

export type ChrisUser = {
    siteUUID : string;
    name : string;
    isDataFacilitator: boolean;
    isCosUser: boolean;
    childSiteUUID: Array<StandardFieldOption> | undefined;
    sites: Array<string>,
    districts: Array<string>,
};

type ChrisUserState = {
    loading : boolean;
    error : boolean;
    chrisUser : ChrisUser;
}

type Props = {
    children ?: ReactNode;
};

export const mapRawLists = (rawList : any) : List => ({
    ...rawList
});

export const ChrisUserProviderContext = React.createContext<
    [ChrisUserState, List[]]
>(
    [{
        loading: true,
        error: false,
        chrisUser : {siteUUID : '', name : '', isDataFacilitator: false, childSiteUUID: undefined, sites: [], districts: [], isCosUser: false}
    }, []]
);

const chrisUserReducer = (state : ChrisUserState, action : any) => {
    switch (action.type) {
        case 'LOADING': {
            return {...state, loading : true, error : false}
        }
        case 'SET_CHRIS_USER': {
            return {...state, loading : true, error : false, chrisUser: action.chrisUser}
        }
        case 'LOADED': {
            return {...state, loading : false, error : false}
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

const mapRawChrisUser = (rawChrisUser : any) : ChrisUser => {
  return {...rawChrisUser}
};

const ChrisUserProvider : React.FC<Props> = ({children} : Props) => {
    const [chrisUserState, setChrisUserState] = React.useReducer(chrisUserReducer, {
        loading: true,
        error: false,
        chrisUser : {siteUUID : '', name : ''}
    });
    const [siteLists, setSiteLists] = useState<List[]>([]);
    const apiFetch = useApiFetch();

    const loadSiteLists = useCallback(async (siteUUID : string) => {
        const url = new URL(`/v1/lists/non-static/${siteUUID}`, apiEndpoint);
        const response = await apiFetch(url.toString());
        const data = await response.json();

        setSiteLists(data.map(mapRawLists));
    }, [setSiteLists, apiFetch]);

    const loadChrisUser = useCallback(async () => {
        const url = new URL(`/v1/user`, apiEndpoint);
        const response = await apiFetch(url.toString());
        const data = await response.json();
        const chrisUser = mapRawChrisUser(data);
        setChrisUserState({type: 'LOADING'});
        setChrisUserState({
            type: 'SET_CHRIS_USER',
            chrisUser: chrisUser
        });

        if (chrisUser.siteUUID && !chrisUser.isCosUser) {
            await loadSiteLists(chrisUser.siteUUID);
        }

        setChrisUserState({type: 'LOADED'});
    }, [setChrisUserState, apiFetch, loadSiteLists]);

    useEffect( () => {
        if (chrisUserState.chrisUser.siteUUID === '' && chrisUserState.chrisUser.name === '') {
            loadChrisUser();
        }
    }, [chrisUserState.chrisUser.siteUUID]);

    return (
        <ChrisUserProviderContext.Provider value={[chrisUserState, siteLists]}>
            {children}
        </ChrisUserProviderContext.Provider>
    );
};

export default ChrisUserProvider;

