import React, {useEffect} from 'react';
import {userManager} from '../utils/UserManager';
import FullPageLoadingIndicator from "../components/FullPageLoadingIndicator";
import {useApiFetch} from "../components/Providers/OidcProvider";
import {apiEndpoint} from "../utils/api";

const SignOut = () => {
    const apiFetch = useApiFetch();

    useEffect( () => {
        const signOut = async () => {
            try {
                const url = new URL(`/v1/user/logout`, apiEndpoint);
                await apiFetch(url.toString());
            } catch (e) {
                console.error('error logging logout', e);
            }

            await userManager.signoutRedirect();
        }

        signOut().catch(e => userManager.signoutRedirect());
    }, [apiFetch]);

    return (
        <FullPageLoadingIndicator/>
    );
};

export default SignOut;
