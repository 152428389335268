import React, {useState} from "react";
import ModalBase from "./ModalBase";
import {Button, Col, Row} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

type Props = {
    show : boolean;
    handleClose : () => void;
    handleYes : () => void;
    handleCancel? : () => void;
    message: string;
};

const ConfirmDeleteEventModal = ({
    show,
    handleCancel,
    handleClose,
    handleYes,
    message
} : Props) => {
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <ModalBase
            show={show}
            title=""
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleClose}
        >
            <Row className="chris-form-bg mx-0 py-3 px-2">
                <Col xs={12}>{message}</Col>
            </Row>
            <Col xs={12}><hr /></Col>
            <Row className="mx-0 mt-2">
                <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                    <Button
                        variant="outline-secondary"
                        type="button"
                        className="d-flex pt-2 flex-row align-content-center mr-2"
                        onClick={async () => {
                            handleCancel ? handleCancel() : handleClose();
                        }}
                    >Cancel</Button>
                    <Button
                        variant="secondary"
                        type="button"
                        className="d-flex pt-2 flex-row align-content-center"
                        onClick={async () => {
                            setLoading(true);
                            await handleYes();
                        }}
                    >{loading ? <React.Fragment>
                        <Spinner animation="border" size="sm" className="mt-1"/>
                        &nbsp; Removing
                    </React.Fragment> : 'Yes, Delete Event'}</Button>
                </Col>
            </Row>
        </ModalBase>);
}

export default ConfirmDeleteEventModal;