import {User, UserManager} from 'oidc-client-ts';
import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import FullPageLoadingIndicator from '../FullPageLoadingIndicator';

type Props = {
    userManager : UserManager;
    children ?: ReactNode;
};

export type OidcUser = {
    accessToken : string;
    sub : string;
    name ?: string;
};

const oidcContext = createContext<OidcUser | null>(null);

const OidcProvider : React.FC<Props> = ({userManager, children} : Props) => {
    const [user, setUser] = useState<OidcUser | null>(null);

    useEffect(() => {
        let currentUser : OidcUser | null = null;

        const replaceUser = (user : User | null) => {
            if (user === null) {
                return setUser(currentUser = null);
            }

            setUser(currentUser = {
                accessToken: user.access_token,
                sub: user.profile.sub,
                name: user.profile.name ?? user.profile.email,
            });
        };

        const performSignInRedirect = async () => {
            await userManager.signinRedirect({});
        };

        const performSignIn = async () => {
            await userManager.clearStaleState();

            try {
                await userManager.signinSilent();
            } catch (e) {
                await performSignInRedirect();
            }
        };

        const handleUserLoaded = (user : User) => {
            if (currentUser && currentUser.sub === user.profile.sub) {
                // User did not change, so no need to change the object reference, avoids useless re-renders.
                //
                // This is a performance optimization, and if your components require to listen for a change of a
                // specific attribute, you should list those in the dependencies of your hooks instead.
                currentUser.accessToken = user.access_token;
                return;
            }

            replaceUser(user);
        };

        const handleUserUnloaded = () => {
            replaceUser(null);
        };

        const handleAccessTokenExpired = async () => {
            replaceUser(null);
            await performSignIn();
        };

        const handleSilentRenewError = async () => {
            await performSignInRedirect();
        };

        const handleUserSignedOut = async () => {
            await performSignInRedirect();
        };

        const handleUserSessionChanged = async () => {
            replaceUser(null);
            await performSignIn();
        };

        userManager.events.addUserLoaded(handleUserLoaded);
        userManager.events.addUserUnloaded(handleUserUnloaded);
        userManager.events.addAccessTokenExpired(handleAccessTokenExpired);
        userManager.events.addSilentRenewError(handleSilentRenewError);
        userManager.events.addUserSignedOut(handleUserSignedOut);
        userManager.events.addUserSessionChanged(handleUserSessionChanged);

        (async () => {
            const user = await userManager.getUser();

            if (user !== null && !user.expired) {
                return replaceUser(user);
            }

            return performSignIn();
        })();

        return () => {
            userManager.events.removeUserLoaded(handleUserLoaded);
            userManager.events.removeUserUnloaded(handleUserUnloaded);
            userManager.events.removeAccessTokenExpired(handleAccessTokenExpired);
            userManager.events.removeSilentRenewError(handleSilentRenewError);
            userManager.events.removeUserSignedOut(handleUserSignedOut);
            userManager.events.removeUserSessionChanged(handleUserSessionChanged);
        };
    }, [userManager, setUser]);

    if (!user) {
        return <FullPageLoadingIndicator/>;
    }

    return (
        <oidcContext.Provider value={user}>
            {children}
        </oidcContext.Provider>
    );
};

export const useUser = () : OidcUser => {
    const user = useContext(oidcContext);

    if (!user) {
        throw new Error('Context was used before initial load');
    }

    return user;
};

if (!process.env.REACT_APP_API_ENDPOINT) {
    throw new Error('REACT_APP_API_ENDPOINT not defined');
}

export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
type ApiFetch = (url : string, init ?: RequestInit) => Promise<Response>;

export const useApiFetch = () : ApiFetch => {
    const user = useUser();

    return useCallback(async (url : string, init ?: RequestInit) : Promise<Response> => {
        if (!init) {
            init = {};
        }

        init.headers = init.headers instanceof Headers ? init.headers : new Headers(init.headers);
        init.headers.append('Authorization', `Bearer ${user.accessToken}`);
        init.headers.append('Content-Type', 'application/json');

        return fetch(url, init);
    }, [user]);
};

export default OidcProvider;
