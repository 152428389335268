import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../../Fields/StandardField";
import * as yup from 'yup';
import {List} from "../../../../pages/RecordLocator";
import {getOptionsForField, yupDateRegex} from "../../../../utils/formHelpers";
import {BaseFormValues} from "./SCEventForm";

export const ON_HOLD_STRING = 'On Hold';
export type ON_HOLD = typeof ON_HOLD_STRING;

export type OnHoldValues = BaseFormValues & {
    OnHoldDate : string;
    OnHoldCompletedDate : string;
    OnHoldReason : string;
    OnHoldComments : string;
};

export const OnHoldSchema = {
    OnHoldReason: yup.string(),
    OnHoldComments: yup.string(),
    OnHoldDate: yupDateRegex.required('You must enter a date'),
    OnHoldCompletedDate: yupDateRegex
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
};

const OnHoldForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-2">
                <Col xs={6}>
                    <StandardField
                        name="OnHoldDate"
                        type="date"
                        label="On Hold Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={6}>
                    <StandardField
                        name="OnHoldCompletedDate"
                        type="date"
                        label="Completed Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="OnHoldReason"
                        type="select"
                        label="Reason"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'OnHoldList',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="OnHoldComments"
                        type="textarea"
                        label="Notes"
                        className="textarea-lg"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
        </>
    );
};

export default OnHoldForm;