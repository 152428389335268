import React, {useCallback, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import NavHeader from "../components/Navigation/NavHeader";
import ActiveUserNav from "../components/Navigation/ActiveUserNav";
import {ChrisUserProviderContext} from "../components/Providers/ChrisUserProvider";
import {StandardFieldOption} from "../components/Forms/Fields/StandardField";
import Form from "react-bootstrap/Form";
import {apiEndpoint} from "../utils/api";
import {useApiFetch} from "../components/Providers/OidcProvider";
import CircularLoadingIndicator from "../components/CircularLoadingIndicator";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import DataFacilitatorOptionToggle from "../components/DataFacilitator/DataFacilitatorOptionToggle";
import AddDataFacilitatorListOptionForm from "../components/Forms/DataFacilitator/AddDataFacilitatorListOptionForm";
import {FormikValues} from "formik";
import { v4 as uuidv4 } from 'uuid';
import {ActiveChildProviderContext} from "../components/Providers/ActiveChildProvider";
import ActiveChildNav from "../components/Navigation/ActiveChildNav";
import GeneralErrorModal from "../components/Modal/GeneralErrorModal";

export type FacilitatorListOption = {
    UUID: string;
    value: string;
    active: number;
};

export type DisplayFacilitatorListOption = {
    value: string;
    active: React.ReactElement;
};

const mapRawFacilitatorList = (values : any) : FacilitatorListOption => ({
    ...values,
});

const ManageLists = () => {
    const [chrisUserState] = React.useContext(ChrisUserProviderContext);
    const [activeListOptions, setActiveListOptions] = useState<Array<FacilitatorListOption>>([]);
    const [displayActiveListOptions, setDisplayActiveListOptions] = useState<Array<DisplayFacilitatorListOption>>([]);
    const [activeList, setActiveList] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [activeChild, loadActiveChild] = React.useContext(ActiveChildProviderContext);
    const [showGeneralErrorModal, setShowGeneralErrorModal] = useState<boolean>(false);

    const apiFetch  = useApiFetch();

    const lists : Array<StandardFieldOption> = [
        {label: 'Cities List', value: 'Cities List', checked: false},
        {label: 'Code List', value: 'Code List', checked: false},
        {label: 'Contact Log List', value: 'Contact Log List', checked: false},
        {label: 'HomeZoneSchool List', value: 'HomeZoneSchool List', checked: false},
        {label: 'Language List', value: 'Language List', checked: false},
        {label: 'Location List', value: 'Location List', checked: false},
        {label: 'On Hold List', value: 'On Hold List', checked: false},
        {label: 'Sub Location List', value: 'Sub Location List', checked: false},
        {label: 'AppointmentTypeList', value: 'AppointmentTypeList', checked: false}
    ];

    const columns = [
        {
            dataField: 'UUID',
            hidden: true,
            sort: false,
            text: 'UUID'
        },
        {
            dataField: 'value',
            text: 'Value',
            sort: true
        },
        {
            dataField: 'active',
            text: 'Active',
            sort: false
        }
    ];

    const handleUpdateListOption = useCallback(async (
        value: string, active: number, activeListOptions: Array<FacilitatorListOption>, activeList: string
    ) => {
        const url = new URL(
            `/v1/lists/data-facilitator/${activeList}/${value}/${active}`,
            apiEndpoint
        );
        const response = await apiFetch(url.toString(), {
            method: 'PUT'
        });

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const optionIndex = activeListOptions.findIndex(
            (listOption) => listOption.value === value
        );
        activeListOptions[optionIndex].active = active;
        setActiveListOptions(activeListOptions);
    }, [apiFetch, setLoading, setActiveListOptions, activeList]);

    const handleSetActiveList = useCallback(async (list : string) => {
        setActiveList(list);
        setLoading(true);

        const url = new URL(`/v1/lists/data-facilitator/${list}`, apiEndpoint);
        const response = await apiFetch(url.toString());

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const data = await response.json();

        const activeListOptions = data.map(mapRawFacilitatorList);
        setActiveListOptions(activeListOptions);
        setDisplayActiveListOptions(activeListOptions.map((activeListOption : FacilitatorListOption) => ({
                ...activeListOption,
                active: <DataFacilitatorOptionToggle
                    activeListOption={activeListOption}
                    handleUpdateListOption={handleUpdateListOption}
                    activeListOptions={activeListOptions}
                    activeList={list}
                />
            }
        )));
        setLoading(false);
    }, [apiFetch, setLoading, setActiveListOptions, setActiveList, handleUpdateListOption]);

    const handleAddNewListOption = useCallback(async (values : FormikValues) => {
        const url = new URL(
            `/v1/lists/data-facilitator/${activeList}/${values.optionValue}/1`,
            apiEndpoint
        );
        const response = await apiFetch(url.toString(), {
            method: 'PUT'
        });

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        activeListOptions.push({value: values.optionValue, active: 1, UUID: uuidv4()});
        setActiveListOptions(activeListOptions);
        setDisplayActiveListOptions(activeListOptions.map((activeListOption : FacilitatorListOption) => ({
                ...activeListOption,
                active: <DataFacilitatorOptionToggle
                    activeListOption={activeListOption}
                    handleUpdateListOption={handleUpdateListOption}
                    activeListOptions={activeListOptions}
                    activeList={activeList}
                />
            }
        )));
    }, [apiFetch, setLoading, activeListOptions, handleUpdateListOption, activeList]);

    React.useEffect(() => {
        loadActiveChild();
    }, []);

    return (
        <>
            <NavHeader />
            <ActiveUserNav chrisUser={chrisUserState.chrisUser}>
                {activeChild && activeChild.activeChild ? (
                    <ActiveChildNav
                        activeChild={activeChild.activeChild}
                    />
                ) : (<></>)}
            </ActiveUserNav>
            <Container>
                {chrisUserState.loading ? (
                    <CircularLoadingIndicator/>
                ) : (
                    <Row>
                        <Col xs={4} className="d-flex flex-column align-items-start mt-4">
                            <label>Available Lists</label>
                            <Form.Control
                                type="select"
                                as="select"
                                name="providerLists"
                                value={activeList}
                                onChange={async (e) => {
                                    await handleSetActiveList(e.currentTarget.value);
                                }}
                                disabled={false}
                            >
                                <option value=""> -- Select --</option>
                                {lists.map((option : StandardFieldOption, index) => {
                                    return <option
                                        value={option.value}
                                        key={index}
                                    >{option.label}</option>;
                                })}
                            </Form.Control>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col xs={12} className="d-flex flex-column align-items-start mt-2">
                        {loading && (
                            <CircularLoadingIndicator/>
                        )}
                        {!loading && activeListOptions && activeListOptions.length > 0 && displayActiveListOptions && (
                            <>
                                <AddDataFacilitatorListOptionForm handleSubmit={handleAddNewListOption}/>
                                <BootstrapTable
                                    bootstrap4
                                    keyField='UUID'
                                    data={displayActiveListOptions}
                                    columns={columns}
                                    pagination={ paginationFactory({sizePerPage: 50}) }
                                    striped
                                    defaultSorted={[
                                        {
                                            dataField: "value",
                                            order: "asc"
                                        }
                                    ]}
                                />
                            </>
                        )}
                    </Col>
                </Row>
                <GeneralErrorModal
                    show={showGeneralErrorModal}
                    handleShowGeneralErrorModal={setShowGeneralErrorModal}
                />
            </Container>
        </>
    );
};

export default ManageLists;
