import React from 'react';
import {Alert, Button, Collapse} from 'react-bootstrap';

type Props = {
    show : boolean;
    alertText : string | null;
    setShowSystemAlert : (showSystemAlert : boolean) => void;
    timeout? : number;
    variant? : any;
};

const SystemAlert = ({
    show,
    alertText,
    timeout,
    variant,
    setShowSystemAlert
} : Props) => {
    return (
        <Collapse in={show} timeout={timeout ? timeout : 1000}>
            <div className="fixed-top">
                <Alert variant={variant ? variant : 'success'} className="rounded-0">
                    <Button
                        type="button"
                        className="close btn-success"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => setShowSystemAlert(false)}
                    >
                        <span aria-hidden="true">&times;</span>
                    </Button>
                    <span className="justify-content-center d-flex">
                        {alertText}
                    </span>
                </Alert>
            </div>
        </Collapse>
    );
}

export default (SystemAlert);