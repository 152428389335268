import React, {useState} from 'react';
import ModalBase from './ModalBase';
import {Button, Col, Row} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

type Props = {
    show : boolean;
    handleShowModal : (show : boolean) => void;
    handleAddTimeline : () => Promise<void>;
};

const AddTimelineConfirmModal = ({show, handleShowModal, handleAddTimeline} : Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const handleCloseModal = () => {
        handleShowModal(false);
    };

    return (
        <ModalBase
            show={show}
            title=""
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleCloseModal}
        >
            <Row className="chris-form-bg mx-0 py-3 px-2">
                <Col xs={12}>You are about to enter a new set of timeline events for this child. Would you like to proceed?</Col>
            </Row>
            <Col xs={12}><hr /></Col>
            <Row className="mx-0 mt-2">
                <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                    <Button
                        variant="outline-secondary"
                        type="button"
                        className="d-flex pt-2 flex-row align-content-center mr-2"
                        onClick={async () => {
                            handleCloseModal();
                        }}
                    >Cancel</Button>
                    <Button
                        variant="secondary"
                        type="button"
                        className="d-flex pt-2 flex-row align-content-center"
                        onClick={async () => {
                            setLoading(true);
                            await handleAddTimeline();
                        }}
                    >{loading ? <>
                        <Spinner animation="border" size="sm" className="mt-1"/>
                        &nbsp; Adding Timeline
                    </> : 'Yes, Add Timeline'}</Button>
                </Col>
            </Row>
        </ModalBase>
    );
};

export default AddTimelineConfirmModal;