import React, {useCallback} from 'react';
import {Form, Formik, FormikValues} from "formik";
import ModalBase from './ModalBase';
import FormsSelect from "../../images/formsSelect.png";
import {Child, List} from "../../pages/RecordLocator";
import {getOptionsForField} from "../../utils/formHelpers";
import StandardField from "../Forms/Fields/StandardField";
import * as yup from "yup";
import {useApiFetch} from "../Providers/OidcProvider";
import {apiEndpoint} from "../../utils/api";
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faPeopleArrows} from "@fortawesome/free-solid-svg-icons";
import FormControls from "../Forms/Fields/FormControls";
import {ActiveChildProviderContext} from "../Providers/ActiveChildProvider";

type Props = {
    setShowTransferModel : (show : boolean) => void;
    handleClearActiveChild?: () => void;
    show : boolean;
    transferChild : Child;
    siteLists : List[];
};

const schema = yup.object({
    countyOfResidence: yup.string().required('You must enter a Transfer To County.'),
    transferType: yup.string().required('You must enter a Transfer type.'),
    acknowledge: yup.string().required('You must check that you have read the reminder.'),
});

export const FacilitatorRecordTransferModal = (
    {show, setShowTransferModel, transferChild, siteLists, handleClearActiveChild} : Props
) => {
    const apiFetch = useApiFetch();
    const [,, clearActiveChild] = React.useContext(ActiveChildProviderContext);

    const handleSetFormIsModified = async (formIsModified : boolean) => {
    }
    
    const transferStudent = useCallback(async (values: FormikValues) => {
        const url = new URL(
            `/v1/child/transfer/${transferChild.childUUID}`,
            apiEndpoint
        );
        await apiFetch(
            url.toString(),
            {
                method: 'PUT',
                body: JSON.stringify({
                    transfertoCounty: values.countyOfResidence,
                    transferType: values.transferType,
                })
        });
        await clearActiveChild();
        handleClearActiveChild && handleClearActiveChild();
    }, [apiFetch, clearActiveChild, handleClearActiveChild, transferChild.childUUID]);

    return (
        <ModalBase
            show={show}
            title=""
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={() => setShowTransferModel(false)}
        >
            <div className="d-flex flex-column align-items-center">
                <img
                    alt="Record Transfer Request"
                    src={FormsSelect}
                    width={240}
                    className="d-inline-block align-top"
                />
                <h2 className="mt-2 mb-3">Record Transfer Request</h2>
            </div>
            <div>
                <Row className="mx-0 mt-2">
                    <Col xs={12}>
                        Changing the County Of Residence to one not in your service area will move the child's record
                        to that county's database and you will no longer have access to edit the record.
                    </Col>
                </Row>
                <Formik
                    initialValues={{
                    }}
                    validationSchema={schema}
                    enableReinitialize={true}
                    onSubmit={async (values, formHelpers) => {
                        await transferStudent(values);
                    }}
                >
                    {(props) => {
                        return <Form>
                            <Row className="mx-0 mt-2">
                                <Col xs={12}>
                                    <StandardField
                                        name="acknowledge"
                                        type="checkbox"
                                        label=""
                                        disabled={false}
                                        handleSetFormIsModified={handleSetFormIsModified}
                                        options={[{
                                            label: 'Check here when you have read the above reminder',
                                            value: 'ready',
                                            checked: false,
                                        }]}
                                    />
                                </Col>
                            </Row>
                            <Row className="mx-0 mt-2">
                                <Col xs={12} sm={6}>
                                    <StandardField
                                        name="transferType"
                                        type="select"
                                        label="Transfer Type:"
                                        disabled={false}
                                        handleSetFormIsModified={handleSetFormIsModified}
                                        options={[{
                                            label: 'Residence County',
                                            value: 'COR',
                                            checked: false,
                                        },
                                            {
                                                label: 'ServiceCounty',
                                                value: 'ServiceCounty',
                                                checked: false,
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <StandardField
                                        name="countyOfResidence"
                                        type="select"
                                        label="Transfer To County:"
                                        disabled={false}
                                        handleSetFormIsModified={handleSetFormIsModified}
                                        options={getOptionsForField(
                                            'AllCounties',
                                            '',
                                            siteLists
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row className="mx-0 mt-2">
                                <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                                    <Button
                                        className="d-flex pt-2 flex-row align-content-center mr-2"
                                        variant="outline-secondary"
                                        onClick={() => setShowTransferModel(false)}
                                    ><FontAwesomeIcon icon={faBan} size="sm" className="mt-1"/>
                                        &nbsp; Cancel</Button>
                                    <FormControls
                                        formIsModified={true}
                                        loading={false}
                                        submitForm={props.submitForm}
                                        showSave={true}
                                        submitType={'save'}
                                        disableAutoSave={true}
                                        isValid={props.isValid}
                                        saveIcon={faPeopleArrows}
                                        saveText="Transfer Child"
                                        saveTextPast="Transfer Child"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    }}
                </Formik>
            </div>
        </ModalBase>
    );
};