import React, {ChangeEvent} from 'react';
import Form from 'react-bootstrap/Form';
import {FormikValues, useFormikContext} from 'formik';
import {Col} from 'react-bootstrap';
import {FormikContextType} from "formik/dist/types";
import Datetime from "react-datetime";
import moment, {isMoment} from "moment";

export type StandardFieldOption = {
    label : string;
    value : string;
    checked : boolean;
};

type Props = {
    name : string;
    type : string;
    label : string;
    options? : Array<StandardFieldOption>;
    disabled? : boolean;
    handleSetFormIsModified : (formIsModified: boolean) => Promise<void>;
    arrayName? : string;
    iteration? : number;
    className? : string;
    onChange? : (e: ChangeEvent<any> | string) => void;
    fieldLabelClass? : string;
    hideCheckboxDescription? : boolean;
    errorNoticeOverride? : string;
    autoFocus? : boolean;
};

const StandardField = ({
    name,
    type,
    label,
    options,
    disabled,
    handleSetFormIsModified,
    arrayName,
    iteration,
    className,
    onChange,
    fieldLabelClass,
    hideCheckboxDescription,
    errorNoticeOverride,
    autoFocus
} : Props) => {
    const { values, handleChange, errors, setFieldValue } : FormikContextType<FormikValues> = useFormikContext();
    const fieldName = arrayName && iteration !== undefined ? `${arrayName}[${iteration}].${name}` : name;
    const fieldValue = arrayName && iteration !== undefined ? values[arrayName][iteration][name] : values[name];
    // @ts-ignore
    const errorsName = arrayName && iteration !== undefined && errors[arrayName] && errors[arrayName][iteration] ? errors[arrayName][iteration][name] : errors[fieldName];

    return (
        <Form.Group>
            <Form.Row>
                {(type === 'text' || type === 'datez' || type === 'time') && (
                    <>
                        {label && (
                            <Form.Label column={false} className={fieldLabelClass}>{label}&nbsp;</Form.Label>
                        )}
                        <Form.Control
                            type={type}
                            name={fieldName}
                            value={fieldValue}
                            onChange={async (e) => {
                                handleChange(e);
                                onChange && onChange(e);
                                await handleSetFormIsModified(true);
                            }}
                            isInvalid={!!errorsName || !!errorNoticeOverride}
                            disabled={disabled}
                            className={className ? className : ''}
                            autoFocus={autoFocus}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errorsName ? errorsName : (errorNoticeOverride ? errorNoticeOverride : '')}
                        </Form.Control.Feedback>
                    </>
                )}
                {type === 'date' && (
                    <>
                        {label && (
                            <Form.Label column={false} className={fieldLabelClass}>{label}&nbsp;</Form.Label>
                        )}
                        <Datetime
                            onChange={async (date) => {
                                setFieldValue(
                                    fieldName,
                                    isMoment(date) ? date.format('MM/DD/YYYY') : date,
                                    true
                                );
                                onChange && onChange(isMoment(date) ? date.format('MM/DD/YYYY') : date);
                                await handleSetFormIsModified(true);
                            }}
                            className={`w-100 ${!!errorsName || !!errorNoticeOverride ? 'date-input-error' : ''}`}
                            initialValue={fieldValue !== '' && fieldValue !== '?' ? moment(fieldValue).format('MM/DD/YYYY') : ''}
                            timeFormat={false}
                            input={true}
                            dateFormat="MM/DD/YYYY"
                            inputProps={{
                                placeholder: 'MM/DD/YYYY',
                                disabled: disabled,
                                className : !!errorsName || !!errorNoticeOverride ? 'form-control is-invalid' : 'form-control',
                                pattern : "\\d{2}/\\d{2}/\\d{4}"
                            }}
                            renderInput={(props) => {
                                return <input {...props} value={(fieldValue) ? props.value : ''} />
                            }}
                        />
                        {!!errorsName || !!errorNoticeOverride ? (
                            <div className="invalid-feedback d-block">
                                {errorsName ? errorsName : (errorNoticeOverride ? errorNoticeOverride : '')}
                            </div>
                        ) : <></>}
                    </>
                )}
                {(type === 'textarea') && (
                    <>
                        {label && (
                            <Form.Label column={false} className={fieldLabelClass}>{label}</Form.Label>
                        )}
                        <Form.Control
                            as={type}
                            name={fieldName}
                            value={fieldValue}
                            onChange={async (e) => {
                                handleChange(e);
                                onChange && onChange(e);
                                await handleSetFormIsModified(true);
                            }}
                            isInvalid={!!errorsName || !!errorNoticeOverride}
                            disabled={disabled}
                            className={className ? className : ''}
                            autoFocus={autoFocus}
                        />
                    </>
                )}
                {(type === 'checkbox' || type === 'radio') && (
                    <>
                        {label && (
                            <Col xs={12} className={fieldLabelClass}>
                                <Form.Label column={false}>{label}</Form.Label>
                                {(!!errorsName || !!errorNoticeOverride) && (
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {errorsName ? errorsName : (errorNoticeOverride ? errorNoticeOverride : '')}
                                </Form.Control.Feedback>
                                )}
                            </Col>
                        )}
                        {options && options.map((option : StandardFieldOption, index) => {
                            if (fieldValue && fieldValue.includes(null)) {
                                const index = fieldValue.indexOf(null);
                                fieldValue.splice(index);
                            }

                            if (fieldValue && fieldValue.includes(null)) {
                                const index = fieldValue.indexOf('');
                                fieldValue.splice(index);
                            }

                            return (
                                <React.Fragment key={index}>
                                    <Col xs={12} lg={options.length <= 3 ? 12 : 4}>
                                        {type === 'checkbox' && (
                                            <Form.Check
                                                className="mb-2"
                                                type={type}
                                                name={fieldName}
                                                label={!hideCheckboxDescription ? option.label : false}
                                                value={option.value}
                                                onChange={async (e : any) => {
                                                    const value = e.target.checked ? option.value : null;
                                                    let newFieldValues = fieldValue;
                                                    if (Array.isArray(newFieldValues)) {
                                                        if (value === null && newFieldValues !== null) {
                                                            newFieldValues = newFieldValues.filter(
                                                                (newValue: string) => newValue !== option.value
                                                            );
                                                        } else {
                                                            newFieldValues.push(value);
                                                        }
                                                        setFieldValue(`${fieldName}.${index}`, value);
                                                        setFieldValue(fieldName, newFieldValues);
                                                    } else {
                                                        setFieldValue(`${fieldName}.${index}`, value);
                                                    }
                                                    onChange && onChange(e);
                                                    await handleSetFormIsModified(true);
                                                }}
                                                checked={fieldValue && fieldValue.includes(option.value)}
                                                isInvalid={!!errorsName || !!errorNoticeOverride}
                                                id={`${fieldName}-${index}`}
                                                disabled={disabled}
                                                autoFocus={autoFocus}
                                            />
                                        )}
                                        {type === 'radio' && (
                                            <Form.Check
                                                className="mb-2"
                                                type={type}
                                                name={fieldName}
                                                label={option.label}
                                                value={option.value}
                                                onClick={async (e : any) => {
                                                    const value = e.target.checked ? option.value : null;
                                                    setFieldValue(`${fieldName}`, value);
                                                    await handleSetFormIsModified(true);
                                                    onChange && onChange(value ? value.toString() : '');
                                                }}
                                                defaultChecked={fieldValue && fieldValue.includes(option.value)}
                                                id={`${fieldName}-${index}`}
                                                disabled={disabled}
                                                autoFocus={autoFocus}
                                            />
                                        )}
                                    </Col>
                                </React.Fragment>
                            );
                        })}
                    </>
                )}
                {(type === 'select') && (
                    <>
                        {label && (
                            <Form.Label column={false} className={fieldLabelClass}>{label}</Form.Label>
                        )}
                        <Form.Control
                            type={type}
                            as="select"
                            name={fieldName}
                            value={fieldValue}
                            onChange={async (e) => {
                                handleChange(e);
                                onChange && onChange(e);
                                await handleSetFormIsModified(true);
                            }}
                            isInvalid={!!errorsName || !!errorNoticeOverride}
                            disabled={disabled}
                            className={className ? className : ''}
                            autoFocus={autoFocus}
                        >
                            <option value=""> -- Select --</option>
                            {options && options.map((option : StandardFieldOption, index) => {
                                return <option
                                    value={option.value}
                                    key={index}
                                >{option.label}</option>;
                            })}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errorsName ? errorsName : (errorNoticeOverride ? errorNoticeOverride : '')}
                        </Form.Control.Feedback>
                    </>
                )}
            </Form.Row>
        </Form.Group>
    );
};

export default StandardField;
