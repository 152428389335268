import React from 'react';
import {ActiveTab, Child} from '../../pages/RecordLocator';
import {Button, Nav} from 'react-bootstrap';
import {faIdCard, faCalendar, faFileAlt, faPrint, faPeopleArrows} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import {SiteForm} from "../SiteForms/Forms";
import {FacilitatorRecordTransferModal} from "../Modal/FacilitatorRecordTransferModal";
import {ChrisUserProviderContext} from "../Providers/ChrisUserProvider";

type Props = {
    activeTab : ActiveTab;
    handleSetActiveTab : (activeTab : ActiveTab) => void;
    setActiveForm : (activeForm : SiteForm | null) => void;
    setShowPrintModal : (showPrintModal : boolean) => void;
    handleClearActiveChild?: () => void;
    activeChild: Child
}

const LocatorSubNav = ({activeTab, handleSetActiveTab, setActiveForm, setShowPrintModal, handleClearActiveChild,  activeChild} : Props) => {
    const [chrisUserState, userSiteLists] = React.useContext(ChrisUserProviderContext);
    let history = useHistory();
    const [showTransferModel, setShowTransferModel] = React.useState<boolean>(false);


    return (
        <div className="d-flex flex-row">
        <Nav variant="pills" onSelect={(eventKey: any) => {
            history.push(`/record-locator?sub=${eventKey}`);
            if (history.location.search === `?sub=${eventKey}`) {
                if (eventKey === 'forms') {
                    setActiveForm(null);
                }
                handleSetActiveTab(eventKey);
            }
        }} className="mb-3 w-100" activeKey={activeTab ?? 'demographics'}>
            <Nav.Item>
                <Nav.Link eventKey="demographics">
                    <FontAwesomeIcon icon={faIdCard} size={"lg"} /> &nbsp; Demographics
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="tracking">
                    <FontAwesomeIcon icon={faCalendar} size={"lg"} /> &nbsp; Tracking
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="forms">
                    <FontAwesomeIcon icon={faFileAlt} size={"lg"} /> &nbsp; Forms
                </Nav.Link>
            </Nav.Item>
            <div className="justify-content-end d-flex">
                <Button
                    variant={'secondary'}
                    type="submit"
                    className="d-flex pt-2 flex-row align-content-center pb-0"
                    disabled={false}
                    onClick={() => setShowPrintModal(true)}
                >
                    <React.Fragment>
                        <FontAwesomeIcon icon={faPrint} size="sm" className="mt-1"/>&nbsp; Print
                    </React.Fragment>
                </Button>
            </div>
            {chrisUserState.chrisUser.isDataFacilitator && (
                <>

                    <div
                        className="justify-content-end d-flex left-margin-thin"
                    >
                        <Button
                            variant={'secondary'}
                            type="submit"
                            className="d-flex pt-2 flex-row align-content-center pb-0"
                            disabled={false}
                            onClick={() => setShowTransferModel(true)}
                        >
                            <FontAwesomeIcon icon={faPeopleArrows} size="sm" className="mt-1"/>&nbsp; Transfer
                        </Button>
                    </div>
                    <FacilitatorRecordTransferModal
                        setShowTransferModel={setShowTransferModel}
                        show={showTransferModel}
                        transferChild={activeChild}
                        siteLists={userSiteLists}
                        handleClearActiveChild={handleClearActiveChild}
                    />
                </>
            )}
        </Nav>
        </div>
    );
};

export default LocatorSubNav;
