import React, {useEffect} from 'react';
import {userManager} from '../../utils/UserManager';

const SilentCallback : React.FC = () => {
    useEffect(() => {
        userManager.signinSilentCallback();
    }, []);

    return null;
};

export default SilentCallback;
