import React, {useCallback, useState} from 'react';
import {Form, Formik, FormikValues} from "formik";
import {NameToValueMap} from "../../FormBuilder";
import * as yup from 'yup';
import {Child, List} from "../../../../pages/RecordLocator";
import ModifiedPrompt from "../../ModifiedPrompt";
import {TimelineEvent, TimelineEventValues} from "../../../Tracking/TimelineEvents";
import TransitionForm, {TRANSITION_STRING, TransitionSchema} from "./TransitionForm";
import ScreeningForm, {SCREENING_STRING, ScreeningSchema} from "./ScreeningForm";
import EvaluationForm, {EVALUATION_STRING, EvaluationSchema} from "./EvaluationForm";
import ESEEligibilityForm, {
    ESE_ELIGIBILITY_STRING,
    ESEEligibilitySchema,
    secondaryExceptionalityFields
} from "./ESEEligibilityForm";
import IEPIFSPForm, {IEP_IFSP_STRING, IEPIFSPSchema} from "./IEPIFSPForm";
import EndTimelineForm, {END_TIMELINE_STRING, EndTimelineSchema} from "./EndTimelineForm";
import TimelineEventSummary from "./TimelineEventSummary";
import TimelineEventFooter from "./EventFooter";
import TimelineEventControls, {getNextPrevEvent, NextPrev} from "./TimelineEventControls";
import moment from "moment";

export const mapRawTimelineEventValues = (values : any) : TimelineEventValues => ({
    ...values,
});

export const requiredFieldMsg : any = {
    'ScrReferralForScreeningDate' : 'You must enter a Referral For Screening Date.',
    'ScrFinalScreeningResultDate' : 'You must enter a Date of Final Result.',
    'ScrFinalScreeningResult' : 'You must enter a Final Screening Result.',
    'TrnPartCtoPartBReferralDate' : 'You must enter a Part C to Part B Transition Notification Date.',
    'TrnTransitionMeetingDate' : 'You must enter a ES Transition Conference Date.',
    'EvlReferralForEvaluationDate' : 'You must enter a Referral Date.',
    'EvlParentConsentForEval' : 'You must enter a Parent Consent Date.',
    'EvlFinalEvalDate' : 'You must enter a Final Evaluation Date.',
    'ESEEligibilityDate' : 'You must enter a ESE Eligibility Date.',
    'IEPIFSPDate' : 'You must enter a IEP/IFSP Date',
    'IEPIFSPParentConsentDate' : 'You must enter a Parent Consent for Placement Date',
    'ESEAutistic' : 'You have not entered a Primary Exceptionality, please do so prior to exit.',
    'ESEDeaforHardofHearing' : 'You have not entered a Primary Exceptionality, please do so prior to exit.',
    'ESEDevelopmentallyDelayed' : 'You have not entered a Primary Exceptionality, please do so prior to exit.',
    'ESEDualSensoryImpaired' : 'You have not entered a Primary Exceptionality, please do so prior to exit.',
    'ESEEmotionalBehavioralDisability' : 'You have not entered a Primary Exceptionality, please do so prior to exit.',
    'ESEEstablishedConditions' : 'You have not entered a Primary Exceptionality, please do so prior to exit.',
    'ESEHospitalHomebound' : 'You have not entered a Primary Exceptionality, please do so prior to exit.',
    'ESEIntellectualDisability' : 'You have not entered a Primary Exceptionality, please do so prior to exit.',
    'ESELanguageImpaired' : 'You have not entered a Primary Exceptionality, please do so prior to exit.',
    'ESESpecificLearningDisabled' : 'You have not entered a Primary Exceptionality, please do so prior to exit.',
    'ESESpeechImpaired' : 'You have not entered a Primary Exceptionality, please do so prior to exit.',
    'ESEVisuallyImpaired' : 'You have not entered a Primary Exceptionality, please do so prior to exit.',
    'ESEOrthopedicallyImpaired' : 'You have not entered a Primary Exceptionality, please do so prior to exit.',
    'ESEOtherHealthImpaired' : 'You have not entered a Primary Exceptionality, please do so prior to exit.',
    'ESETraumaticBrainInjured' : 'You have not entered a Primary Exceptionality, please do so prior to exit.',
    'PrimarySecondaryAge3A' : 'A Child cannot have a Primary OR Secondary Exceptionality of EH(Emotionally Handicapped), SLD(Specific Learning Disabled) or SED(Severely Emotionally Disturbed) if he/she is less than 3 years old.',
    'PrimarySecondaryAge6' : 'A Child cannot have a Primary OR Secondary Exceptionality of DD(Developmentally Delayed) if he/she is 6 years old or older.',
    'PrimarySecondaryAge3B' : 'A Child cannot have a Primary OR Secondary Exceptionality of EC(Established Condition) if he/she is 3 years old or older.',
    'ScrPresentingProblem1' : 'Presenting Problem should be entered.',
    'EvlPresentingProblem1' : 'Presenting Problem should be entered.',
    'TrnTransitionMeetingDateAge' : 'This child is less than 2 years 3 months old. Please check with Early Steps to be sure the Transition Meeting Date is correct. The Transition Meeting date must not be in the future.',
    'TrnPartCtoPartBReferralDateAge' : 'This child is less than 2 years old. Please check with Early Steps to be sure the Transition Meeting Date is correct. The Transition Meeting date must not be in the future.',
    'ScrReferralForScreeningDateAge' : 'This child is less than 2 years 3 months old and should be referred to Early Steps.  Please check to be sure you should begin a timeline for this child. The Screening date must not be in the future.'
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    activeChild : Child;
    timelineEvent : TimelineEvent;
    handleClearActiveTimelineEvent : () => void;
    eventValues : TimelineEventValues;
    timelineEvents : Array<TimelineEvent>,
    handleSetActiveTimelineEvent : (timelineEvent : TimelineEvent) => void;
    handleTimelineEventSubmit : (values : FormikValues, timelineEventName : string) => Promise<void>;
    eventName : string;
};

export type BaseTimelineFormValues = {
    EventNotes : string;
    UUID : string;
    RecordCreator : string;
    UpdateDate : string;
    EventModifier : string;
};

export type SudoRequiredField = {
    name: string;
    showError: boolean;
    standard: boolean;
};

export const getDaysElapsedError = (
    fieldName : string,
    negativeDaysElapsed : boolean,
    sudoRequiredFields : Array<SudoRequiredField>
) => {
    let daysElapsedError = negativeDaysElapsed
        ? 'The selected date will cause days elapsed to be negative. ' : '';

    const sudoField = sudoRequiredFields.find(
        (sudoRequiredField) => sudoRequiredField.name === fieldName
    );

    daysElapsedError += sudoField && sudoField.showError ? requiredFieldMsg[fieldName] : '';
    return daysElapsedError;
};

const TimelineEventForm = ({
    canSave,
    siteLists,
    activeChild,
    timelineEvent,
    handleClearActiveTimelineEvent,
    eventValues,
    timelineEvents,
    handleSetActiveTimelineEvent,
    handleTimelineEventSubmit,
    eventName
} : Props) => {
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [negativeDaysElapsed, setNegativeDaysElapsed] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [sudoRequiredFields, setSudoRequiredFields] = useState<Array<SudoRequiredField>>([
        {name: 'ScrReferralForScreeningDate', showError: false, standard: true},
        {name: 'ScrFinalScreeningResultDate', showError: false, standard: true},
        {name: 'ScrFinalScreeningResult', showError: false, standard: true},
        {name: 'TrnPartCtoPartBReferralDate', showError: false, standard: true},
        {name: 'TrnTransitionMeetingDate', showError: false, standard: true},
        {name: 'EvlParentConsentForEval', showError: false, standard: true},
        {name: 'EvlReferralForEvaluationDate', showError: false, standard: true},
        {name: 'EvlFinalEvalDate', showError: false, standard: true},
        {name: 'ESEEligibilityDate', showError: false, standard: true},
        {name: 'IEPIFSPDate', showError: false, standard: true},
        {name: 'IEPIFSPParentConsentDate', showError: false, standard: true},
        {name: 'ESEAutistic', showError: false, standard: false},
        {name: 'ESEDeaforHardofHearing', showError: false, standard: false},
        {name: 'ESEDevelopmentallyDelayed', showError: false, standard: false},
        {name: 'ESEDualSensoryImpaired', showError: false, standard: false},
        {name: 'ESEEmotionalBehavioralDisability', showError: false, standard: false},
        {name: 'ESEEstablishedConditions', showError: false, standard: false},
        {name: 'ESEHospitalHomebound', showError: false, standard: false},
        {name: 'ESEIntellectualDisability', showError: false, standard: false},
        {name: 'ESELanguageImpaired', showError: false, standard: false},
        {name: 'ESESpecificLearningDisabled', showError: false, standard: false},
        {name: 'ESESpeechImpaired', showError: false, standard: false},
        {name: 'ESEVisuallyImpaired', showError: false, standard: false},
        {name: 'ESEOrthopedicallyImpaired', showError: false, standard: false},
        {name: 'ESEOtherHealthImpaired', showError: false, standard: false},
        {name: 'ESETraumaticBrainInjured', showError: false, standard: false},
        {name: 'PrimarySecondaryAge3A', showError: false, standard: false},
        {name: 'PrimarySecondaryAge6', showError: false, standard: false},
        {name: 'PrimarySecondaryAge3B', showError: false, standard: false},
        {name: 'ScrPresentingProblem1', showError: false, standard: true},
        {name: 'EvlPresentingProblem1', showError: false, standard: true},
        {name: 'TrnTransitionMeetingDateAge', showError: false, standard: false},
        {name: 'TrnPartCtoPartBReferralDateAge', showError: false, standard: false},
        {name: 'ScrReferralForScreeningDateAge', showError: false, standard: false},
    ]);

    const handleSetFormIsModified = useCallback(async (formIsModified : boolean) => {
        await setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const handleCheckNegativeDaysElapsed = useCallback(async (eventName: string, date: string) => {
        const nextPrev : NextPrev = getNextPrevEvent[eventName];
        const prevTimelineEvent : TimelineEvent | undefined = timelineEvents.find(
            (timelineEvent) => timelineEvent.name === nextPrev.prev
        );

        if (prevTimelineEvent && prevTimelineEvent.date !== ''
            && moment(new Date(prevTimelineEvent.date)) > moment(new Date(date))) {
            setNegativeDaysElapsed(true);
        } else {
            setNegativeDaysElapsed(false);
        }
    }, [setNegativeDaysElapsed, timelineEvents]);

    const handleSudoRequiredFields = useCallback(async (values : FormikValues) => {
        const standardFields : Array<SudoRequiredField> | undefined = sudoRequiredFields.filter(
            (sudoRequiredField) => sudoRequiredField.standard
        );

        for (const sudoRequiredField of standardFields) {
            if (sudoRequiredField.name in values && values[sudoRequiredField.name] === '')
            {
                sudoRequiredField.showError = true;
            } else {
                sudoRequiredField.showError = false;
            }

            const fieldIndex = sudoRequiredFields.findIndex(
                (sudoRequiredFieldMain) => sudoRequiredFieldMain.name === sudoRequiredField.name
            );

            sudoRequiredFields[fieldIndex] = sudoRequiredField;
        };

        const secondaryExceptionality : Array<SudoRequiredField> | undefined = sudoRequiredFields.filter(
            (sudoRequiredField) => secondaryExceptionalityFields.includes(sudoRequiredField.name)
        );

        for (const sudoRequiredField of secondaryExceptionality) {
            if (sudoRequiredField.name in values && 'ESEPrimaryExceptionality' in values
                && values[sudoRequiredField.name] !== '' && values['ESEPrimaryExceptionality'] === '')
            {
                sudoRequiredField.showError = true;
            } else {
                sudoRequiredField.showError = false;
            }

            const fieldIndex = sudoRequiredFields.findIndex(
                (sudoRequiredFieldMain) => sudoRequiredFieldMain.name === sudoRequiredField.name
            );

            sudoRequiredFields[fieldIndex] = sudoRequiredField;
        }

        let index = sudoRequiredFields.findIndex(
            (sudoRequiredField) => sudoRequiredField.name === 'PrimarySecondaryAge3A'
        );
        if ('ESEPrimaryExceptionality' in values && parseInt(activeChild.age) < 3 &&
            (['SLD (K)'].includes(values['ESEPrimaryExceptionality']) || values['ESEEmotionalBehavioralDisability'] !== ''
                || values['ESESpecificLearningDisabled'] !== '')
        ) {
            sudoRequiredFields[index].showError = true;
        } else {
            sudoRequiredFields[index].showError = false;
        }

        index = sudoRequiredFields.findIndex(
            (sudoRequiredField) => sudoRequiredField.name === 'PrimarySecondaryAge6'
        );
        if ('ESEPrimaryExceptionality' in values && parseInt(activeChild.age) < 3 &&
            (['DD (T)'].includes(values['ESEPrimaryExceptionality']) || values['ESEDevelopmentallyDelayed'] !== '')
        ) {
            sudoRequiredFields[index].showError = true;
        } else {
            sudoRequiredFields[index].showError = false;
        }

        index = sudoRequiredFields.findIndex(
            (sudoRequiredField) => sudoRequiredField.name === 'PrimarySecondaryAge3B'
        );
        if ('ESEPrimaryExceptionality' in values && parseInt(activeChild.age) < 3 &&
            (['EC (U)'].includes(values['ESEPrimaryExceptionality']) || values['ESEEstablishedConditions'] !== '')
        ) {
            sudoRequiredFields[index].showError = true;
        } else {
            sudoRequiredFields[index].showError = false;
        }

        const formattedDOB = moment(activeChild.displayDOB);

        index = sudoRequiredFields.findIndex(
            (sudoRequiredField) => sudoRequiredField.name === 'TrnTransitionMeetingDateAge'
        );
        if ('TrnTransitionMeetingDate' in values
            && (
                moment(values['TrnTransitionMeetingDate']).subtract('27', 'months') < moment(formattedDOB)
                || moment(values['TrnTransitionMeetingDate']) > moment()
            )
        ) {
            sudoRequiredFields[index].showError = true;
        } else {
            sudoRequiredFields[index].showError = false;
        }

        index = sudoRequiredFields.findIndex(
            (sudoRequiredField) => sudoRequiredField.name === 'TrnPartCtoPartBReferralDateAge'
        );
        if ('TrnPartCtoPartBReferralDate' in values
            && (
                moment(values['TrnPartCtoPartBReferralDate']).subtract('24', 'months') < moment(formattedDOB)
                || moment(values['TrnPartCtoPartBReferralDate']) > moment()
            )
        ) {
            sudoRequiredFields[index].showError = true;
        } else {
            sudoRequiredFields[index].showError = false;
        }

        index = sudoRequiredFields.findIndex(
            (sudoRequiredField) => sudoRequiredField.name === 'ScrReferralForScreeningDateAge'
        );
        if ('ScrReferralForScreeningDate' in values
            && (
                moment(values['ScrReferralForScreeningDate']).subtract('27', 'months') < moment(formattedDOB)
                || moment(values['ScrReferralForScreeningDate']) > moment()
            )
        ) {
            sudoRequiredFields[index].showError = true;
        } else {
            sudoRequiredFields[index].showError = false;
        }

        await setSudoRequiredFields(sudoRequiredFields);
    }, [sudoRequiredFields, setSudoRequiredFields, activeChild]);

    const initialValues : NameToValueMap = eventValues;

    React.useEffect(() => {
        handleSudoRequiredFields(initialValues);
        handleCheckNegativeDaysElapsed(eventName, timelineEvent.date);
    }, [timelineEvent, eventName, handleCheckNegativeDaysElapsed, handleSudoRequiredFields, initialValues]);

    const getTimelineEventForm = (eventName : string) : JSX.Element | null => {
        switch (eventName) {
            case TRANSITION_STRING:
                return <TransitionForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                    handleCheckNegativeDaysElapsed={handleCheckNegativeDaysElapsed}
                    negativeDaysElapsed={negativeDaysElapsed}
                    sudoRequiredFields={sudoRequiredFields}
                />;
            case SCREENING_STRING:
                return <ScreeningForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                    handleCheckNegativeDaysElapsed={handleCheckNegativeDaysElapsed}
                    negativeDaysElapsed={negativeDaysElapsed}
                    sudoRequiredFields={sudoRequiredFields}
                />;
            case EVALUATION_STRING:
                return <EvaluationForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                    handleCheckNegativeDaysElapsed={handleCheckNegativeDaysElapsed}
                    negativeDaysElapsed={negativeDaysElapsed}
                    sudoRequiredFields={sudoRequiredFields}
                />;
            case ESE_ELIGIBILITY_STRING:
                return <ESEEligibilityForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                    handleCheckNegativeDaysElapsed={handleCheckNegativeDaysElapsed}
                    negativeDaysElapsed={negativeDaysElapsed}
                    sudoRequiredFields={sudoRequiredFields}
                />;
            case IEP_IFSP_STRING:
                return <IEPIFSPForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                    handleCheckNegativeDaysElapsed={handleCheckNegativeDaysElapsed}
                    negativeDaysElapsed={negativeDaysElapsed}
                    sudoRequiredFields={sudoRequiredFields}
                />;
            case END_TIMELINE_STRING:
                return <EndTimelineForm
                    canSave={canSave}
                    siteLists={siteLists}
                    handleSetFormIsModified={handleSetFormIsModified}
                    handleCheckNegativeDaysElapsed={handleCheckNegativeDaysElapsed}
                    negativeDaysElapsed={negativeDaysElapsed}
                />;
        }
        return null;
    };

    const getTimelineEventSchema : any = {
        'Transition' : TransitionSchema,
        'Screening' : ScreeningSchema,
        'Evaluation' : EvaluationSchema,
        'ESE Eligibility' : ESEEligibilitySchema,
        'IEP/IFSP' : IEPIFSPSchema,
        'End Timeline' : EndTimelineSchema
    };

    const getTimelineEventFooterFields : any = {
        'Transition' : {
            note: 'TrnNotes',
            actionNeeded : 'DemoEntry to Timeline by AN to TrnAN::ActionNeeded',
            completedDate : 'DemoEntry to Timeline by AN to TrnAN::ANCompletedDate',
            followUpDate : 'DemoEntry to Timeline by AN to TrnAN::ANFollowUpDate'
        },
        'Screening' : {
            note: 'ScrNotes',
            actionNeeded : 'DemoEntry to Timeline by AN to ScrAN::ActionNeeded',
            completedDate : 'DemoEntry to Timeline by AN to ScrAN::ANCompletedDate',
            followUpDate : 'DemoEntry to Timeline by AN to ScrAN::ANFollowUpDate'
        },
        'Evaluation' : {
            note: 'EvlNotes',
            actionNeeded : 'DemoEntry to Timeline by AN to EvlAN::ActionNeeded',
            completedDate : 'DemoEntry to Timeline by AN to EvlAN::ANCompletedDate',
            followUpDate : 'DemoEntry to Timeline by AN to EvlAN::ANFollowUpDate'
        },
        'ESE Eligibility' : {
            note: 'ESENotes',
            actionNeeded : 'DemoEntry to Timeline by AN to ESEAN::ActionNeeded',
            completedDate : 'DemoEntry to Timeline by AN to ESEAN::ANCompletedDate',
            followUpDate : 'DemoEntry to Timeline by AN to ESEAN::ANFollowUpDate'
        },
        'IEP/IFSP' : {
            note: 'IEPIFSPNotes',
            actionNeeded : 'DemoEntry to Timeline by AN to IEPIFSPAN::ActionNeeded',
            completedDate : 'DemoEntry to Timeline by AN to IEPIFSPAN::ANCompletedDate',
            followUpDate : 'DemoEntry to Timeline by AN to IEPIFSPAN::ANFollowUpDate'
        },
        'End Timeline' : {
            note: 'TrnNotes',
            actionNeeded : 'DemoEntry to Timeline by AN to EndAN::ActionNeeded',
            completedDate : 'DemoEntry to Timeline by AN to EndAN::ANCompletedDate',
            followUpDate : 'DemoEntry to Timeline by AN to EndAN::ANFollowUpDate'
        }
    };

    const getTimelineEventSummaryFields : any = {
        'Transition' : {
            eventModifier : initialValues['TrnEventModifier'],
            updatedDate : initialValues['TrnUpdateDate'],
            recordCreator : initialValues['TrnRecordCreator']
        },
        'Screening' : {
            eventModifier : initialValues['ScrEventModifier'],
            updatedDate : initialValues['ScrUpdateDate'],
            recordCreator : initialValues['ScrRecordCreator']
        },
        'Evaluation' : {
            eventModifier : initialValues['EvlEventModifier'],
            updatedDate : initialValues['EvlUpdateDate'],
            recordCreator : initialValues['EvlRecordCreator']
        },
        'ESE Eligibility' : {
            eventModifier : initialValues['ESEEventModifier'],
            updatedDate : initialValues['ESEUpdateDate'],
            recordCreator : initialValues['ESERecordCreator']
        },
        'IEP/IFSP' : {
            eventModifier : initialValues['IEPIFSPEventModifier'],
            updatedDate : initialValues['IEPIFSPUpdateDate'],
            recordCreator : initialValues['IEPIFSPRecordCreator']
        },
        'End Timeline' : {
            eventModifier : initialValues['EndEventModifier'],
            updatedDate : initialValues['EndUpdateDate'],
            recordCreator : initialValues['EndRecordCreator']
        }
    };

    const schema = yup.object({
        ...getTimelineEventSchema[eventName]
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values, formikHelpers) => {
                setLoading(true);
                await handleSudoRequiredFields(values);
                await handleTimelineEventSubmit(values, eventName);
                await handleSetFormIsModified(false);
                setLoading(false);
            }}
            enableReinitialize={true}
            validateOnMount={true}
        >
        {(props) => {
            return <Form className="chris-form-bg pt-3">
                <TimelineEventControls
                    handleClearActiveEvent={handleClearActiveTimelineEvent}
                    loading={loading}
                    formIsModified={formIsModified}
                    submitForm={props.submitForm}
                    canSave={canSave}
                    isValid={props.isValid}
                    handleSetActiveTimelineEvent={handleSetActiveTimelineEvent}
                    eventName={eventName}
                    timelineEvents={timelineEvents}
                    timelineEvent={timelineEvent}
                />
                <TimelineEventSummary activeChild={activeChild} eventValues={getTimelineEventSummaryFields[eventName]}/>
                {getTimelineEventForm(eventName)}
                <TimelineEventFooter
                    handleSetFormIsModified={handleSetFormIsModified}
                    canSave={canSave}
                    fields={getTimelineEventFooterFields[eventName]}
                />
                <ModifiedPrompt formIsModified={formIsModified} loading={loading}/>
            </Form>
        }}
        </Formik>
    );
};

export default TimelineEventForm;
