import React from 'react';
import {Prompt} from "react-router";

type Props = {
    formIsModified: boolean;
    loading: boolean;
};

const ModifiedPrompt = ({formIsModified, loading} : Props) => {
    return (
        <Prompt
            when={formIsModified || loading}
            message='You have unsaved changes, to SAVE the Data click Cancel.'
        />
    );
};

export default ModifiedPrompt;