import React, {useCallback, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons';
import {Col, Row} from 'react-bootstrap';
import FormControls from '../Forms/Fields/FormControls';
import {Formik, Form, FormikValues, FormikProps} from 'formik';
import * as yup from 'yup';
import ModifiedPrompt from "../Forms/ModifiedPrompt";
import { useHistory } from "react-router-dom";
import SystemAlert from "../SystemAlert";
import {Report} from "../../pages/Reports";
import StandardReports, {
    STANDARD_REPORTS,
    STANDARD_REPORTS_STRING,
    StandardReportsSchema,
    StandardReportsValues
} from "../Forms/Reports/StandardReports";
import {List} from "../../pages/RecordLocator";
import TimelineReport, {
    TIMELINE_REPORT,
    TIMELINE_REPORT_STRING,
    TimelineReportSchema,
    TimelineReportValues
} from "../Forms/Reports/TimelineReport";
import DaysElapsedReport, {
    DAYS_ELAPSED_REPORT,
    DAYS_ELAPSED_REPORT_STRING,
    DaysElapsedReportSchema,
    DaysElapsedReportValues
} from "../Forms/Reports/DaysElapsedReport";
import SixtyDayReport, {
    SIXTY_DAY_REPORT,
    SIXTY_DAY_REPORT_STRING,
    SixtyDayReportSchema,
    SixtyDayReportValues
} from "../Forms/Reports/SixtyDayReport";
import SODReferralReport, {
    SOD_REFERRAL_REPORT, SOD_REFERRAL_REPORT_STRING,
    SODReferralReportSchema,
    SODReferralReportValues
} from "../Forms/Reports/SODReferralReport";
import SODCompletedReport, {
    SOD_COMPLETED_REPORT,
    SOD_COMPLETED_REPORT_STRING,
    SODCompletedReportSchema, SODCompletedReportValues
} from "../Forms/Reports/SODCompletedReport";
import SODEvaluationReport, {
    SOD_EVALUATION_REPORT,
    SOD_EVALUATION_REPORT_STRING,
    SODEvaluationReportSchema, SODEvaluationReportValues
} from "../Forms/Reports/SODEvaluationReport";
import COSReport, {
    COS_REPORT,
    COS_REPORT_STRING,
    COSReportSchema,
    COSReportValues
} from "../Forms/Reports/COSReport";

export interface NameToValueMap
{
    [key: string] : any;
}

export type ReportSubmitType = 'print' | 'email' | 'count';

export type ReportName =
    STANDARD_REPORTS
    | TIMELINE_REPORT
    | DAYS_ELAPSED_REPORT
    | SIXTY_DAY_REPORT
    | SOD_REFERRAL_REPORT
    | SOD_COMPLETED_REPORT
    | SOD_EVALUATION_REPORT
    | COS_REPORT;

type Props = {
    activeReport : Report;
    setActiveReport : (activeReport: Report | null) => void;
    handleReportBuilderSubmit : (values : FormikValues, submitType : ReportSubmitType) => void;
    reportType : string;
    reportName : string;
    siteLists: Array<List>;
}

const ReportBuilder = ({
    activeReport,
    setActiveReport,
    handleReportBuilderSubmit,
    reportType,
    reportName,
    siteLists
} : Props) => {
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showSystemAlert, setShowSystemAlert] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<string>('');
    const [submitType, setSubmitType] = useState<ReportSubmitType>('print');

    const handleSetFormIsModified = useCallback(async (formIsModified : boolean) => {
        await setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const systemAlert = useCallback(async (alertText: string, alertTimeout: number) => {
        await setAlertText(alertText);
        await setShowSystemAlert(true);
        setTimeout(() => {
            setShowSystemAlert(false);
        }, alertTimeout);
    }, [setShowSystemAlert, setAlertText]);

    const getReportForm = (reportType : string, formProps: FormikProps<any>, reportName : string, contextTable? : string) : JSX.Element | null => {
        switch (reportType) {
            case TIMELINE_REPORT_STRING:
                return <TimelineReport handleSetFormIsModified={handleSetFormIsModified}/>;
            case DAYS_ELAPSED_REPORT_STRING:
                return <DaysElapsedReport
                    handleSetFormIsModified={handleSetFormIsModified}
                    siteLists={siteLists}
                />;
            case SIXTY_DAY_REPORT_STRING:
                return <SixtyDayReport handleSetFormIsModified={handleSetFormIsModified}/>;
            case SOD_REFERRAL_REPORT_STRING:
                return <SODReferralReport handleSetFormIsModified={handleSetFormIsModified}/>;
            case SOD_COMPLETED_REPORT_STRING:
                return <SODCompletedReport handleSetFormIsModified={handleSetFormIsModified}/>;
            case SOD_EVALUATION_REPORT_STRING:
                return <SODEvaluationReport handleSetFormIsModified={handleSetFormIsModified}/>;
            case COS_REPORT_STRING:
                return <COSReport handleSetFormIsModified={handleSetFormIsModified}/>
            case STANDARD_REPORTS_STRING:
            default:
                return <StandardReports
                    handleSetFormIsModified={handleSetFormIsModified}
                    siteLists={siteLists}
                    reportName={reportName}
                    contextTable={contextTable}
                />;
        }
    };

    const getReportSchema : any = {
        'StandardReports' : StandardReportsSchema,
        'TimelineReport' : TimelineReportSchema,
        'DaysElapsedReport' : DaysElapsedReportSchema,
        'SixtyDayReport' : SixtyDayReportSchema,
        'SODReferralReport' : SODReferralReportSchema,
        'SODCompletedReport' : SODCompletedReportSchema,
        'SODEvaluationReport' : SODEvaluationReportSchema,
        'COSReport': COSReportSchema
    };

    const getReportValues : any = {
        'StandardReports' : StandardReportsValues,
        'TimelineReport' : TimelineReportValues,
        'DaysElapsedReport' : DaysElapsedReportValues,
        'SixtyDayReport' : SixtyDayReportValues,
        'SODReferralReport' : SODReferralReportValues,
        'SODCompletedReport' : SODCompletedReportValues,
        'SODEvaluationReport' : SODEvaluationReportValues,
        'COSReport' : COSReportValues,
    };

    const schema = yup.object({
        ...getReportSchema[reportType]
    });

    let initialValues : NameToValueMap = getReportValues[reportType];

    let history = useHistory();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values, formikHelpers) => {
                await setLoading(true);
                await handleReportBuilderSubmit(values, submitType);
                await handleSetFormIsModified(false);
                await setSubmitType('print');
                await setLoading(false);
            }}
        >
            {(props) => {
                return <Form className="w-100">
                    <Row className="mx-0">
                        <Col lg={4} className="d-flex flex-row pt-3 pl-2">
                            <FontAwesomeIcon
                                icon={faExchangeAlt}
                                className="btn-link cursor-pointer mx-2"
                                onClick={() => {
                                    history.push(`/reports?sub=reports`);
                                    if (history.location.search === `?sub=reports`) {
                                        setActiveReport(null);
                                    }
                                }}
                            /> <h3>{activeReport.title}</h3>
                        </Col>
                        <Col lg={8} xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                            <FormControls
                                formIsModified={formIsModified}
                                loading={loading}
                                submitForm={props.submitForm}
                                showSave={false}
                                systemAlert={systemAlert}
                                setSubmitType={setSubmitType}
                                submitType={submitType}
                                showEmail={true}
                                showPrint={true}
                                showCount={true}
                                isValid={props.isValid}
                                printText="Generate Report"
                                emailText="Email Report Link"
                                disableAutoSave={true}
                                emailHasValue={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <p className="my-4">{activeReport.description}</p>
                        </Col>
                    </Row>
                    <Row className="chris-form-bg mx-0 py-3 px-2">
                        {getReportForm(reportType, props, reportName, activeReport.contextTable)}
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end mt-3">
                            <FormControls
                                formIsModified={formIsModified}
                                loading={loading}
                                submitForm={props.submitForm}
                                showSave={false}
                                systemAlert={systemAlert}
                                setSubmitType={setSubmitType}
                                submitType={submitType}
                                showEmail={true}
                                showPrint={true}
                                showCount={true}
                                isValid={props.isValid}
                                printText="Generate Report"
                                emailText="Email Report Link"
                                disableAutoSave={true}
                                emailHasValue={true}
                            />
                        </Col>
                    </Row>
                    <ModifiedPrompt formIsModified={formIsModified} loading={loading}/>
                    <SystemAlert show={showSystemAlert} alertText={alertText} setShowSystemAlert={setShowSystemAlert}/>
                </Form>
            }}
        </Formik>
    );
};

export default ReportBuilder;
