import React from 'react';
import {Col, Row} from 'react-bootstrap';
import StandardField from "../../Fields/StandardField";

export type FooterFields = {
    note : string;
    actionNeeded : string;
    completedDate : string;
    followUpDate : string;
};

type Props = {
    handleSetFormIsModified : (formIsModified: boolean) => Promise<void>;
    canSave : boolean;
    fields : FooterFields;
};

const TimelineEventFooter = ({handleSetFormIsModified, canSave, fields} : Props) => {
    return (
        <Row className="mx-0 p-2 pt-0">
            <Col xs={12} lg={12}>
                <hr className="mt-0"/>
            </Col>
            <Col xs={12} lg={12}>
                <StandardField
                    name={fields.actionNeeded}
                    type="text"
                    label="Action Needed"
                    disabled={!canSave}
                    handleSetFormIsModified={handleSetFormIsModified}
                />
            </Col>
            <Col xs={12} lg={4}>
                <StandardField
                    name={fields.followUpDate}
                    type="date"
                    label="Follow Up Date"
                    disabled={!canSave}
                    handleSetFormIsModified={handleSetFormIsModified}
                />
            </Col>
            <Col xs={12} lg={4}>
                <StandardField
                    name={fields.completedDate}
                    type="date"
                    label="Completed Date"
                    disabled={!canSave}
                    handleSetFormIsModified={handleSetFormIsModified}
                />
            </Col>
            <Col xs={12} lg={12} className="mt-0 pt-0">
                <hr className="p-0 m-0 pb-2"/>
            </Col>
            <Col xs={12} lg={12}>
                <StandardField
                    name={fields.note}
                    type="textarea"
                    label="Notes"
                    className="textarea-md"
                    disabled={!canSave}
                    handleSetFormIsModified={handleSetFormIsModified}
                />
            </Col>
        </Row>
    );
}

export default (TimelineEventFooter);