import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../Fields/StandardField";
import * as yup from 'yup';

export const SIXTY_DAY_REPORT_STRING = 'SixtyDayReport';
export type SIXTY_DAY_REPORT = typeof SIXTY_DAY_REPORT_STRING;

export const SixtyDayReportValues = {
    EvlParentConsentForEval : '',
    '60dayRule' : ''
};

export const SixtyDayReportSchema = {
    EvlParentConsentForEval: yup.string().required(),
    '60dayRule' : yup.string().required('You must select a report to run')
};

type Props = {
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
};

const SixtyDayReport = ({
    handleSetFormIsModified
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-1">
                <Col xs={12}>
                    <h3>Find Criteria</h3>
                </Col>
            </Row>
            <Row className="mx-0 px-2 condensed-form-row">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="EvlParentConsentForEval"
                        type="text"
                        label="Parent Consent for Evaluation Date:"
                        disabled={false}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} className="mt-3">
                    <h3>Report Selection</h3>
                </Col>
                <Col xs={4}>
                    <StandardField
                        name="60dayRule"
                        type="select"
                        label="Select Report:"
                        disabled={false}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={[
                            {label: '60 Day Rule Chart', value: '60 Day Rule Chart', checked: false},
                            {label: '60 Day Rule Detail', value: '60 Day Rule Detail', checked: false}
                        ]}
                    />
                </Col>
            </Row>
        </>
    );
};

export default SixtyDayReport;