import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../../Fields/StandardField";
import * as yup from 'yup';
import {List} from "../../../../pages/RecordLocator";
import {BaseFormValues} from "./SCEventForm";
import {yupDateRegex} from "../../../../utils/formHelpers";

export const PACKET_RETURNED_STRING = 'Packet Returned';
export type PACKET_RETURNED = typeof PACKET_RETURNED_STRING;

export type PacketReturnedValues = BaseFormValues & {
    PacketReturnedComments : string;
    PacketReturnedDate : string;
};

export const PacketReturnedSchema = {
    PacketReturnedComments: yup.string(),
    PacketReturnedDate: yupDateRegex.required('You must enter a date'),
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
};

const PacketReturnedForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="PacketReturnedDate"
                        type="date"
                        label="Packet Returned Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="PacketReturnedComments"
                        type="textarea"
                        label="Comments"
                        className="textarea-lg"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
        </>
    );
};

export default PacketReturnedForm;