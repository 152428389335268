import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../../Fields/StandardField";
import * as yup from 'yup';
import {List} from "../../../../pages/RecordLocator";
import {BaseFormValues} from "./SCEventForm";
import {yupDateRegex} from "../../../../utils/formHelpers";

export const FOLDER_TO_ESE_DISPLAY = 'Folder to District/LEA';
export const FOLDER_TO_ESE_STRING = 'Folder To ESE';
export type FOLDER_TO_ESE = typeof FOLDER_TO_ESE_STRING;

export type FolderToESEValues = BaseFormValues & {
    FolderToESEDate : string;
    FolderToESERcvdByESE : string;
    FolderToESEComments : string;
};

export const FolderToESESchema = {
    FolderToESEComments: yup.string(),
    FolderToESEDate: yupDateRegex.required('You must enter a date'),
    FolderToESERcvdByESE: yupDateRegex
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
};

const FolderToESEForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-2">
                <Col xs={6}>
                    <StandardField
                        name="FolderToESEDate"
                        type="date"
                        label="Folder To District/LEA Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={6}>
                    <StandardField
                        name="FolderToESERcvdByESE"
                        type="date"
                        label="Received By District/LEA Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="FolderToESEComments"
                        type="textarea"
                        label="Folder To District/LEA Notes"
                        className="textarea-lg"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
        </>
    );
};

export default FolderToESEForm;
