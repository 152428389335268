import React, {useCallback, useState} from 'react';
import {Form, Formik, FormikValues} from "formik";
import {NameToValueMap} from "../FormBuilder";
import {Col, Row} from "react-bootstrap";
import StandardField from "../Fields/StandardField";
import * as yup from 'yup';
import FormControls from "../Fields/FormControls";
import ModifiedPrompt from "../ModifiedPrompt";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

type Props = {
    handleSubmit: (values : FormikValues) => void;
};

const AddDataFacilitatorListOptionForm = ({
    handleSubmit
} : Props) => {
    const [formIsModified, setFormIsModified] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSetFormIsModified = useCallback(async (formIsModified : boolean) => {
        await setFormIsModified(formIsModified);
    }, [setFormIsModified]);

    const initialValues : NameToValueMap = {optionValue: ''};

    const schema = yup.object({
        optionValue: yup.string().required()
    });

    return (
        <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (values, formikHelpers) => {
            setLoading(true);
            await handleSubmit(values);
            formikHelpers.setFieldValue('optionValue', '', false);
            setFormIsModified(false);
            setLoading(false);
        }}
        enableReinitialize={true}
        >
        {(props) => {
            return <Form className="w-100">
                <Row>
                    <Col xs={12}>
                        <hr />
                    </Col>
                </Row>
                <Row className="mx-0 my-2">
                    <Col xs={4} className="pl-1">
                        <StandardField
                            name="optionValue"
                            type="text"
                            label="New Option Value"
                            disabled={false}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={4} className="pt-3 add-option-button">
                        <FormControls
                            formIsModified={formIsModified}
                            loading={loading}
                            submitForm={props.submitForm}
                            showSave={true}
                            submitType={'save'}
                            disableAutoSave={true}
                            isValid={props.isValid}
                            saveText={'Add Option'}
                            saveTextPast={'Add Option'}
                            saveTextAction={'Adding Option'}
                            saveIcon={faPlus}
                        />
                    </Col>
                </Row>
                <ModifiedPrompt formIsModified={formIsModified} loading={loading}/>
            </Form>
        }}
        </Formik>
    );
};

export default AddDataFacilitatorListOptionForm;