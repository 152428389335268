import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../../Fields/StandardField";
import * as yup from 'yup';
import {List} from "../../../../pages/RecordLocator";
import {getOptionsForField, yupDateRegex} from "../../../../utils/formHelpers";
import {BaseFormValues} from "./SCEventForm";

export const REFERRAL_INACTIVE_STRING = 'Referral Inactive';
export type REFERRAL_INACTIVE = typeof REFERRAL_INACTIVE_STRING;

export type ReferralInactiveValues = BaseFormValues & {
    ReferralInactiveDate : string;
    ReferralInactiveReason : string;
};

export const ReferralInactiveSchema = {
    ReferralInactiveReason: yup.string(),
    ReferralInactiveDate: yupDateRegex.required('You must enter a date')
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
};

const ReferralInactiveForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="ReferralInactiveDate"
                        type="date"
                        label="Referral Inactive Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="ReferralInactiveReason"
                        type="select"
                        label="Referral Inactive Reason"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'Inactive',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
        </>
    );
};

export default ReferralInactiveForm;