import React from 'react';
import {Col, Row} from 'react-bootstrap';
import StandardField from "../../Fields/StandardField";

type Props = {
    handleSetFormIsModified : (formIsModified: boolean) => Promise<void>;
    canSave : boolean;
};

const EventFooter = ({handleSetFormIsModified, canSave} : Props) => {
    return (
        <Row className="mx-0 p-2">
            <Col xs={12} lg={12}>
                <hr />
            </Col>
            <Col xs={12} lg={12}>
                <StandardField
                    name="DemoEntry to ServiceCoordination by ANUnique::ActionNeeded"
                    type="text"
                    label="Action Needed"
                    disabled={!canSave}
                    handleSetFormIsModified={handleSetFormIsModified}
                />
            </Col>
            <Col xs={12} lg={4}>
                <StandardField
                    name="DemoEntry to ServiceCoordination by ANUnique::ANFollowUpDate"
                    type="date"
                    label="Follow Up Date"
                    disabled={!canSave}
                    handleSetFormIsModified={handleSetFormIsModified}
                />
            </Col>
            <Col xs={12} lg={4}>
                <StandardField
                    name="DemoEntry to ServiceCoordination by ANUnique::ANCompletedDate"
                    type="date"
                    label="Completed Date"
                    disabled={!canSave}
                    handleSetFormIsModified={handleSetFormIsModified}
                />
            </Col>
            <Col xs={12} lg={12} className="mt-0 pt-0">
                <hr />
            </Col>
            <Col xs={12} lg={12}>
                <StandardField
                    name="EventNotes"
                    type="textarea"
                    label="Notes"
                    className="textarea-md"
                    disabled={!canSave}
                    handleSetFormIsModified={handleSetFormIsModified}
                />
            </Col>
        </Row>
    );
}

export default (EventFooter);