import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    MutableRefObject,
} from 'react';
import moment from 'moment';
import {useUser} from "./Providers/OidcProvider";
import AutoLogoutModal from "./Modal/AutoLogoutModal";

const events = ['click', 'scroll', 'load'];

const SessionTimeout = () => {
    const [isOpen, setOpen] = useState(false);
    const user = useUser();
    const sessionTimeoutMinutes = process.env.REACT_APP_SESSION_TIMEOUT_MINUTES!;

    let timeStamp;
    let warningInactiveInterval : MutableRefObject<NodeJS.Timeout | undefined> = useRef();
    let startTimerInterval : MutableRefObject<NodeJS.Timeout | undefined> = useRef();
    const entryRef = useRef<number>(60);
    entryRef.current = 60;

    let timeChecker = () => {
        startTimerInterval.current = setTimeout(() => {
            let storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
            if (storedTimeStamp && moment(storedTimeStamp) < moment(new Date())) {
                warningInactive(storedTimeStamp);
            } else {
                timeChecker();
            }
        }, 60000);
    };

    const warningCountdown = useCallback(() => {
        entryRef.current -= 1;

        if (entryRef.current < 1) {
            if (warningInactiveInterval.current) {
                clearInterval(warningInactiveInterval.current);
            }
            sessionStorage.removeItem('lastTimeStamp');
            setOpen(false);
            window.location.href = '/logout';
        }
    }, []);

    let warningInactive = useCallback((timeString : string) => {
        if (startTimerInterval.current) {
            clearTimeout(startTimerInterval.current);
        }

        warningInactiveInterval.current = setInterval(() => {
            setOpen(true);
            warningCountdown();
        }, 1000);
    }, [setOpen, startTimerInterval, warningInactiveInterval]);

    let resetTimer = useCallback(() => {
        if (startTimerInterval.current) {
            clearTimeout(startTimerInterval.current);
        }
        if (warningInactiveInterval.current) {
            clearInterval(warningInactiveInterval.current);
        }

        timeStamp = moment(new Date()).add(sessionTimeoutMinutes, 'minute');
        sessionStorage.setItem('lastTimeStamp', timeStamp.toString());
        timeChecker();
        setOpen(false);
    }, [user]);

    const handleClose = () => {
        setOpen(false);
        resetTimer();
    };

    useEffect(() => {
        events.forEach((event) => {
            window.addEventListener(event, resetTimer);
        });
    }, [timeChecker]);

    if (!isOpen) {
        return null;
    }

    return (
        <>
            <AutoLogoutModal show={isOpen} handleConfirm={handleClose}/>
        </>
    );
};

export default SessionTimeout;