import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {useApiFetch} from "../Providers/OidcProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faPlus} from "@fortawesome/free-solid-svg-icons";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable, {SortOrder} from "react-bootstrap-table-next";
import {CONTACT_LOG, ContactLogValues} from "../Forms/Tracking/SCEvents/ContactLogForm";
import {Child, List} from "../../pages/RecordLocator";
import {apiEndpoint} from "../../utils/api";
import CircularLoadingIndicator from "../CircularLoadingIndicator";
import {comparator, dateSort} from "../../utils/sort";
import {FormikValues} from "formik";
import {mapFormikValuesForFM} from "../../utils/formHelpers";
import SCEventForm, {mapRawSCEventValues, mapScEventNameToDisplay} from "../Forms/Tracking/SCEvents/SCEventForm";
import {REFERRAL_FIRST_CONTACT, ReferralFirstContactValues} from "../Forms/Tracking/SCEvents/ReferralFirstContactForm";
import {REFERRAL_CLOSED, ReferralClosedValues} from "../Forms/Tracking/SCEvents/ReferralClosedForm";
import {APPOINTMENTS, AppointmentsValues} from "../Forms/Tracking/SCEvents/AppointmentsForm";
import {REFERRAL_OUT, ReferralOutValues} from "../Forms/Tracking/SCEvents/ReferralOutForm";
import {REFERRAL_IN, ReferralInValues} from "../Forms/Tracking/SCEvents/ReferralInForm";
import {REFERRAL_INACTIVE, ReferralInactiveValues} from "../Forms/Tracking/SCEvents/ReferralInactiveForm";
import {FOLDER_TO_ESE, FolderToESEValues,} from "../Forms/Tracking/SCEvents/FolderToESEForm";
import {ON_HOLD, OnHoldValues} from "../Forms/Tracking/SCEvents/OnHoldForm";
import {PACKET_SENT, PacketSentValues} from "../Forms/Tracking/SCEvents/PacketSentForm";
import {PACKET_RETURNED, PacketReturnedValues} from "../Forms/Tracking/SCEvents/PacketReturnedForm";
import {PERIODIC_FOLLOW_UP, PeriodicFollowUpValues} from "../Forms/Tracking/SCEvents/PeriodicFollowUpForm";
import AddSCEModal, {addAnotherEvent, goToDetail} from "../Modal/AddSCEModal";
import {useHistory} from "react-router-dom";
import * as H from 'history';
import {ChildTracking} from "./Tracking";
import GeneralErrorModal from "../Modal/GeneralErrorModal";

const dtSort = (a : any, b : any, sortOrder : string, dataField : string) => {
    if (a?.originalName === 'Referral First Contact') {
        return 1;
    }
    if (b?.originalName === 'Referral First Contact') {
        return -1;
    }
    let result;
    let valueA = a[dataField];
    let valueB = b[dataField];
    valueA = typeof valueA === 'undefined' ? '' : valueA;
    valueB = typeof valueB === 'undefined' ? '' : valueB;

    if (sortOrder === 'desc') {
        result = dataField === 'date' ? dateSort(valueA, valueB, sortOrder) :
            comparator(valueA, valueB);
    } else {
        result = dataField === 'date' ? dateSort(valueA, valueB, sortOrder) :
            comparator(valueB, valueA);
    }
    return result;
}

export const sort = (data : any, sortOrder : string, dataField : string) => {
    const _data = [...data];
    _data.sort((a, b) => dtSort(a,b,sortOrder,dataField));
    return _data;
};

export type SCEventName = CONTACT_LOG
    | REFERRAL_CLOSED
    | REFERRAL_FIRST_CONTACT
    | APPOINTMENTS
    | REFERRAL_OUT
    | REFERRAL_IN
    | REFERRAL_INACTIVE
    | FOLDER_TO_ESE
    | ON_HOLD
    | PACKET_RETURNED
    | PACKET_SENT
    | PERIODIC_FOLLOW_UP;

export type SCEventValues = ContactLogValues
    | ReferralFirstContactValues
    | ReferralClosedValues
    | AppointmentsValues
    | FolderToESEValues
    | ReferralOutValues
    | ReferralInValues
    | ReferralInactiveValues
    | OnHoldValues
    | PacketSentValues
    | PacketReturnedValues
    | PeriodicFollowUpValues;

export type SCEvent = {
    UUID : string;
    name :  SCEventName;
    date : string;
    creationDate: string;
    actionNeeded : string;
    summary : string;
    values? : SCEventValues
};

export type SCDisplayEvent = {
    UUID : string;
    name : JSX.Element;
    date : string;
    actionNeeded : string;
    summary : string;
    originalName : string;
    creationDate : string;
};

const mapSCEventsForDisplay = (
    SCEvent : SCEvent,
    handleSetActiveSCEvent : (serviceCoordinationEvent : SCEvent) => Promise<void>,
    history : H.History
) : SCDisplayEvent => ({
    UUID : SCEvent.UUID,
    name : <div className="btn-link cursor-pointer" onClick={async () => {
        history.push(`/record-locator?sub=trackingSCEvents&event=${SCEvent.UUID}`);
        await handleSetActiveSCEvent(SCEvent);
    }}>{mapScEventNameToDisplay(SCEvent)}</div>,
    date : SCEvent.date ? SCEvent.date : '-',
    actionNeeded : SCEvent.actionNeeded,
    summary : SCEvent.summary ? SCEvent.summary : '-',
    originalName : SCEvent.name,
    creationDate : SCEvent.creationDate
});

type Props = {
    tracking: ChildTracking;
    handleSetShowContactLog : (showContactLog : boolean) => void;
    showContactLog : boolean;
    canSave : boolean;
    canDelete : boolean;
    siteLists : Array<List>;
    activeChild : Child;
    systemAlert : (alertText : string, alertTimeout : number) => Promise<void>;
};

const SCEvents = ({
    tracking,
    handleSetShowContactLog,
    showContactLog,
    canSave,
    canDelete,
    siteLists,
    activeChild,
    systemAlert
} : Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [defaultSortField, setDefaultSortField] = useState<string>('creationDate');
    const [defaultSortOrder, setDefaultSortOrder] = useState<SortOrder>('asc');
    const [activeSCEvent, setActiveSCEvent] = useState<SCEvent | null>(null);
    const [sortedData, setSortedData] = useState<Array<SCDisplayEvent> | null>(null);
    const [showAddSCE, setShowAddSCE] = useState<boolean>(false);
    const [showGeneralErrorModal, setShowGeneralErrorModal] = useState<boolean>(false);

    const apiFetch = useApiFetch();
    const tableRef = useRef<BootstrapTable>(null);
    let history = useHistory();

    const handleSetActiveSCEvent = useCallback(async (serviceCoordinationEvent : SCEvent) => {
        setLoading(true);
        setActiveSCEvent(null);
        const url = new URL(
            `/v1/child/service-coordination-event/`
            + `${activeChild.childUUID}/${serviceCoordinationEvent.UUID}/${serviceCoordinationEvent.name}`,
            apiEndpoint
        );

        const response = await apiFetch(url.toString(), {
            method: 'GET',
        });

        if (response.status === 403) {
            await systemAlert('Invalid access for child', 5000);
            return;
        }

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const data = await response.json();
        serviceCoordinationEvent.values = mapRawSCEventValues(data);
        setActiveSCEvent(serviceCoordinationEvent);
        setLoading(false);
    }, [apiFetch, activeChild, systemAlert]);

    const handleSCEventSubmit = useCallback(async (
        values : FormikValues,
        scEventUUID : string,
        eventDateField : string
    ) => {
        const mappedValues = mapFormikValuesForFM(values);
        const url = new URL(
            `/v1/child/service-coordination-event/`
            + `${activeChild.childUUID}/${scEventUUID}`,
            apiEndpoint
        );

        const response = await apiFetch(url.toString(), {
            method: 'PUT',
            body: JSON.stringify({
                values: mappedValues
            })
        });

        if (response.status === 403) {
            await systemAlert('Invalid access for child', 5000);
            return;
        }

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const data = await response.json();

        if (tracking.serviceCoordinationEvents) {
            const index = tracking.serviceCoordinationEvents.findIndex((scEvent) => scEvent.UUID === scEventUUID);
            const followUpDate = values['DemoEntry to ServiceCoordination by ANUnique::ANFollowUpDate'];
            const completedDate = values['DemoEntry to ServiceCoordination by ANUnique::ANCompletedDate'];
            tracking.serviceCoordinationEvents[index].actionNeeded = followUpDate !== '' && completedDate !== ''
                ? '-'
                : (followUpDate !== '' && completedDate === '' ? 'Yes' : '-');
            tracking.serviceCoordinationEvents[index].summary = data.summary;
            tracking.serviceCoordinationEvents[index].date = values[eventDateField];
            const filteredEvents = showContactLog ? tracking.serviceCoordinationEvents :
                tracking.serviceCoordinationEvents.filter((scEvent ) => scEvent.name !== 'Contact Log');
            setSortedData(sort(filteredEvents.map((data) => mapSCEventsForDisplay(
                data,
                handleSetActiveSCEvent,
                history
            )), defaultSortOrder, defaultSortField));
        }

    }, [
        showContactLog,
        apiFetch,
        activeChild,
        systemAlert,
        tracking.serviceCoordinationEvents,
        handleSetActiveSCEvent,
        history,
        defaultSortOrder,
        defaultSortField
    ]);

    const handleRemoveSCE = useCallback(async (eventid: string) => {
        const url = new URL(
            `/v1/child/service-coordination-event/`
            + `${activeChild.childUUID}/${eventid}`,
            apiEndpoint
        );
        const response = await apiFetch(url.toString(), {
            method: 'DELETE',
        });

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        tracking.serviceCoordinationEvents = tracking.serviceCoordinationEvents.filter((e) => e.UUID !== eventid);
        setSortedData(null);
    }, [activeChild.childUUID, apiFetch, tracking.serviceCoordinationEvents])

    const handleClearActiveSCEvent = useCallback(async () => {
        setActiveSCEvent(null);
    }, [setActiveSCEvent]);

    useEffect(() => {
        if (!sortedData) {
            const filteredEvents = showContactLog ? tracking.serviceCoordinationEvents :
                tracking.serviceCoordinationEvents.filter((scEvent ) => scEvent.name !== 'Contact Log');
            const sortedData = sort(filteredEvents.map((data) => mapSCEventsForDisplay(
                data,
                handleSetActiveSCEvent,
                history
            )), 'asc', 'date');
            setSortedData(sortedData);
        }
    }, [
        sortedData,
        showContactLog,
        tracking.serviceCoordinationEvents
    ]);

    const hideAddSCE = useCallback(() => {
        setShowAddSCE(false);
    }, [setShowAddSCE]);

    const handleAddSCESubmit = useCallback(async (values : FormikValues) => {
        const url = new URL(
            `/v1/child/service-coordination-event/`
            + `${activeChild.childUUID}`,
            apiEndpoint
        );
        const response = await apiFetch(url.toString(), {
            method: 'POST',
            body: JSON.stringify({
                eventDate: values.eventDate,
                eventType: values.eventType
            })
        });

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const responseJson = await response.json();

        const event = {
            UUID: responseJson.SCEventUUID,
            name: values.eventType,
            date: values.eventDate,
            actionNeeded: '-',
            summary: ''
        } as SCEvent;

        tracking.serviceCoordinationEvents.push(event);
        setSortedData(null);

        switch (values.nextAction ) {
            case addAnotherEvent:
                return true;
            case goToDetail:
                await handleSetActiveSCEvent(event);
                break;
        }

        setShowAddSCE(false);
        return false;
    }, [activeChild.childUUID, apiFetch, handleSetActiveSCEvent, tracking.serviceCoordinationEvents]);

    const handleOnSort = (field : string, order: SortOrder) => {
        if (tableRef.current) {
            setSortedData(sort(tableRef.current.props.data, order, field));
            setDefaultSortField(field);
            setDefaultSortOrder(order);
        }
    };

    const columns = [
        {
            dataField: 'UUID',
            sort: false,
            hidden: true,
            text: 'UUID'
        },
        {
            dataField: 'name',
            text: 'Event',
            sort: false,
            classes: 'medium-cell-width',
            headerClasses: 'medium-cell-width'
        },
        {
            dataField: 'date',
            text: 'Date',
            sort: true,
            onSort: handleOnSort,
            classes: 'medium-cell-width',
            headerClasses: 'medium-cell-width',
            sortFunc: dateSort
        },
        {
            dataField: 'actionNeeded',
            text: 'AN',
            sort: true,
            onSort: handleOnSort,
            classes: 'd-none d-lg-table-cell text-wrap overflow-hidden min-cell-width',
            headerClasses: 'd-none d-lg-table-cell min-cell-width'
        },
        {
            dataField: 'summary',
            text: 'Summary',
            sort: false
        },
        {
            dataField: 'creationDate',
            sort: false,
            hidden: true,
            text: 'creationDate'
        }
    ];

    return (
        <>
        {loading ? (
            <Col sm={12} className="bg-white py-3 mx-0 px-0">
                <CircularLoadingIndicator/>
            </Col>
        ) : (
            <>
                {activeSCEvent && sortedData && activeSCEvent.values
                    ? <SCEventForm
                        canSave={canSave}
                        canDelete={canDelete}
                        siteLists={siteLists}
                        activeChild={activeChild}
                        SCEvent={activeSCEvent}
                        handleClearActiveSCEvent={handleClearActiveSCEvent}
                        eventValues={activeSCEvent.values}
                        sortedEvents={sortedData}
                        serviceCoordinationEvents={tracking.serviceCoordinationEvents}
                        handleSetActiveSCEvent={handleSetActiveSCEvent}
                        handleSCEventSubmit={handleSCEventSubmit}
                        eventName={activeSCEvent.name}
                        handleRemoveSCE={handleRemoveSCE}
                    />
                    :
                    <div className="chris-form-bg pb-4">
                        <Row className="mx-0 p-2 pt-4">
                            <Col xs={12} lg={4} className="d-flex flex-row justify-content-start">
                                {activeChild.access.includes('w') && <Button
                                    variant="secondary"
                                    type="button"
                                    className="d-flex pt-2 flex-row align-content-center"
                                    disabled={loading}
                                    onClick={async () => {
                                        setShowAddSCE(true);
                                    }}
                                ><FontAwesomeIcon icon={faPlus} size="sm" className="mt-1"/>&nbsp; Add Event</Button>}
                            </Col>
                            <Col
                                xs={12}
                                lg={8}
                                className="d-flex flex-row justify-content-start justify-content-lg-end"
                            >

                            </Col>
                            <Col xs={12}>
                                <hr />
                            </Col>
                        </Row>
                        <Row className="mx-4 significant-adult sc-event-header">
                            <Col xs={12} lg={6} className="pt-4">
                                Service Coordination Events
                            </Col>
                            <Col
                                xs={12}
                                lg={6}
                                className="d-flex flex-row justify-content-start justify-content-lg-end pt-3"
                            >
                                <div className="mt-2 mr-2">Contact Log Events:</div>
                                <Button
                                    variant="outline-secondary"
                                    type="button"
                                    className="d-flex pt-2 flex-row align-content-center"
                                    disabled={loading}
                                    onClick={async () => {
                                        setSortedData(null);
                                        handleSetShowContactLog(!showContactLog);
                                    }}
                                >
                                    {showContactLog
                                        ? <><FontAwesomeIcon icon={faEyeSlash} size="sm" className="mt-1"/>&nbsp; Hide</>
                                        : <><FontAwesomeIcon icon={faEye} size="sm" className="mt-1"/>&nbsp; Show</>}
                                </Button>
                            </Col>
                            <Col xs={12} className="mx-0 px-0">
                                <hr/>
                            </Col>
                            <Col xs={12}>
                                {tracking.serviceCoordinationEvents.length > 0 && sortedData ? (
                                    <BootstrapTable
                                        bootstrap4
                                        keyField='UUID'
                                        data={sortedData}
                                        columns={columns}
                                        pagination={paginationFactory({sizePerPage: 20})}
                                        striped
                                        defaultSorted={[{ dataField: defaultSortField, order: defaultSortOrder}]}
                                        ref={tableRef}
                                    />
                                ) : (
                                    <div className="d-flex py-3 flex-row justify-content-center">
                                        <span>No Service Coordination events.</span>
                                        {canSave && <div className="btn-link cursor-pointer ml-2">
                                            <Button
                                                variant="secondary"
                                                type="button"
                                                className="d-flex pt-2 flex-row align-content-center"
                                                disabled={loading}
                                                onClick={async () => {
                                                    setShowAddSCE(true);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faPlus} size="sm" className="mt-1"/>&nbsp; Add Event
                                            </Button>
                                        </div>}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                }
                {showAddSCE && <AddSCEModal
                    show={showAddSCE}
                    handleSubmitAddSCE={handleAddSCESubmit}
                    handleClose={hideAddSCE}
                />}
            </>
        )}
            <GeneralErrorModal
                show={showGeneralErrorModal}
                handleShowGeneralErrorModal={setShowGeneralErrorModal}
            />
        </>
    );
};

export default SCEvents;
