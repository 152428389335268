import React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import ActiveChildProvider from "./components/Providers/ActiveChildProvider";
import ChrisUserProvider from "./components/Providers/ChrisUserProvider";
import ReportsProvider from "./components/Providers/ReportsProvider";
import CosForm from './pages/CosForm';
import Help from "./pages/Help";
import ManageLists from "./pages/ManageLists";
import NotFound from './pages/NotFound';
import RecordLocator from "./pages/RecordLocator";
import Reports from "./pages/Reports";
import SignOut from "./pages/SignOut";
import RecordLocatorProvider from "./components/Providers/RecordLocatorProvider";
import SignOutCallback from "./pages/Oidc/SignOutCallback";

const Routes : React.FC = () => (
    <Switch>
        <Switch>
            <ChrisUserProvider>
                <RecordLocatorProvider>
                    <ActiveChildProvider>
                        <Route path="/cos-form" exact component={CosForm}/>
                        <Route path="/manage-lists" exact component={ManageLists}/>
                        <ReportsProvider>
                            <Route path="/reports" exact component={Reports}/>
                        </ReportsProvider>
                        <Route path="/help" exact component={Help}/>
                        <Route path="/record-locator" exact component={RecordLocator}/>
                    </ActiveChildProvider>
                    <Route exact path="/">
                        <Redirect to="/record-locator" />
                    </Route>
                    <Route path="/oidc/sign-out-callback" exact component={SignOutCallback}/>
                    <Route path="/logout" exact component={SignOut}/>
                </RecordLocatorProvider>
            </ChrisUserProvider>
            <Route path="*" component={NotFound}/>
        </Switch>
    </Switch>
);

export default Routes;
