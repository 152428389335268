import React from 'react';
import {Nav} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

export type ActiveTrackingTab = 'trackingGeneral' | 'trackingSCEvents' | 'trackingTimeline';

type Props = {
    activeTrackingTab : ActiveTrackingTab;
    handleSetActiveTrackingTab : (activeTab : ActiveTrackingTab) => void;
}

const TrackingSubNav = ({activeTrackingTab, handleSetActiveTrackingTab} : Props) => {
    let history = useHistory();

    return (
        <Nav className="flex-column pb-xs-0 pb-lg-5 demo-nav" onSelect={(eventKey: any) => {
            history.push(`/record-locator?sub=${eventKey}`);
            if (history.location.search === `?sub=${eventKey}`) {
                handleSetActiveTrackingTab(eventKey);
            }
        }} activeKey={activeTrackingTab}>
            <Nav.Item>
                <Nav.Link className="p-3" eventKey="trackingGeneral">
                    <FontAwesomeIcon icon={faPencilAlt} size={"lg"}/> &nbsp; General
                </Nav.Link>
                <Nav.Link className="p-3" eventKey="trackingSCEvents">
                    <FontAwesomeIcon icon={faCalendarAlt} size={"lg"}/> &nbsp; SC Events
                </Nav.Link>
                <Nav.Link className="p-3" eventKey="trackingTimeline">
                    <FontAwesomeIcon icon={faCalendarAlt} size={"lg"}/> &nbsp; Timeline Events
                </Nav.Link>
            </Nav.Item>
        </Nav>
    );
};

export default TrackingSubNav;