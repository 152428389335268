import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../../Fields/StandardField";
import * as yup from 'yup';
import {List} from "../../../../pages/RecordLocator";
import {getOptionsForField, yupDateRegex} from "../../../../utils/formHelpers";
import {BaseFormValues} from "./SCEventForm";

export const APPOINTMENTS_STRING = 'Appointments';
export type APPOINTMENTS = typeof APPOINTMENTS_STRING;

export type AppointmentsValues = BaseFormValues & {
    ApptAddress : string;
    ApptConfirmed : string;
    ApptDate : string;
    ApptDirections : string;
    ApptEndTime : string;
    ApptLocation : string;
    ApptProvider1 : string;
    ApptProvider2 : string;
    ApptProvider3 : string;
    ApptProvider4 : string;
    ApptProvider5 : string;
    ApptStatus : string;
    ApptTime : string;
    ApptTransport : string;
    ApptType1 : string;
    ApptType2 : string;
    ApptType3 : string;
    ApptType4 : string;
    ApptType5 : string;
};

export const AppointmentsSchema = {
    ApptDate: yupDateRegex.required('You must enter a date'),
    ApptAddress: yup.string(),
    ApptConfirmed: yup.string(),
    ApptDirections: yup.string(),
    ApptEndTime: yup.string(),
    ApptLocation: yup.string(),
    ApptProvider1: yup.string(),
    ApptProvider2: yup.string(),
    ApptProvider3: yup.string(),
    ApptProvider4: yup.string(),
    ApptProvider5: yup.string(),
    ApptStatus: yup.string(),
    ApptTime: yup.string(),
    ApptTransport: yup.string(),
    ApptType1: yup.string(),
    ApptType2: yup.string(),
    ApptType3: yup.string(),
    ApptType4: yup.string(),
    ApptType5: yup.string()
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
};

const AppointmentsForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptDate"
                        type="date"
                        label="Appointment Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptTime"
                        type="time"
                        label="Time"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptEndTime"
                        type="time"
                        label="End Time"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptConfirmed"
                        type="select"
                        label="Confirmed"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'YN',
                            '',
                            siteLists
                        )}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptStatus"
                        type="select"
                        label="Status"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ApptStatus',
                            '',
                            siteLists
                        )}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptLocation"
                        type="select"
                        label="Location"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'LocationList',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptAddress"
                        type="text"
                        label="Address"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptDirections"
                        type="text"
                        label="Directions"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptTransport"
                        type="text"
                        label="Transport"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptProvider1"
                        type="select"
                        label="Provider/Staff"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptType1"
                        type="select"
                        label="Appointment Type"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'AppointmentTypeList',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptProvider2"
                        type="select"
                        label="Provider/Staff 2"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptType2"
                        type="select"
                        label="Appointment Type 2"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'AppointmentTypeList',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptProvider3"
                        type="select"
                        label="Provider/Staff 3"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptType3"
                        type="select"
                        label="Appointment Type 3"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'AppointmentTypeList',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptProvider4"
                        type="select"
                        label="Provider/Staff 4"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptType4"
                        type="select"
                        label="Appointment Type 4"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'AppointmentTypeList',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptProvider5"
                        type="select"
                        label="Provider/Staff 5"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListA',
                            '',
                            siteLists
                        )}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ApptType5"
                        type="select"
                        label="Appointment Type 5"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'AppointmentTypeList',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
        </>
    );
};

export default AppointmentsForm;