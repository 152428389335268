import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {Child, List} from "../../pages/RecordLocator";
import {ChildTracking} from "./Tracking";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import {useHistory} from "react-router-dom";
import {apiEndpoint} from "../../utils/api";
import {useApiFetch} from "../Providers/OidcProvider";
import {
    mapFormikValuesForFM
} from "../../utils/formHelpers";
import {
    TRANSITION,
    TRANSITION_STRING,
    TransitionValues
} from "../Forms/Tracking/TimelineEvents/TransitionForm";
import {
    SCREENING,
    SCREENING_STRING,
    ScreeningValues
} from "../Forms/Tracking/TimelineEvents/ScreeningForm";
import {
    EVALUATION,
    EVALUATION_STRING,
    EvaluationValues
} from "../Forms/Tracking/TimelineEvents/EvaluationForm";
import {
    ESE_ELIGIBILITY,
    ESE_ELIGIBILITY_STRING,
    ESEEligibilityValues
} from "../Forms/Tracking/TimelineEvents/ESEEligibilityForm";
import {IEP_IFSP, IEP_IFSP_STRING, IEPIFSPValues} from "../Forms/Tracking/TimelineEvents/IEPIFSPForm";
import {
    END_TIMELINE,
    END_TIMELINE_STRING,
    EndTimelineValues
} from "../Forms/Tracking/TimelineEvents/EndTimelineForm";
import TimelineEventForm, {mapRawTimelineEventValues} from "../Forms/Tracking/TimelineEvents/TimelineEventForm";
import CircularLoadingIndicator from "../CircularLoadingIndicator";
import {FormikValues} from "formik";
import {getNextPrevEvent, NextPrev} from "../Forms/Tracking/TimelineEvents/TimelineEventControls";
import Spinner from "react-bootstrap/Spinner";
import AddTimelineErrorModal from "../Modal/AddTimelineErrorModal";
import AddTimelineUnder3Modal from "../Modal/AddTimelineUnder3Modal";
import TimelineErrorConfirmationModal from "../Modal/TimelineErrorConfirmation";
import GeneralErrorModal from "../Modal/GeneralErrorModal";
import AddTimelineConfirmModal from "../Modal/AddTimelineConfirmModal";

export type TimelineEventName = TRANSITION
    | SCREENING
    | EVALUATION
    | ESE_ELIGIBILITY
    | IEP_IFSP
    | END_TIMELINE;

export type TimelineEventValues = TransitionValues
    | ScreeningValues
    | EvaluationValues
    | ESEEligibilityValues
    | IEPIFSPValues
    | EndTimelineValues;

export type Timeline = {
    timelineUUID : string;
    timeElapsed : string;
    timelineCount : string;
    timelineEvents : Array<TimelineEvent>
};

export type TimelineEvent = {
    displayUUID : string;
    name : TimelineEventName | JSX.Element;
    date : string;
    actionNeeded : string;
    summary : string;
    daysElapsed : string;
    values? : any;
    hasRequiredDates : boolean;
};

export const getTimelineNavigationError : any = {
    'Transition' : 'This Timeline is closed!! You cannot create a transition event once a timeline has been closed.',
    'Screening' : 'This Timeline is closed!! You cannot create a transition event once a timeline has been closed.',
    'Evaluation' : 'Final Screening Date and/or Final Screening Result should filled in prior to creating an Evaluation event.',
    'ESE Eligibility' : 'Final Evaluation Date and/or Parent Consent Date should filled in prior to creating an ESE Eligibility event.',
    'IEP/IFSP' : 'ESE Eligibility Date should be filled out prior to creating an IEP/IFSP event.',
    'End Timeline' : ''
};

type Props = {
    tracking: ChildTracking;
    canSave : boolean;
    canDelete : boolean;
    siteLists : Array<List>;
    activeChild : Child;
    systemAlert : (alertText : string, alertTimeout : number) => Promise<void>;
};

const TimelineEvents = ({
    tracking,
    canSave,
    siteLists,
    activeChild,
    systemAlert
} : Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingAddTimeline, setLoadingAddTimeline] = useState<boolean>(false);
    const [timelinesEnded, setTimelinesEnded] = useState<boolean>(false);
    const [showAddTimelineErrorModal, setShowAddTimelineErrorModal] = useState<boolean>(false);
    const [showAddTimelineUnder3Modal, setShowAddTimelineUnder3Modal] = useState<boolean>(false);
    const [showAddTimelineConfirmModal, setShowAddTimelineConfirmModal] = useState<boolean>(false);
    const [currentTimeline, setCurrentTimeline] = useState<Timeline | null>(null);
    const [activeTimelineEvent, setActiveTimelineEvent] = useState<TimelineEvent | null>(null);
    const [totalDaysElapsed, setTotalDaysElapsed] = useState<number>(0);
    const [showTimelineErrorConfirmationModal, setShowTimelineErrorConfirmationModal] = useState<boolean>(
        false
    );
    const [clickedTimelineEvent, setClickedTimelineEvent] = useState<TimelineEvent | null>(null);
    const [showGeneralErrorModal, setShowGeneralErrorModal] = useState<boolean>(false);

    let history = useHistory();
    const apiFetch = useApiFetch();

    const handleShowAddTimelineErrorModal = useCallback((show : boolean) => {
        setShowAddTimelineErrorModal(show);
    }, [setShowAddTimelineErrorModal]);

    const calcTotalTimelineDays = useCallback((timeline : Timeline) => {
        let days = 0;
        timeline.timelineEvents.map((timelineEvent) => {
            days += timelineEvent.daysElapsed ? parseInt(timelineEvent.daysElapsed) : 0;
            return null;
        });
        setTotalDaysElapsed(days);
    }, [setTotalDaysElapsed]);

    const handleSetCurrentTimeline = useCallback(async (timeline : Timeline) => {
        history.push(
            `/record-locator?sub=trackingTimeline&event=${timeline.timelineUUID}`
        );
        setCurrentTimeline(timeline);
        calcTotalTimelineDays(timeline);
    }, [setCurrentTimeline, history, calcTotalTimelineDays]);

    const handleSetTimelinesEnded = useCallback( (timelines : Array<Timeline>) => {
        let timelinesEnded = true;
        timelines.map((timeline) => {
            const endTimeline = timeline.timelineEvents.find(
                (timelineEvent) => timelineEvent.name === 'End Timeline'
            );
            if (endTimeline && endTimeline.date === '') {
                timelinesEnded = false;
            }
            return null;
        });
        setTimelinesEnded(timelinesEnded);
    }, [setTimelinesEnded]);

    const handleSetActiveTimelineEvent = useCallback(async (timelineEvent : TimelineEvent) => {
        history.push(`/record-locator?sub=trackingTimeline&event=${timelineEvent.name}`);
        setLoading(true);
        setActiveTimelineEvent(null);
        const eventName = typeof timelineEvent.name === 'string'
            ? (timelineEvent.name === IEP_IFSP_STRING ? 'IEPIFSP' : timelineEvent.name) : '';

        const url = new URL(
            `/v1/child/timeline-event/`
            + `${activeChild.childUUID}/${currentTimeline?.timelineUUID}/${eventName}`,
            apiEndpoint
        );

        const response = await apiFetch(url.toString(), {
            method: 'GET',
        });

        if (response.status === 403) {
            await systemAlert('Invalid access for child', 5000);
            return;
        }

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const data = await response.json();
        timelineEvent.values = mapRawTimelineEventValues(data);
        setActiveTimelineEvent(timelineEvent);
        setLoading(false);
    }, [apiFetch, activeChild, systemAlert, currentTimeline, history]);

    const handleTimelineEventSubmit = useCallback(async (
        values : FormikValues,
        eventName : string
    ) => {
        const urlEventName = eventName === IEP_IFSP_STRING ? 'IEPIFSP' : eventName;
        const mappedValues = mapFormikValuesForFM(values);
        const url = new URL(
            `/v1/child/timeline-event/`
            + `${activeChild.childUUID}/${currentTimeline?.timelineUUID}/${urlEventName}`,
            apiEndpoint
        );

        const response = await apiFetch(url.toString(), {
            method: 'PUT',
            body: JSON.stringify({
                values: mappedValues
            })
        });

        if (response.status === 403) {
            await systemAlert('Invalid access for child', 5000);
            return;
        }

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const TEUrl = new URL(
            `/v1/child/timeline-events/${activeChild.childUUID}/`,
            apiEndpoint
        );

        const TEResponse = await apiFetch(TEUrl.toString(), {
            method: 'GET'
        });

        if (TEResponse.status === 403) {
            await systemAlert('Invalid access for child', 5000);
            return;
        }

        if (!TEResponse.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const TEData = await TEResponse.json();

        if (tracking.timelines && currentTimeline) {
            const index = tracking.timelines.findIndex(
                (timeline) => timeline.timelineUUID === currentTimeline.timelineUUID
            );

            const refreshedTimeline = TEData.find((timeline : Timeline) => timeline.timelineUUID === tracking.timelines[index].timelineUUID);

            tracking.timelines[index].timelineEvents.map((timelineEvent, timelineEventMapIndex) => {
                const refreshedTimelineEvent : TimelineEvent = refreshedTimeline.timelineEvents.find(
                    (timelineEventRefreshed: TimelineEvent) => timelineEventRefreshed.name === timelineEvent.name
                );

                tracking.timelines[index].timelineEvents[timelineEventMapIndex].daysElapsed = refreshedTimelineEvent.daysElapsed;
                tracking.timelines[index].timelineEvents[timelineEventMapIndex].date = refreshedTimelineEvent.date;
                tracking.timelines[index].timelineEvents[timelineEventMapIndex].summary = refreshedTimelineEvent.summary;
                tracking.timelines[index].timelineEvents[timelineEventMapIndex].actionNeeded = refreshedTimelineEvent.actionNeeded;
                tracking.timelines[index].timelineEvents[timelineEventMapIndex].hasRequiredDates = refreshedTimelineEvent.hasRequiredDates;

                return null;
            });

            if (eventName === 'End Timeline') {
                handleSetTimelinesEnded(tracking.timelines);
            }

            calcTotalTimelineDays(tracking.timelines[index]);
        }
    }, [
        apiFetch,
        activeChild,
        systemAlert,
        currentTimeline,
        handleSetTimelinesEnded,
        tracking.timelines,
        calcTotalTimelineDays
    ]);

    const handleAddTimeline = useCallback(async () => {
        const url = new URL(
            `/v1/child/timeline-event/`
            + `${activeChild.childUUID}`,
            apiEndpoint
        );
        const response = await apiFetch(url.toString(), {
            method: 'POST'
        });

        if (!response.ok) {
            setShowGeneralErrorModal(true);
            return;
        }

        const responseJson = await response.json();

        const timeline = {
            timelineUUID : responseJson.timelineUUID,
            timeElapsed : '0',
            timelineCount : '',
            timelineEvents : [
                {
                    displayUUID : '1',
                    name : TRANSITION_STRING,
                    date : '',
                    actionNeeded : '-',
                    summary : '',
                    daysElapsed : '',
                    hasRequiredDates : false
                },
                {
                    displayUUID : '2',
                    name : SCREENING_STRING,
                    date : '',
                    actionNeeded : '-',
                    summary : '',
                    daysElapsed : '',
                    hasRequiredDates : false
                },
                {
                    displayUUID : '3',
                    name : EVALUATION_STRING,
                    date : '',
                    actionNeeded : '-',
                    summary : '',
                    daysElapsed : '',
                    hasRequiredDates : false
                },
                {
                    displayUUID : '4',
                    name : ESE_ELIGIBILITY_STRING,
                    date : '',
                    actionNeeded : '-',
                    summary : '',
                    daysElapsed : '',
                    hasRequiredDates : false
                },
                {
                    displayUUID : '5',
                    name : IEP_IFSP_STRING,
                    date : '',
                    actionNeeded : '-',
                    summary : '',
                    daysElapsed : '',
                    hasRequiredDates : false
                },
                {
                    displayUUID : '6',
                    name : END_TIMELINE_STRING,
                    date : '',
                    actionNeeded : '-',
                    summary : '',
                    daysElapsed : '',
                    hasRequiredDates : false
                }
            ]
        } as Timeline;

        tracking.timelines = [timeline, ...tracking.timelines];
        setCurrentTimeline(null);
        setCurrentTimeline(timeline);
        setTimelinesEnded(false);
        setShowAddTimelineUnder3Modal(false);
        setShowAddTimelineConfirmModal(false);
    }, [activeChild.childUUID, apiFetch, tracking.timelines]);

    const mapTimelineEventForDisplay = (timelineEvent : TimelineEvent, timelineEvents : Array<TimelineEvent>) => {
        const eventName = typeof timelineEvent.name === 'string' ? timelineEvent.name : '';
        const nextPrev : NextPrev = getNextPrevEvent[eventName];
        const prevEvent = nextPrev.prev ? timelineEvents.find(
            (timelineEvent) => timelineEvent.name === nextPrev.prev
        ) : null;
        const endTimelineEvent = nextPrev.next ? timelineEvents.find(
            (timelineEvent) => timelineEvent.name === END_TIMELINE_STRING
        ) : null;

        let canEditEvent = false;

        if ((prevEvent && prevEvent.hasRequiredDates) || timelineEvent.name === END_TIMELINE_STRING) {
            canEditEvent = true;
        }

        if ((timelineEvent.name === TRANSITION_STRING) || timelineEvent.name === SCREENING_STRING) {
            canEditEvent = true;
        }

        if (timelineEvent.date !== '') {
            canEditEvent = true;
        }

        if (endTimelineEvent
            && timelineEvent.name === TRANSITION_STRING
            && timelineEvent.date === ''
            && endTimelineEvent.date !== '') {
            canEditEvent = false;
        }

        const editableEvent = <div
            className="btn-link cursor-pointer"
            onClick={async () => {
                await handleSetActiveTimelineEvent(timelineEvent);
            }}
        >{timelineEvent.name}</div>;

        const nonEditableEvent = <div
            className="btn-link cursor-pointer"
            onClick={async () => {
                setClickedTimelineEvent(timelineEvent);
                setShowTimelineErrorConfirmationModal(true);
            }}
        >{timelineEvent.name}</div>;

        return ({
            ...timelineEvent,
            name: canEditEvent ? editableEvent : nonEditableEvent
        });
    };

    const handleClearActiveTimelineEvent = useCallback(async () => {
        setActiveTimelineEvent(null);
    }, [setActiveTimelineEvent]);

    const handleClickAddTimeline = async () => {
        if (!timelinesEnded) {
            handleShowAddTimelineErrorModal(true);
            return;
        }

        if (parseInt(activeChild.age) < 2.4) {
            setShowAddTimelineUnder3Modal(true);
            return;
        }

        setShowAddTimelineConfirmModal(true);
    };

    useEffect(() => {
        if (tracking.timelines.length > 0) {
            setCurrentTimeline(tracking.timelines[0]);
            setTotalDaysElapsed(tracking.timelines[0]?.timeElapsed
                ? parseInt(tracking.timelines[0].timeElapsed) : 0
            );
            handleSetTimelinesEnded(tracking.timelines);
        } else {
            setTimelinesEnded(true);
        }
    }, [tracking.timelines.length]);

    const columns = [
        {
            dataField: 'displayUUID',
            sort: false,
            hidden: true,
            text: 'displayUUID'
        },
        {
            dataField: 'name',
            text: 'Event',
            sort: false,
            classes: 'medium-cell-width',
            headerClasses: 'medium-cell-width'
        },
        {
            dataField: 'date',
            text: 'Date',
            sort: false,
            classes: 'medium-cell-width',
            headerClasses: 'medium-cell-width'
        },
        {
            dataField: 'actionNeeded',
            text: 'AN',
            sort: false,
            classes: 'd-none d-lg-table-cell text-wrap overflow-hidden min-cell-width',
            headerClasses: 'd-none d-lg-table-cell min-cell-width'
        },
        {
            dataField: 'summary',
            text: 'Summary',
            sort: false
        },
        {
            dataField: 'daysElapsed',
            text: 'Days',
            sort: false,
            classes: 'd-none d-lg-table-cell text-wrap overflow-hidden min-cell-width',
            headerClasses: 'd-none d-lg-table-cell min-cell-width'
        }
    ];

    return (
        <>
            {loading ? (
                <Col sm={12} className="bg-white py-3 mx-0 px-0">
                    <CircularLoadingIndicator/>
                </Col>
            ) : (
                <>
                    <TimelineErrorConfirmationModal
                        show={showTimelineErrorConfirmationModal}
                        handleClose={() => { setShowTimelineErrorConfirmationModal(false); }}
                        handleConfirm={async () => {
                            if (clickedTimelineEvent) {
                                await handleSetActiveTimelineEvent(clickedTimelineEvent);
                                setClickedTimelineEvent(null);
                                setShowTimelineErrorConfirmationModal(false);
                            }
                        }}
                        message={getTimelineNavigationError[clickedTimelineEvent?.name ? clickedTimelineEvent?.name.toString() : '']}
                        confirmText={`Continue to ${clickedTimelineEvent?.name} event`}
                    />
                    <AddTimelineErrorModal
                        show={showAddTimelineErrorModal}
                        handleShowAddTimelineErrorModal={handleShowAddTimelineErrorModal}
                    />
                    <AddTimelineUnder3Modal
                        show={showAddTimelineUnder3Modal}
                        handleShowAddTimelineUnder3Modal={setShowAddTimelineUnder3Modal}
                        handleAddTimeline={handleAddTimeline}
                    />
                    <AddTimelineConfirmModal
                        show={showAddTimelineConfirmModal}
                        handleShowModal={setShowAddTimelineConfirmModal}
                        handleAddTimeline={handleAddTimeline}
                    />
                    {activeTimelineEvent && activeTimelineEvent.values && currentTimeline?.timelineEvents
                        ? <TimelineEventForm
                            canSave={canSave}
                            siteLists={siteLists}
                            activeChild={activeChild}
                            timelineEvent={activeTimelineEvent}
                            handleClearActiveTimelineEvent={handleClearActiveTimelineEvent}
                            eventValues={activeTimelineEvent.values}
                            timelineEvents={currentTimeline?.timelineEvents}
                            handleSetActiveTimelineEvent={handleSetActiveTimelineEvent}
                            handleTimelineEventSubmit={handleTimelineEventSubmit}
                            eventName={typeof activeTimelineEvent?.name === 'string' ? activeTimelineEvent.name : ''}
                        />
                        :
                        <div className="chris-form-bg py-4">
                            <Row className="mx-0 p-2">
                                <Col xs={12} lg={4} className="d-flex flex-row justify-content-start">
                                    {activeChild.access.includes('w') && <Button
                                        variant="secondary"
                                        type="button"
                                        className="d-flex pt-2 flex-row align-content-center"
                                        disabled={loadingAddTimeline}
                                        onClick={handleClickAddTimeline}
                                    >{loadingAddTimeline ? (
                                        <>
                                            <Spinner animation="border" size="sm" className="mt-1"/>
                                            &nbsp; Adding Timeline
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faPlus} size="sm" className="mt-1"/>
                                            &nbsp; Add Timeline
                                        </>
                                    )}</Button>}
                                </Col>
                                <Col
                                    xs={12}
                                    lg={8}
                                    className="d-flex flex-row justify-content-start justify-content-lg-end"
                                >

                                </Col>
                                <Col xs={12}>
                                    <hr />
                                </Col>
                            </Row>
                            <Row className="mx-4 significant-adult sc-event-header">
                                {tracking.timelines.length > 0 && (
                                <>
                                <Col xs={12} lg={4} className="pt-4">
                                </Col>
                                <Col
                                    xs={12}
                                    lg={4}
                                    className="pt-4 d-flex flex-row justify-content-start justify-content-lg-center"
                                >
                                    <div className="pt-2 pr-1">Timeline #</div>
                                    <select
                                        className="form-control te-timelines-select"
                                        onChange={
                                            async (e) => {
                                                const timeline = tracking.timelines.find(
                                                    (timeline) =>
                                                        timeline.timelineUUID === e.currentTarget.value
                                                );

                                                if (timeline) {
                                                    await handleSetCurrentTimeline(timeline);
                                                }
                                            }
                                        }
                                        defaultValue={currentTimeline?.timelineUUID}
                                    >
                                        {tracking.timelines.map((timeline, index) => (
                                            <option
                                                key={index}
                                                value={timeline.timelineUUID}
                                            >{tracking.timelines.length - index}</option>
                                        ))}
                                    </select>
                                    <div
                                        className="pt-2 pl-1"
                                    >of &nbsp; <strong>{tracking.timelines.length}</strong> &nbsp;</div>
                                </Col>
                                <Col
                                    xs={12}
                                    lg={4}
                                    className="pt-4 d-flex flex-row justify-content-start justify-content-lg-end"
                                >
                                    Time Elapsed: &nbsp; <strong>{totalDaysElapsed} days</strong>
                                </Col>
                                <Col xs={12} className="mx-0 px-0">
                                    <hr/>
                                </Col>
                                </>
                                )}
                                <Col xs={12}>
                                    {currentTimeline ? (
                                        <BootstrapTable
                                            bootstrap4
                                            keyField='displayUUID'
                                            data={currentTimeline?.timelineEvents.map(
                                                (data) =>
                                                    mapTimelineEventForDisplay(data, currentTimeline?.timelineEvents)
                                            )}
                                            columns={columns}
                                            striped
                                        />
                                    ) : (
                                        <div className="d-flex py-3 flex-row justify-content-center">
                                            <div className="pt-2">No Timelines available.</div>
                                            {canSave && <div className="btn-link cursor-pointer ml-2">
                                                <div
                                                    className="d-flex pt-2 flex-row align-content-center btn-link"
                                                    onClick={handleClickAddTimeline}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        size="sm"
                                                        className="mt-1"
                                                    />&nbsp; Add New Timeline
                                                </div>
                                            </div>}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    }
                </>
            )}
            <GeneralErrorModal
                show={showGeneralErrorModal}
                handleShowGeneralErrorModal={setShowGeneralErrorModal}
            />
        </>
    );
};

export default TimelineEvents;
