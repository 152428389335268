import React, {ReactNode} from 'react';
import classNames from "classnames";
import {SiteForm} from "./Forms";
import {TreeNodeInArray} from "react-simple-tree-menu/dist/TreeMenu/walk";
import FormsSelect from "../../images/formsSelect.png";
import TreeMenu from "react-simple-tree-menu";
import {ListGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Report} from "../../pages/Reports";
import {ChrisUserProviderContext} from "../Providers/ChrisUserProvider";

export type TreeData = {
    key : string;
    label : string;
    nodes : Array<TreeData> | null;
};

export type CustomItem = {
    hasNodes : boolean;
    isOpen : boolean;
    level : number;
    key : string;
    label : string | ReactNode;
    [name : string] : any;
    active? : boolean;
    onClick : (event: React.MouseEvent<HTMLLIElement>) => void;
    toggleNode? : () => void;
}

export const DEFAULT_PADDING = 0.75;
export const ICON_SIZE = 2;
export const LEVEL_SPACE = 1.75;
export const ToggleIconCustom = ({
    on,
    openedIcon,
    closedIcon,
} : {
    on: boolean;
    openedIcon: ReactNode;
    closedIcon: ReactNode;
}) => (
    <div role="img" aria-label="Toggle" className="rstm-toggle-icon-symbol">
        {on ? openedIcon : closedIcon}
    </div>
);

export const ItemComponentCustom: React.FunctionComponent<CustomItem> = ({
    hasNodes = false,
    isOpen = false,
    level = 0,
    onClick,
    toggleNode,
    active,
    focused,
    openedIcon = '-',
    closedIcon = '+',
    label = 'unknown',
    style = {}
}) => (
    <li
        className={classNames(
            'rstm-tree-item',
            `rstm-tree-item-level${level}`,
            { 'rstm-tree-item--active': active },
            { 'rstm-tree-item--focused': focused }
        )}
        style={{
            paddingLeft: `${DEFAULT_PADDING +
            ICON_SIZE * (hasNodes ? 0 : .3) +
            level * LEVEL_SPACE}rem`,
            ...style,
        }}
        role="button"
        aria-pressed={active}
        onClick={e => {
            hasNodes && toggleNode && toggleNode();
            onClick(e);
        }}
    >
        {hasNodes && (
            <div
                className="rstm-toggle-icon"
                onClick={e => {
                    hasNodes && toggleNode && toggleNode();
                    e.stopPropagation();
                }}
            >
                <ToggleIconCustom on={isOpen} openedIcon={openedIcon} closedIcon={closedIcon} />
            </div>
        )}
        {label}
    </li>
);

export const mapTreeData = (parentFormReport : SiteForm, siteForms : Array<SiteForm>) : TreeNodeInArray => {
    const children = siteForms.filter(siteFormReport => siteFormReport.parentId === parentFormReport.id);
    return {
        key: parentFormReport.id,
        label: parentFormReport.short ? parentFormReport.short : parentFormReport.title,
        isParent: parentFormReport.isParent,
        formId: parentFormReport.id,
        nodes: children.map((siteFormReportChild) : TreeNodeInArray => {
            return {
                key: siteFormReportChild.id,
                label: siteFormReportChild.short ? siteFormReportChild.short : siteFormReportChild.title,
                isParent: siteFormReportChild.isParent,
                formId: siteFormReportChild.id
            }
        }),
    };
};

type AvailableType = 'Report' | 'Form';

type Props = {
    siteFormsReports : Array<SiteForm | Report>;
    siteName : string | null;
    handleSetActiveFormReport : ((siteForm : SiteForm) => void) | ((report : Report) => void);
    type : AvailableType;
};

const AvailableFormsReports = ({
    siteFormsReports,
    siteName,
    handleSetActiveFormReport,
    type
} : Props) => {
    const [{chrisUser}] = React.useContext(ChrisUserProviderContext);
    const parents = chrisUser.isCosUser ? siteFormsReports : siteFormsReports
        .filter(siteFormReport => siteFormReport.isParent || !siteFormReport.parentId);
    const treeData = parents.map((siteFormReport) => mapTreeData(siteFormReport, siteFormsReports));

    return (
        <>
            <div className="d-flex flex-column align-items-center">
                <img
                    alt={`Select ${type}`}
                    src={FormsSelect}
                    width={240}
                    className="d-inline-block align-top"
                />
                {type === 'Form' && (
                    <h2 className="mt-2 mb-3">{siteName}</h2>
                )}
                {type === 'Report' && (
                    <h2 className="mt-2 mb-3">Reports</h2>
                )}
            </div>
            {siteFormsReports.length > 0 ? (
                <TreeMenu
                    data={treeData}
                    onClickItem={({ key, label, ...props }) => {
                        const clickedForm = siteFormsReports.find(
                            siteFormReport => siteFormReport.id === props.formId
                        );
                        if (clickedForm && !clickedForm.isParent) {
                            handleSetActiveFormReport(clickedForm);
                        }
                    }}
                >
                    {({ search, items }) => (
                        <>
                            <h3>Available {type}s</h3>
                            <input
                                className="form-control"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const { value } = e.target;
                                    search && search(value);
                                }}
                                placeholder={`Search ${type.toLowerCase()}s ...`}
                            />
                            <ListGroup className="list-unstyled">
                                {items.map(props => (
                                    <ItemComponentCustom
                                        {...props}
                                        openedIcon={<FontAwesomeIcon icon={faMinus}/>}
                                        closedIcon={<FontAwesomeIcon icon={faPlus}/>}
                                        label={props.isParent ?
                                            props.label
                                            :
                                            <span><FontAwesomeIcon icon={faEdit}/> &nbsp; {props.label}</span>
                                        }
                                    />
                                ))}
                            </ListGroup>
                        </>
                    )}
                </TreeMenu>
            ) : (
                <p className="d-flex flex-row justify-content-center pt-5">No {type.toLowerCase()} available at this time</p>
            )}
        </>
    );
};

export default AvailableFormsReports;
