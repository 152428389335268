import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import OidcProvider from './components/Providers/OidcProvider';
import SignInCallback from './pages/Oidc/SignInCallback';
import SilentCallback from './pages/Oidc/SilentCallback';
import Routes from './Routes';
import {userManager} from './utils/UserManager';
import SessionTimeout from "./components/SessionTimeout";

const Guard = () => (
    <OidcProvider userManager={userManager}>
        <Routes/>
        <SessionTimeout/>
    </OidcProvider>
);

const App = () => {


    return (
        <>
            <BrowserRouter>
                <Route path="/oidc/sign-in-callback" exact component={SignInCallback}/>
                <Route path="/oidc/silent-callback" exact component={SilentCallback}/>
                <Route path="/" component={Guard}/>
            </BrowserRouter>
        </>
    );
}

export default App;
