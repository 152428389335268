import React from 'react';
import {Col, Row} from "react-bootstrap";
import {List} from "../../../../pages/RecordLocator";
import {BaseTimelineFormValues, getDaysElapsedError, requiredFieldMsg, SudoRequiredField} from "./TimelineEventForm";
import * as yup from "yup";
import {getOptionsForField, yupDateRegex} from "../../../../utils/formHelpers";
import StandardField from "../../Fields/StandardField";
import moment from "moment";
import {isString} from "formik";

export const SCREENING_STRING = 'Screening';
export type SCREENING = typeof SCREENING_STRING;

export type ScreeningValues = BaseTimelineFormValues & {
    ScrAdaptiveDate : string;
    ScrAdaptiveLocation : string;
    ScrAdaptivePF : string;
    ScrAdaptivePrvd : string;
    ScrBehaviorDate : string;
    ScrBehaviorLocation : string;
    ScrBehaviorPF : string;
    ScrBehaviorPrvd : string;
    ScrCognitionDate : string;
    ScrCognitionLocation : string;
    ScrCognitionPF : string;
    ScrCognitionPrvd : string;
    ScrDevelopDate : string;
    ScrDevelopLocation : string;
    ScrDevelopPF : string;
    ScrDevelopPrvd : string;
    ScrHearingDate : string;
    ScrHearingLocation : string;
    ScrHearingPF : string;
    ScrHearingPrvd : string;
    ScrLanguageDate : string;
    ScrLanguageLocation : string;
    ScrLanguagePF : string;
    ScrLanguagePrvd : string;
    ScrMotorDate : string;
    ScrMotorLocation : string;
    ScrMotorPF : string;
    ScrMotorPrvd : string;
    ScrObsrvDate : string;
    ScrObsrvLocation : string;
    ScrObsrvPF : string;
    ScrObsrvPrvd : string;
    ScrParentCnfDate : string;
    ScrParentCnfLocation : string;
    ScrParentCnfPF : string;
    ScrParentCnfPrvd : string;
    ScrProfRprtDate : string;
    ScrProfRprtLocation : string;
    ScrProfRprtPF : string;
    ScrProfRprtPrvd : string;
    ScrRcrdRevDate : string;
    ScrRcrdRevLocation : string;
    ScrRcrdRevPF : string;
    ScrRcrdRevPrvd : string;
    ScrSocialEmDate : string;
    ScrSocialEmLocation : string;
    ScrSocialEmPF : string;
    ScrSocialEmPrvd : string;
    ScrSpeechDate : string;
    ScrSpeechLocation : string;
    ScrSpeechPF : string;
    ScrSpeechPrvd : string;
    ScrVisionDate : string;
    ScrVisionLocation : string;
    ScrVisionPF : string;
    ScrVisionPrvd : string;
    ScrPresentingProblem1 : string;
    ScrPresentingProblem2 : string;
    ScrPresentingProblem3 : string;
    ScrReferralForScreeningDate : string;
    ScrFinalScreeningResult : string;
    ScrFinalScreeningResultDate : string;
    ScrInstrumentsUsed : string;
    ScrInterpreterUsed : string;
    ScrNotes : string;
    'DemoEntry to Timeline by AN to ScrAN::ActionNeeded' : string;
    'DemoEntry to Timeline by AN to ScrAN::ANCompletedDate' : string;
    'DemoEntry to Timeline by AN to ScrAN::ANFollowUpDate' : string;
};

const endOfToday = moment().endOf('day').toDate();

export const ScreeningSchema = {
    ScrAdaptiveDate: yupDateRegex,
    ScrAdaptiveLocation: yup.string(),
    ScrAdaptivePF: yup.string(),
    ScrAdaptivePrvd: yup.string(),
    ScrBehaviorDate: yupDateRegex,
    ScrBehaviorLocation: yup.string(),
    ScrBehaviorPF: yup.string(),
    ScrBehaviorPrvd: yup.string(),
    ScrCognitionDate: yupDateRegex,
    ScrCognitionLocation: yup.string(),
    ScrCognitionPF: yup.string(),
    ScrCognitionPrvd: yup.string(),
    ScrDevelopDate: yupDateRegex,
    ScrDevelopLocation: yup.string(),
    ScrDevelopPF: yup.string(),
    ScrDevelopPrvd: yup.string(),
    ScrHearingDate: yupDateRegex,
    ScrHearingLocation: yup.string(),
    ScrHearingPF: yup.string(),
    ScrHearingPrvd: yup.string(),
    ScrLanguageDate: yupDateRegex,
    ScrLanguageLocation: yup.string(),
    ScrLanguagePF: yup.string(),
    ScrLanguagePrvd: yup.string(),
    ScrMotorDate: yupDateRegex,
    ScrMotorLocation: yup.string(),
    ScrMotorPF: yup.string(),
    ScrMotorPrvd: yup.string(),
    ScrObsrvDate: yupDateRegex,
    ScrObsrvLocation: yup.string(),
    ScrObsrvPF: yup.string(),
    ScrObsrvPrvd: yup.string(),
    ScrParentCnfDate: yupDateRegex,
    ScrParentCnfLocation: yup.string(),
    ScrParentCnfPF: yup.string(),
    ScrParentCnfPrvd: yup.string(),
    ScrProfRprtDate: yupDateRegex,
    ScrProfRprtLocation: yup.string(),
    ScrProfRprtPF: yup.string(),
    ScrProfRprtPrvd: yup.string(),
    ScrRcrdRevDate: yupDateRegex,
    ScrRcrdRevLocation: yup.string(),
    ScrRcrdRevPF: yup.string(),
    ScrRcrdRevPrvd: yup.string(),
    ScrSocialEmDate: yupDateRegex,
    ScrSocialEmLocation: yup.string(),
    ScrSocialEmPF: yup.string(),
    ScrSocialEmPrvd: yup.string(),
    ScrSpeechDate: yupDateRegex,
    ScrSpeechLocation: yup.string(),
    ScrSpeechPF: yup.string(),
    ScrSpeechPrvd: yup.string(),
    ScrVisionDate: yupDateRegex,
    ScrVisionLocation: yup.string(),
    ScrVisionPF: yup.string(),
    ScrVisionPrvd: yup.string(),
    ScrPresentingProblem1: yup.string(),
    ScrPresentingProblem2: yup.string(),
    ScrPresentingProblem3: yup.string(),
    ScrReferralForScreeningDate: yup.date().min(
        moment().subtract('2', 'year').toDate(), 'Referral For Screening Date must be later than ' +
        moment().subtract('2', 'year').format('MM/DD/YYYY')
    ).max(
        endOfToday,//if this fixes the issue make the same change to ScrFinalScreeningResultDate
        'Date Must Not Be In The Future.'
    ),
    ScrFinalScreeningResult: yup.string(),
    ScrFinalScreeningResultDate: yup.date().min(
        moment().subtract('2', 'year').toDate(), 'Date of Final Results must be later than ' +
        moment().subtract('2', 'year').format('MM/DD/YYYY')
    ).max(
        moment().toDate(),
        'Date Must Not Be In The Future.'
    ),
    ScrInstrumentsUsed: yup.string(),
    ScrInterpreterUsed: yup.string(),
    ScrNotes: yup.string(),
    'DemoEntry to Timeline by AN to ScrAN::ActionNeeded': yup.string(),
    'DemoEntry to Timeline by AN to ScrAN::ANCompletedDate': yupDateRegex,
    'DemoEntry to Timeline by AN to ScrAN::ANFollowUpDate': yupDateRegex
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
    handleCheckNegativeDaysElapsed : (eventName : string, date : string) => void;
    negativeDaysElapsed : boolean;
    sudoRequiredFields: Array<SudoRequiredField>;
};

const ScreeningForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified,
    handleCheckNegativeDaysElapsed,
    negativeDaysElapsed,
    sudoRequiredFields
} : Props) => {

    let referralForScreeningDateError = sudoRequiredFields.find(
        (sudoRequiredField) =>
            sudoRequiredField.name === 'ScrReferralForScreeningDate'
    )?.showError ? requiredFieldMsg['ScrReferralForScreeningDate'] : '';

    referralForScreeningDateError += sudoRequiredFields.find(
        (sudoRequiredField) =>
            sudoRequiredField.name === 'ScrReferralForScreeningDateAge'
    )?.showError ? requiredFieldMsg['ScrReferralForScreeningDateAge'] : '';

    return (
        <div id="screeningEvaluationForm">
            <Row className="mx-0 px-2">
                <Col xs={12} lg={3}>
                </Col>
                <Col xs={12} lg={6} className="d-flex flex-row justify-content-center">
                    <h3 className="pt-lg-4">Presenting Problems at Time of Screening</h3>
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrInterpreterUsed"
                        type="radio"
                        label="Interpreter Used"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'YesNo',
                            '',
                            siteLists
                        )}
                    />
                </Col>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ScrPresentingProblem1"
                        type="select"
                        label="Presenting Problem (Primary)"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'PresentingProblem',
                            '',
                            siteLists
                        )}
                        errorNoticeOverride={sudoRequiredFields.find(
                            (sudoRequiredField) =>
                                sudoRequiredField.name === 'ScrPresentingProblem1'
                        )?.showError ? requiredFieldMsg['ScrPresentingProblem1'] : ''}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ScrPresentingProblem2"
                        type="select"
                        label="Secondary I"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'PresentingProblem',
                            '',
                            siteLists
                        )}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ScrPresentingProblem3"
                        type="select"
                        label="Secondary II"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'PresentingProblem',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ScrReferralForScreeningDate"
                        type="date"
                        label="Referral For Screening Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        errorNoticeOverride={referralForScreeningDateError}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ScrFinalScreeningResultDate"
                        type="date"
                        label="Date of Final Result:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        onChange={async (value) => {
                            handleCheckNegativeDaysElapsed(SCREENING_STRING, isString(value) ? value : '');
                        }}
                        errorNoticeOverride={getDaysElapsedError(
                            'ScrFinalScreeningResultDate',
                            negativeDaysElapsed,
                            sudoRequiredFields
                        )}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ScrFinalScreeningResult"
                        type="select"
                        label="Final Screening Result:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        errorNoticeOverride={sudoRequiredFields.find(
                            (sudoRequiredField) =>
                                sudoRequiredField.name === 'ScrFinalScreeningResult'
                        )?.showError ? requiredFieldMsg['ScrFinalScreeningResult'] : ''}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2 condensed-form-row">
                <Col xs={12} lg={2} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>Screening</strong>
                </Col>
                <Col xs={12} lg={1} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>P/F</strong>
                </Col>
                <Col xs={12} lg={3} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>Date</strong>
                </Col>
                <Col xs={12} lg={3} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>PRVD</strong>
                </Col>
                <Col xs={12} lg={3} className="flex-row justify-content-center d-none d-lg-flex">
                    <strong>Location</strong>
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Adaptive
                </Col>
                <Col xs={12} lg={1}>
                    <StandardField
                        name="ScrAdaptivePF"
                        type="select"
                        label="Adaptive P/F:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                        className="min-select-width"
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrAdaptiveDate"
                        type="date"
                        label="Adaptive Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrAdaptivePrvd"
                        type="select"
                        label="Adaptive PRVD:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderAccronymList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrAdaptiveLocation"
                        type="select"
                        label="Adaptive Location:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'LocationList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Behavior
                </Col>
                <Col xs={12} lg={1}>
                    <StandardField
                        name="ScrBehaviorPF"
                        type="select"
                        label="Behavior P/F:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                        className="min-select-width"
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrBehaviorDate"
                        type="date"
                        label="Behavior Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrBehaviorPrvd"
                        type="select"
                        label="Behavior PRVD:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderAccronymList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrBehaviorLocation"
                        type="select"
                        label="Behavior Location:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'LocationList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Cognition
                </Col>
                <Col xs={12} lg={1}>
                    <StandardField
                        name="ScrCognitionPF"
                        type="select"
                        label="Cognition P/F:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                        className="min-select-width"
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrCognitionDate"
                        type="date"
                        label="Cognition Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrCognitionPrvd"
                        type="select"
                        label="Cognition PRVD:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderAccronymList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrCognitionLocation"
                        type="select"
                        label="Cognition Location:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'LocationList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Development
                </Col>
                <Col xs={12} lg={1}>
                    <StandardField
                        name="ScrDevelopPF"
                        type="select"
                        label="Development P/F:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                        className="min-select-width"
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrDevelopDate"
                        type="date"
                        label="Development Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrDevelopPrvd"
                        type="select"
                        label="Development PRVD:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderAccronymList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrDevelopLocation"
                        type="select"
                        label="Development Location:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'LocationList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Hearing
                </Col>
                <Col xs={12} lg={1}>
                    <StandardField
                        name="ScrHearingPF"
                        type="select"
                        label="Hearing P/F:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                        className="min-select-width"
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrHearingDate"
                        type="date"
                        label="Hearing Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrHearingPrvd"
                        type="select"
                        label="Hearing PRVD:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderAccronymList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrHearingLocation"
                        type="select"
                        label="Hearing Location:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'LocationList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Language
                </Col>
                <Col xs={12} lg={1}>
                    <StandardField
                        name="ScrLanguagePF"
                        type="select"
                        label="Language P/F:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                        className="min-select-width"
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrLanguageDate"
                        type="date"
                        label="Language Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrLanguagePrvd"
                        type="select"
                        label="Language PRVD:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderAccronymList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrLanguageLocation"
                        type="select"
                        label="Language Location:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'LocationList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Motor
                </Col>
                <Col xs={12} lg={1}>
                    <StandardField
                        name="ScrMotorPF"
                        type="select"
                        label="Motor P/F:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                        className="min-select-width"
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrMotorDate"
                        type="date"
                        label="Motor Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrMotorPrvd"
                        type="select"
                        label="Motor PRVD:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderAccronymList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrMotorLocation"
                        type="select"
                        label="Motor Location:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'LocationList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Observation
                </Col>
                <Col xs={12} lg={1}>
                    <StandardField
                        name="ScrObsrvPF"
                        type="select"
                        label="Observation P/F:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                        className="min-select-width"
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrObsrvDate"
                        type="date"
                        label="Observation Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrObsrvPrvd"
                        type="select"
                        label="Observation PRVD:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderAccronymList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrObsrvLocation"
                        type="select"
                        label="Observation Location:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'LocationList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex">
                    Parent Conf/Rprt
                </Col>
                <Col xs={12} lg={1}>
                    <StandardField
                        name="ScrParentCnfPF"
                        type="select"
                        label="Parent Conf/Rprt P/F:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                        className="min-select-width"
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrParentCnfDate"
                        type="date"
                        label="Parent Conf/Rprt Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrParentCnfPrvd"
                        type="select"
                        label="Parent Conf/Rprt PRVD:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderAccronymList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrParentCnfLocation"
                        type="select"
                        label="Parent Conf/Rprt Location:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'LocationList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Prof. Report
                </Col>
                <Col xs={12} lg={1}>
                    <StandardField
                        name="ScrProfRprtPF"
                        type="select"
                        label="Prof. Report P/F:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                        className="min-select-width"
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrProfRprtDate"
                        type="date"
                        label="Prof. Report Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrProfRprtPrvd"
                        type="select"
                        label="Prof. Report PRVD:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderAccronymList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrProfRprtLocation"
                        type="select"
                        label="Prof. Report Location:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'LocationList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Record Review
                </Col>
                <Col xs={12} lg={1}>
                    <StandardField
                        name="ScrRcrdRevPF"
                        type="select"
                        label="Record Review P/F:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                        className="min-select-width"
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrRcrdRevDate"
                        type="date"
                        label="Record Review Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrRcrdRevPrvd"
                        type="select"
                        label="Record Review PRVD:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderAccronymList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrRcrdRevLocation"
                        type="select"
                        label="Record Review Location:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'LocationList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Social/Emotional
                </Col>
                <Col xs={12} lg={1}>
                    <StandardField
                        name="ScrSocialEmPF"
                        type="select"
                        label="Social/Emotional P/F:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                        className="min-select-width"
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrSocialEmDate"
                        type="date"
                        label="Social/Emotional Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrSocialEmPrvd"
                        type="select"
                        label="Social/Emotional PRVD:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderAccronymList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrSocialEmLocation"
                        type="select"
                        label="Social/Emotional Location:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'LocationList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Speech
                </Col>
                <Col xs={12} lg={1}>
                    <StandardField
                        name="ScrSpeechPF"
                        type="select"
                        label="Speech P/F:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                        className="min-select-width"
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrSpeechDate"
                        type="date"
                        label="Speech Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrSpeechPrvd"
                        type="select"
                        label="Speech PRVD:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderAccronymList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrSpeechLocation"
                        type="select"
                        label="Speech Location:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'LocationList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>

                <Col xs={12} lg={2} className="d-lg-flex d-none d-lg-flex pt-2">
                    Vision
                </Col>
                <Col xs={12} lg={1}>
                    <StandardField
                        name="ScrVisionPF"
                        type="select"
                        label="Vision P/F:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ScreeningP/F',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                        className="min-select-width"
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrVisionDate"
                        type="date"
                        label="Vision Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrVisionPrvd"
                        type="select"
                        label="Vision PRVD:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderAccronymList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <StandardField
                        name="ScrVisionLocation"
                        type="select"
                        label="Vision Location:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'LocationList',
                            '',
                            siteLists
                        )}
                        fieldLabelClass={"d-lg-none"}
                    />
                </Col>
                <Col xs={12}>
                    <hr className="mt-0" />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="ScrInstrumentsUsed"
                        type="text"
                        label="Instruments Used:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default ScreeningForm;
