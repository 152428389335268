import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './custom.scss';
import "react-datetime/css/react-datetime.css";
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    root.render(<App/>);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
