import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {apiEndpoint, useApiFetch} from "./OidcProvider";
import {Report} from "../../pages/Reports";

type Props = {
    children ?: ReactNode;
};

export const ReportsProviderContext = React.createContext<
    [Report[]]
>(
    [[]]
);

const mapRawReport = (rawReport : any) : Report => ({
    ...rawReport,
    parentId: rawReport.parentFormId
});

const ReportsProvider : React.FC<Props> = ({children} : Props) => {
    const [reports, setReports] = useState<Report[]>([]);
    const apiFetch = useApiFetch();

    const loadAvailableReports = useCallback(async () => {

        const url = new URL(`/v1/reports`, apiEndpoint);
        const response = await apiFetch(url.toString());
        const data = await response.json();

        setReports(data.map(mapRawReport));
    }, [setReports, apiFetch]);

    useEffect( () => {
        if (reports.length < 1) {
            loadAvailableReports();
        }
    }, [reports]);

    return (
        <ReportsProviderContext.Provider value={[reports]}>
            {children}
        </ReportsProviderContext.Provider>
    );
};

export default ReportsProvider;

