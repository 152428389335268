import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../Fields/StandardField";
import * as yup from 'yup';

export const COS_REPORT_STRING = 'COSReport';
export type COS_REPORT = typeof COS_REPORT_STRING;

export const COSReportValues = {
    "Report::ReportFieldValue1" : '',
    "Report::ReportFieldValue2" : '',
    "Report::ReportTableName1" : 'demo',
    "Report::ReportTableName2" : 'demo',
    "Report::ReportFieldName1" : 'COSFormEntryCOSDate',
    "Report::ReportFieldName2" : 'COSFormExitCOSDate',
};

const oneDate = function (value : any) {
    if (value) return true;
    // @ts-ignore: it's a yep this to get to the parent.
    if (this.parent['Report::ReportFieldValue1'] || this.parent['Report::ReportFieldValue2']) {
        return true;
    }

    return false;
}

export const COSReportSchema = {
    "Report::ReportFieldValue1": yup.string().test(
        'COSFormEntryCOSDate or COSFormExitCOSDate',
        'COSFormEntryCOSDate or COSFormExitCOSDate must be set',
        oneDate
    ),
    "Report::ReportFieldValue2": yup.string().test(
        'COSFormEntryCOSDate or COSFormExitCOSDate',
        'COSFormEntryCOSDate or COSFormExitCOSDate must be set',
        oneDate
    ),
    "Report::ReportTableName1": yup.string(),
    "Report::ReportTableName2": yup.string(),
    "Report::ReportFieldName1": yup.string(),
    "Report::ReportFieldName2": yup.string(),
};

type Props = {
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
};

const COSReport = ({
    handleSetFormIsModified
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-1">
                <Col xs={12}>
                    <h3>Find Criteria</h3>
                </Col>
            </Row>
            <Row className="mx-0 px-2 condensed-form-row">
                <input type="hidden" name="Report::ReportTableName1" value="demo"/>
                <input type="hidden" name="Report::ReportTableName2" value="demo"/>
                <input type="hidden" name="Report::ReportFieldName1" value="COSFormEntryCOSDate"/>
                <input type="hidden" name="Report::ReportFieldName2" value="COSFormEntryCOSDate"/>
                <React.Fragment >
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="Report::ReportFieldValue1"
                            type="text"
                            label="Entry COS Date:"
                            disabled={false}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <StandardField
                            name="Report::ReportFieldValue2"
                            type="text"
                            label="Exit COS Date:"
                            disabled={false}
                            handleSetFormIsModified={handleSetFormIsModified}
                        />
                    </Col>
                </React.Fragment>
            </Row>
        </>
    );
};

export default COSReport;
