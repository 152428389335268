import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../Branding/Logo';

type Props = {
    children? : any;
}

const NavBase = ({children} : Props) => {
    return (
        <Navbar expand="lg" bg="dark" className="chris-header-bg shadow-sm navbar-dark">
            <Container className="pr-0">
                <Navbar.Brand href="#">
                    <Logo/>
                </Navbar.Brand>
                {children}
            </Container>
        </Navbar>

    );
};

export default NavBase;
