import React from 'react';
import ModalBase from './ModalBase';
import {Button, Col, Row} from "react-bootstrap";

type Props = {
    show : boolean;
    handleShowModal : (show : boolean) => void;
    notice: string;
};

const ReportAlternateResponseModal = ({show, handleShowModal, notice} : Props) => {
    const handleCloseModal = () => {
        handleShowModal(false);
    };

    return (
        <ModalBase
            show={show}
            title=""
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleCloseModal}
        >
            <Row className="chris-form-bg mx-0 py-3 px-2">
                <Col xs={12}>{notice}</Col>
            </Row>
            <Col xs={12}><hr /></Col>
            <Row className="mx-0 mt-2">
                <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                    <Button
                        variant="outline-secondary"
                        type="button"
                        className="d-flex pt-2 flex-row align-content-center mr-2"
                        onClick={async () => {
                            handleCloseModal();
                        }}
                    >Close</Button>
                </Col>
            </Row>
        </ModalBase>
    );
};

export default ReportAlternateResponseModal;