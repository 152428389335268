import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import FullPageLoadingIndicator from '../../components/FullPageLoadingIndicator';
import {userManager} from '../../utils/UserManager';
import {Button, Container} from "react-bootstrap";
import {apiEndpoint} from "../../utils/api";

const SignInCallback : React.FC = () => {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState<null | string>(null);

    useEffect(() => {
        userManager.signinRedirectCallback().then(user => {
            if (user.access_token) {
                const url = new URL(`/v1/user/login`, apiEndpoint);

                const init = {headers: new Headers()};
                init.headers.append('Authorization', `Bearer ${user.access_token}`);
                init.headers.append('Content-Type', 'application/json');

                fetch(url.toString(), init).catch(e => {
                    console.log('error calling login', e);
                }).finally(() => history.push('/'));
            } else {
                history.push('/');
            }
        }).catch(error => {
            setErrorMessage(error.message);
        });
    }, [history]);


    if (!errorMessage) {
        return <FullPageLoadingIndicator/>;
    }

    return (
        <Container>
            <Button color="primary" onClick={() => userManager.signinRedirect()}>
                Try again
            </Button>
        </Container>
    );
};

export default SignInCallback;
