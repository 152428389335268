import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../Fields/StandardField";
import * as yup from 'yup';

export const SOD_REFERRAL_REPORT_STRING = 'SODReferralReport';
export type SOD_REFERRAL_REPORT = typeof SOD_REFERRAL_REPORT_STRING;

export const SODReferralReportValues = {
    ReferralFirstContactDate : ''
};

export const SODReferralReportSchema = {
    ReferralFirstContactDate: yup.string().required()
};

type Props = {
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
};

const SODReferralReport = ({
    handleSetFormIsModified
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-1">
                <Col xs={12}>
                    <h3>Find Criteria</h3>
                </Col>
            </Row>
            <Row className="mx-0 px-2 condensed-form-row">
                    <React.Fragment >
                        <Col xs={12} lg={4}>
                            <StandardField
                                name="ReferralFirstContactDate"
                                type="text"
                                label="Referral Date: (Found set includes Ref First and In dates)"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                    </React.Fragment>
            </Row>
        </>
    );
};

export default SODReferralReport;
