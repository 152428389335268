import React from 'react';
import ModalBase from './ModalBase';
import FormsSelect from "../../images/formsSelect.png";
import {SearchResult} from "../Providers/RecordLocatorProvider";

type Props = {
    show : boolean;
    handleShowTransferRecordModal : (show : boolean, child : SearchResult | null) => void;
    transferChild : SearchResult|null;
};

const RecordTransferModal = ({show, handleShowTransferRecordModal, transferChild} : Props) => {
    const handleCloseModal = () => {
        handleShowTransferRecordModal(false, null);
    };

    const contact = transferChild
        && transferChild?.siteContact
        && transferChild?.sitePhone
        && transferChild?.siteContactEmail ? transferChild : {
        siteContact: 'CHRIS help desk',
        sitePhone: '800-231-5747',
        siteContactEmail: 'chris.um@miami.edu',
    }

    return (
        <ModalBase
            show={show}
            title=""
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleCloseModal}
        >
            <div className="d-flex flex-column align-items-center">
                <img
                    alt="Record Transfer Request"
                    src={FormsSelect}
                    width={240}
                    className="d-inline-block align-top"
                />
                <h2 className="mt-2 mb-3">Record Transfer Request</h2>
            </div>
            <div>
                <p>Please contact <strong>{contact?.siteContact}</strong>  using one of the contact methods below to initiate a record transfer request.</p>
                <ul>
                    <li>P: <strong>{contact?.sitePhone}</strong></li>
                    <li>E: <strong><a href={"mailto:" + contact?.siteContactEmail}>{contact?.siteContactEmail}</a></strong></li>
                </ul>
            </div>
        </ModalBase>
    );
};

export default RecordTransferModal;
