import React from 'react';
import LogoImage from '../../images/CHRIS_Logo.png';

const Logo = () => (
    <img
        alt="CHRIS"
        src={LogoImage}
        width="125"
        className="d-inline-block align-top"
    />
);

export default Logo;