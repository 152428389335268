import React, {ReactNode, useCallback} from 'react';
import {apiEndpoint, useApiFetch} from "./OidcProvider";
import {Child, List} from "../../pages/RecordLocator";

type ActiveChildState = {
    activeChild : Child | undefined;
    error : boolean;
    loading: boolean;
}

type Props = {
    children ?: ReactNode;
};

export const mapRawLists = (rawList : any) : List => ({
    ...rawList
});

export const ActiveChildProviderContext = React.createContext<
    [ActiveChildState, () => Promise<void>, () => Promise<void>]
>(
    [{
        loading: false,
        error: false,
        activeChild : undefined
    }, async () => {}, async () => {}]
);

const activeChildReducer = (state : ActiveChildState, action : any) => {
    switch (action.type) {
        case 'LOADING': {
            return {...state, loading : true, error : false}
        }
        case 'SET_ACTIVE_CHILD': {
            return {...state, loading : true, error : false, activeChild: action.activeChild}
        }
        case 'LOADED': {
            return {...state, loading : false, error : false}
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
};

const mapRawActiveChild = (rawActiveChild : any) : ActiveChildState => {
  return {...rawActiveChild}
};

const ActiveChildProvider : React.FC<Props> = ({children} : Props) => {
    const [activeChildState, setActiveChildState] = React.useReducer(activeChildReducer, {
        loading: false,
        error: false,
        activeChild : undefined
    });
    const apiFetch = useApiFetch();

    const loadActiveChild = useCallback(async () => {
        const url = new URL(`/v1/child/active-child`, apiEndpoint);
        const response = await apiFetch(url.toString());
        const data = await response.json();
        const activeChild = mapRawActiveChild(data);

        if (response.status === 200) {
            setActiveChildState({type: 'LOADING'});
            setActiveChildState({
                type: 'SET_ACTIVE_CHILD',
                activeChild: activeChild
            });
            setActiveChildState({type: 'LOADED'});
        }
    }, [setActiveChildState, apiFetch]);

    const clearActiveChild = useCallback(async () => {
        setActiveChildState({type: 'LOADING'});
        setActiveChildState({
            type: 'SET_ACTIVE_CHILD',
            activeChild: undefined
        });
        setActiveChildState({type: 'LOADED'});

        const url = new URL(`/v1/child/clear-active-child`, apiEndpoint);
        await apiFetch(url.toString());
    }, [setActiveChildState, apiFetch]);

    return (
        <ActiveChildProviderContext.Provider value={[activeChildState, loadActiveChild, clearActiveChild]}>
            {children}
        </ActiveChildProviderContext.Provider>
    );
};

export default ActiveChildProvider;

