import React from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../Fields/StandardField";
import * as yup from 'yup';

export const SOD_EVALUATION_REPORT_STRING = 'SODEvaluationReport';
export type SOD_EVALUATION_REPORT = typeof SOD_EVALUATION_REPORT_STRING;

export const SODEvaluationReportValues = {
    EvlReferralForEvaluationDate : ''
};

export const SODEvaluationReportSchema = {
    EvlReferralForEvaluationDate: yup.string().required()
};

type Props = {
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
};

const SODEvaluationReport = ({
    handleSetFormIsModified
} : Props) => {

    return (
        <>
            <Row className="mx-0 px-1">
                <Col xs={12}>
                    <h3>Find Criteria</h3>
                </Col>
            </Row>
            <Row className="mx-0 px-2 condensed-form-row">
                    <React.Fragment >
                        <Col xs={12} lg={4}>
                            <StandardField
                                name="EvlReferralForEvaluationDate"
                                type="text"
                                label="Referral for evaluation date:"
                                disabled={false}
                                handleSetFormIsModified={handleSetFormIsModified}
                            />
                        </Col>
                    </React.Fragment>
            </Row>
        </>
    );
};

export default SODEvaluationReport;