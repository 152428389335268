import React, {useCallback} from 'react';
import {Col, Row} from "react-bootstrap";
import StandardField from "../../Fields/StandardField";
import * as yup from 'yup';
import {List} from "../../../../pages/RecordLocator";
import {getOptionsForField, yupDateRegex} from "../../../../utils/formHelpers";
import {BaseFormValues, contactListTypes} from "./SCEventForm";
import {FormikProps} from "formik";

export const REFERRAL_IN_STRING = 'Referral In';
export type REFERRAL_IN = typeof REFERRAL_IN_STRING;

export type ReferralInValues = BaseFormValues & {
    ReferralInAwareness : string;
    ReferralInDate : string;
    ReferralInReason : string;
    ReferralInRefBy : string;
    ReferralInRefByEmail : string;
    ReferralInRefByFax : string;
    ReferralInRefByPhone : string;
    ReferralInRefSource : string;
    ReferralInRefSourceEmail : string;
    ReferralInRefSourceFax : string;
    ReferralInRefSourcePhone : string;
    'DemoEntry::PreTransferResidenceCounty': string;
};

export const ReferralInSchema = {
    ReferralInAwareness: yup.string(),
    ReferralInDate:yupDateRegex.required('You must enter a date'),
    ReferralInReason: yup.string(),
    ReferralInRefBy: yup.string(),
    ReferralInRefByEmail: yup.string(),
    ReferralInRefByFax: yup.string(),
    ReferralInRefByPhone: yup.string(),
    ReferralInRefSource: yup.string(),
    ReferralInRefSourceEmail: yup.string(),
    ReferralInRefSourceFax: yup.string(),
    ReferralInRefSourcePhone: yup.string(),
    'DemoEntry::PreTransferResidenceCounty': yup.string()
};

type Props = {
    canSave : boolean;
    siteLists : Array<List>;
    handleSetFormIsModified : (formIsModified : boolean) => Promise<void>;
    formProps: FormikProps<any>
    updateListAPRFCRI: (
        contactListType: contactListTypes,
        formProps: FormikProps<any>,
        selectField: string,
        faxField: string,
        phoneField: string,
        emailFiled: string
    ) => Promise<void>
};

const ReferralInForm = ({
    canSave,
    siteLists,
    handleSetFormIsModified,
    formProps,
    updateListAPRFCRI
} : Props) => {
    // @ts-expect-error
    const updateReferredBy = useCallback(async (e) => {
        await updateListAPRFCRI(
            'ListAPRFCRI',
            formProps,
            e.currentTarget.value,
            'ReferralInRefByFax',
            'ReferralInRefByPhone',
            'ReferralInRefByEmail'
        );
    },[formProps, updateListAPRFCRI]);

    // @ts-expect-error
    const updateProvider = useCallback((e) => {
        updateListAPRFCRI(
            'ProviderList',
            formProps,
            e.currentTarget.value,
            'ReferralInRefSourceFax',
            'ReferralInRefSourcePhone',
            'ReferralInRefSourceEmail'
        );
    }, [formProps, updateListAPRFCRI]);

    return (
        <>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralInDate"
                        type="date"
                        label="Referral In Date:"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralInRefBy"
                        type="select"
                        label="Referred By"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ListAPRFCRI',
                            '',
                            siteLists
                        )}
                        onChange={updateReferredBy}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralInRefByPhone"
                        type="text"
                        label="Phone"
                        disabled={true}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralInRefByFax"
                        type="text"
                        label="Fax"
                        disabled={true}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralInRefByEmail"
                        type="text"
                        label="Email"
                        disabled={true}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralInRefSource"
                        type="select"
                        label="Referral Source"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'ProviderList',
                            '',
                            siteLists
                        )}
                        onChange={updateProvider}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralInRefSourcePhone"
                        type="text"
                        label="Phone"
                        disabled={true}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralInRefSourceFax"
                        type="text"
                        label="Fax"
                        disabled={true}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralInRefSourceEmail"
                        type="text"
                        label="Email"
                        disabled={true}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12}>
                    <StandardField
                        name="ReferralInReason"
                        type="text"
                        label="Referral Reason"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="ReferralInAwareness"
                        type="select"
                        label="Awareness Source"
                        disabled={!canSave}
                        handleSetFormIsModified={handleSetFormIsModified}
                        options={getOptionsForField(
                            'AwarenessSource',
                            '',
                            siteLists
                        )}
                    />
                </Col>
            </Row>
            <Row className="mx-0 px-2">
                <Col xs={12} lg={4}>
                    <StandardField
                        name="DemoEntry::PreTransferResidenceCounty"
                        type="text"
                        label="Record Transferred From"
                        disabled={true}
                        handleSetFormIsModified={handleSetFormIsModified}
                    />
                </Col>
            </Row>
        </>
    );
};

export default ReferralInForm;
