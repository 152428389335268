import React from 'react';
import ModalBase from './ModalBase';
import {Button, Col, Row} from "react-bootstrap";

type Props = {
    show : boolean;
    handleShowGeneralErrorModal : (show : boolean) => void;
};

const GeneralErrorModal = ({show, handleShowGeneralErrorModal} : Props) => {
    const handleCloseModal = () => {
        handleShowGeneralErrorModal(false);
    };

    return (
        <ModalBase
            show={show}
            title=""
            size="lg"
            hideConfirmButton={true}
            hideCloseButton={true}
            handleClose={handleCloseModal}
        >
            <Row className="chris-form-bg mx-0 py-3 px-2">
                <Col xs={12}>
                    <h3>System Error</h3>
                    <p>The system encountered an error with your request. Please refresh the screen and try your request again. If the problem continues, please contact the CHRIS help desk using one of the contact methods listed below.</p>
                    <ul>
                        <li>P: <strong>800-231-5747</strong></li>
                        <li>F: <strong>305-285-6992</strong></li>
                        <li>E: <strong><a href="mailto:chris.um@miami.edu">chris.um@miami.edu</a></strong></li>
                        <li>Hours: <strong>8:30am - 5:00pm ET</strong></li>
                        <li>Days: <strong>Monday - Friday, except holidays</strong></li>
                    </ul>
                </Col>
            </Row>
            <Col xs={12}><hr /></Col>
            <Row className="mx-0 mt-2">
                <Col xs={12} className="d-flex flex-row justify-content-start justify-content-lg-end">
                    <Button
                        variant="outline-secondary"
                        type="button"
                        className="d-flex pt-2 flex-row align-content-center mr-2"
                        onClick={async () => {
                            handleCloseModal();
                        }}
                    >Close</Button>
                </Col>
            </Row>
        </ModalBase>
    );
};

export default GeneralErrorModal;