import React from 'react';

const NotFound = () => (
    <React.Fragment>
        <h3>File not found</h3>
        <p>The requested file could not be found.</p>
    </React.Fragment>
);

export default NotFound;
